const currentUrl = window.location.href

const translation = {
  "action": "Әрекет",
  "add": "қосу",
  "add-retranslators": "Қайталағышты қосыңыз",
  "add-terminal": "Терминалдар қосу",
  "addCompany": "Компанияны қосыңыз",
  "addCompanyTransalter.addUser": "ПАЙДАЛАНУШЫ ҚОСУ",
  "addCompanyTransalter.cancel": "БАС ТАРТУ",
  "addCompanyTransalter.description": "Жаңа жолға өту кезінде бірнеше терминалдарды қосу.",
  "addCompanyTransalter.error.enterEmail": "Өтінеміз қолданыстағы электронды пошта адресін енгізіңіз",
  "addCompanyTransalter.error.enterName": "Пайдаланушы атыңызды енгізіңіз",
  "addCompanyTransalter.error.enterPassword": "құпия сөзді еңгізіңіз",
  "addCompanyTransalter.error.name": "«Компания атауы» өрісін толтырыңыз",
  "addCompanyTransalter.error.password": "Құпия сөз сәйкес келмеді",
  "addCompanyTransalter.error.repeatPassword": "Құпия сөздер бірдей болуы тиіс",
  "addCompanyTransalter.error.time": "«Уақыт белдеуі» өрісін толтырыңыз",
  "addCompanyTransalter.password": "Құпия сөз:",
  "addCompanyTransalter.repeatPassword": "Құпия сөзді растаңыз:",
  "addCompanyTransalter.save": "Сақтау",
  "addCompanyTransalter.selectCompany": "Таңдалған компанияға ауысыңыз",
  "addLocatorTranslate.selectDateFrom": "Басталу күнін таңдаңыз",
  "addLocatorTranslate.selectDateTo": "Аяқтау күнін таңдаңыз",
  "addLocatorTranslate.selectObject": "Объектіні таңдаңыз",
  "add_parametrs.accepted": "Қабылданды",
  "add_parametrs.add_parametrs": "Параметрлерді қосу",
  "add_parametrs.good": "Жақсы",
  "add_parametrs.no_accepted": "Қабылданбады",
  "add_parametrs.parametrs_available": "Қолжетімді параметрлер",
  "add_parametrs.parametrs_time": "Келесі параметрлер сәтті қосылды:",
  "add_parametrs.protocol": "Протокол",
  "add_parametrs.send_parametrs": "Параметрлерді қосу үшін жіберіңіз",
  "add_parametrs.support": "Келесі опцияларды қосу үшін қолдау қызметіне хабарласыңыз (оларды қосу мүмкін емес)",
  "admin-panel": "Админ. ",
  "admin-panel.company": "Компания",
  "admin-panel.download-finance": "Есепті жүктеп алыңыз",
  "admin-panel.finance": "Қаржылық есеп",
  "admin-panel.is-company": "Компанияға кіріңіз!",
  "admin-panel.module": "Модуль",
  "admin-panel.period": "Кезең",
  "admin-panel.show": "Көрсету",
  "admin-panel.timezone": "Сағаттық белдеу",
  "admin-panel.title": "Әкімші панелі",
  "adminPanelTranlsater.acceptDeleteTemplate": "Үлгіні шынымен жойғыңыз келе ме?",
  "adminPanelTranlsater.addCompany": "Компанияны қосыңыз",
  "adminPanelTranlsater.cancel": "Болдырмау",
  "adminPanelTranlsater.cashType.card": "картадан",
  "adminPanelTranlsater.cashType.deposit": "нұсқаулық",
  "adminPanelTranlsater.cashType.trial": "сыналатын нұсқа",
  "adminPanelTranlsater.cashType.withdrawal": "тарифтік төлем",
  "adminPanelTranlsater.command": "Команда",
  "adminPanelTranlsater.company": "Компания",
  "adminPanelTranlsater.copyObject": "Объектіні көшіру",
  "adminPanelTranlsater.copyTemplate": "Үлгіні көшіру",
  "adminPanelTranlsater.date": "күні",
  "adminPanelTranlsater.delete": "Жою",
  "adminPanelTranlsater.enterEmail": "электрондық поштаны енгізіңіз",
  "adminPanelTranlsater.grade": "Баға",
  "adminPanelTranlsater.importKML": "KML ішінен импорттау",
  "adminPanelTranlsater.importWialon": "Wialon-дан импорттау",
  "adminPanelTranlsater.invite": "Шақыру",
  "adminPanelTranlsater.message": "Хабар",
  "adminPanelTranlsater.name": "Аты",
  "adminPanelTranlsater.notData": "Дерек жоқ",
  "adminPanelTranlsater.numberType": "Жоқ. Түрі",
  "adminPanelTranlsater.object": "Объект",
  "adminPanelTranlsater.period": "Кезең",
  "adminPanelTranlsater.phone": "Телефон",
  "adminPanelTranlsater.protocol": "Протокол",
  "adminPanelTranlsater.recoveryObject": "Объектіні қалпына келтіру",
  "adminPanelTranlsater.total": "сомасы",
  "adminPanelTranlsater.type": "Түр",
  "adminPanelTranlsater.typeTerminal": "Терминал түрі",
  "adminPanelTranlsater.user": "Пайдаланушы",
  "adminPanelTranlsater.wrongEmail": "Жарамсыз электрондық пошта",
  "admintariff.accept": "Растау",
  "admintariff.cancel": "Болдырмау",
  "admintariff.changetitle": "Тарифтің өзгеруі",
  "admintariff.company": "Компания",
  "admintariff.date": "Келесі төлем күні",
  "admintariff.days": "күндер",
  "admintariff.for": "артында",
  "admintariff.historyobjects": "нысандар",
  "admintariff.newtariff": "Жаңа тариф",
  "admintariff.object": "объект",
  "admintariff.objects": "нысандар сайын",
  "admintariff.objectsnumber": "Объектілер саны",
  "admintariff.oldtariff": "Ескі тариф",
  "admintariff.paymentdate": "төлеу күні",
  "admintariff.period": "Төлем мерзімі",
  "admintariff.price": "Бір объектінің құны",
  "admintariff.refill": "Толықтыру",
  "admintariff.tariff": "Бағалау",
  "already_deleted_elements": "заттар жойылды",
  "annotation.additional": "Қосымша:",
  "annotation.data_date": "Мәліметтерді қабылдау күні:",
  "annotation.gps_data": "GPS деректері:",
  "annotation.last_params": "Соңғы деректер:",
  "annotation.reports": "Есептер:",
  "annotation.sensors": "Сенсорлар:",
  "annotationCameraTranslater.grid": "Net",
  "annotationCameraTranslater.object": "Нысан:",
  "annotationCameraTranslater.ribbon": "Таспа",
  "annotationCameraTranslater.see": "Қараңыз",
  "annotationCameraTranslater.video": "Бейне",
  "annotationCameraTranslater.videoRegister": "Бейнемагнитофон:",
  "attention": "Назар аударыңыз",
  "author": "Автор",
  "back": "артқа",
  "blocking": "Блоктау",
  "company.blocked": "Компания бұғатталған",
  "company.balance": "Баланс:",
  "company.history_pay": "Есептен шығару тарихы",
  "company.go.pay": "Төлеу",
  "company.blocked.text1": "Компанияға кіруге тыйым салынған. ",
  "company.blocked.text2": "қол жеткізуді жалғастыру үшін.",
  "company.blocked.text3": "Компанияға кіруге тыйым салынған. ",
  "btn.cancel": "Болдырмау",
  "btn.create": "Жасау",
  "btn.delete": "Жою",
  "btn.editlist": "ТІЗІМНІ ӨҢДЕУ",
  "btn.finisheditlist": "ӨҢДЕУДІ АЯҚТАУ",
  "btn.save": "Сақтау",
  "btn.select": "Таңдау",
  "btn.update": "Жаңарту",
  "cancel": "Болдырмау",
  "catalog.user": "Пайдаланушы нұсқаулығы",
  "change": "Өзгерту",
  "close": "Жабық",
  "code": "Код",
  "come.back": "Қайту",
  "command-terminal": "Команда",
  "companies": "Компаниялар",
  "companiesListTranslater.cancel": "Болдырмау",
  "companiesListTranslater.copiedObject": "нысанды көшіру.",
  "companiesListTranslater.copyObject": "Объектіні көшіру",
  "companiesListTranslater.save": "Сақтау",
  "companiesListTranslater.selectCompany": "Қосылғыңыз келетін компанияны таңдаңыз",
  "company.accounting_type": "Бухгалтерлік есеп түрі:",
  "company.add_card_cost_note": "Автотөлем картасын қосу үшін сізден ақы алуымыз керек. ",
  "company.autopayment": "Автоматты төлем",
  "company.autopayment_on": "Автоматты төлемді қосыңыз",
  "company.autopayment_unavailable_with_mir": "MIR карталарымен автоматты төлем уақытша қолжетімсіз",
  "company.by_number_objects": "Объектілер саны бойынша",
  "company.by_number_objects_sent_data": "Деректерді жіберетін объектілер саны бойынша",
  "company.cancel": "Болдырмау",
  "company.card_add_for_autopayments": "Автоматты төлем үшін карта қосу",
  "company.change_card_autopayment": "Автоматты төлем үшін картаны ауыстыру",
  "company.change_card_cost_note": "Автотөлем картаңызды өзгерту үшін біз сізден 1 рубльді дебеттеуіміз керек. ",
  "company.choose_payment_system": "Төлем жүйесін таңдаңыз",
  "company.continue": "Жалғастыру",
  "company.cost_one_object": "1 объектінің құны:",
  "company.date-format": "Күн пішімі",
  "company.download_offer": "Ұсынысты жүктеп алыңыз",
  "company.exitTime": "n минут әрекетсіздіктен кейін шығыңыз",
  "company.integrator": "Дилер",
  "company.last_date_widget": "Виджеттегі соңғы күннің уақыты",
  "company.last_date_widget_note": "Күндер ауқымындағы соңғы күнді таңдағанда (күнді таңдау виджетінде) қай уақыт пішімі пайдаланылатынын көрсетіңіз.",
  "company.max_number_objects": "Макс. ",
  "company.minimum_payment_amount": "Ең төменгі төлем сомасы 1 руб.",
  "company.name": "Аты",
  "company.no": "Жоқ",
  "company.passwordLabel": "Жетілдірілген қауіпсіздік режимі",
  "company.passwordLabelText1": "Қауіпсіздікті арттыру үшін осы режимді іске қосқан кезде жаңа пайдаланушылардан күшті құпия сөздерді жасау қажет болады. ",
  "company.passwordLabelText2": "Пайдаланушының құпия сөзін өзгерткен кезде олар әкімші құпия сөзін пайдаланып жүйеге кіріп, содан кейін тіркелгі үшін өздерінің құпия сөзін орнатуы керек. ",
  "company.payment_period": "Төлем мерзімі:",
  "company.previous_period_amount": "Өткен кезеңдегі сома",
  "company.retransmit": "EFIS жүйесіне қайта тарату",
  "company.select_payment_system": "Төлем жүйесін таңдаңыз:",
  "company.start_current_period": "Ағымдағы кезеңнің басы:",
  "company.temporarily_unavailable": "Уақытша қол жетімсіз",
  "company.time-format": "Уақыт пішімі",
  "company.timeZone": "Сағаттық белдеу",
  "company.tooltip": "0 немесе бос өріс - әрекетсіздікке байланысты шығу болмайды",
  "company.use_card_for_autopayments": "Автоматты төлем үшін картаны пайдаланыңыз",
  "company.visa_or_mastercard": "Visa немесе Mastercard",
  "company_system_mir": "MIR жүйесі",
  "confirm": "Растау",
  "content.object_last_state_move": "Соңғы күй: қозғалыстағы объект",
  "content.object_last_state_stay": "Соңғы күй: нысан тұр",
  "content.object_move": "Қозғалыстағы зат",
  "content.object_stay": "Нысан құнды",
  "copy": "IMEI көшірілді!",
  "copyReportTemplateTranslater.cancel": "Болдырмау",
  "copyReportTemplateTranslater.copy": "шаблонды көшіру.",
  "copyReportTemplateTranslater.save": "Сақтау",
  "copyReportTemplateTranslater.selectCompany": "Қосылғыңыз келетін компанияны таңдаңыз",
  "copyReportTemplateTranslater.title": "Үлгіні көшіру",
  "create": "Жасау",
  "createPayment.amount": "Төлем сомасы",
  "createPayment.company": "Компания",
  "createPayment.createPayment": "Төлем жасау",
  "createPayment.error.createPayment": "Төлем жасалды",
  "createPayment.error.enterAmount": "«Төлем сомасы» өрісін толтырыңыз",
  "createPayment.error.enterCompany": "«Компания» өрісін толтырыңыз",
  "createPayment.error.error": "Қате",
  "createPayment.numberPayment": "Төлем №",
  "createPayment.title": "Төлем жасау",
  "createPayment.upBalance": "Бұл операция таңдалған компанияның балансын арттырады",
  "dealerTranslater.INN": "ҚАЛАЙЫ",
  "dealerTranslater.address": "Мекенжай",
  "dealerTranslater.back": "Артқа",
  "dealerTranslater.confirmApp": "қолдану",
  "dealerTranslater.confirmContacts": "Байланыстар:",
  "dealerTranslater.confirmDescAdmin": "Басқару панелінің сипаттамасы",
  "dealerTranslater.confirmDescBill": "Тарифтің сипаттамасы",
  "dealerTranslater.confirmDescUi": "Интерфейс сипаттамасы",
  "dealerTranslater.confirmDownload": "Жүктеп алу",
  "dealerTranslater.confirmFastStart": "Жылдам бастау",
  "dealerTranslater.confirmGoToApp": "Қолданбаға өтіңіз",
  "dealerTranslater.confirmHeader": "Жаңа интеграторды тіркеу аяқталды",
  "dealerTranslater.confirmMaterials": "Қосымша материалдар:",
  "dealerTranslater.confirmMsg": "Жақын арада сізге жеделхатта қолдау көрсету чатына сілтеме бар электрондық хат келеді; олар сіздің барлық сұрақтарыңызға жауап береді және басқа платформадан көшуге көмектеседі.",
  "dealerTranslater.email": "Электрондық пошта",
  "dealerTranslater.enter": "Көрсетіңіз",
  "dealerTranslater.enterCodeFromImage": "Тіркеуді аяқтау үшін суреттегі кодты енгізіңіз:",
  "dealerTranslater.enterEmail": "Электрондық поштаңызды енгізіңіз",
  "dealerTranslater.enterINN": "СТТН енгізіңіз",
  "dealerTranslater.enterNameCompany": "Компания атауын енгізіңіз",
  "dealerTranslater.enterPassword": "құпия сөзді еңгізіңіз",
  "dealerTranslater.enterPhone": "Телефон нөміріңізді енгізіңіз",
  "dealerTranslater.enterSurname": "Толық аты-жөніңізді енгізіңіз",
  "dealerTranslater.goRegister": "Тіркелу",
  "dealerTranslater.nameCompany": "Компанияның Аты",
  "dealerTranslater.next": "Әрі қарай",
  "dealerTranslater.password": "Құпия сөз",
  "dealerTranslater.register": "Жаңа дилерді тіркеу",
  "dealerTranslater.registerNewDealer": "Жаңа дилерді тіркеу",
  "dealerTranslater.rewritePassword": "Құпия сөзді растаңыз*",
  "dealerTranslater.services": "Қызметтердің сипаттамасы",
  "dealerTranslater.surname": "Толық аты",
  "dealerTranslater.tariff_description": "Тарифтің сипаттамасы",
  "dealerTranslater.typeOrganization": "Ұйым түрі",
  "delete": "Жою",
  "demoTourTranslater.interface": "Интерфейсті бейне шолу",
  "demoTourTranslater.link": "Әзірлеушілерге хабарласыңыз",
  "demoTourTranslater.modules": "Модульдер",
  "demoTourTranslater.next": "Әрі қарай",
  "demoTourTranslater.search": "Іздеу, хабарландырулар және параметрлер",
  "demoTourTranslater.text": "Біз пайдаланушыларға нысандардың анық және толық көрінісін беруге тырысамыз, сондықтан біздің қолданба картамен өзара әрекеттесуге үлкен мән береді, өйткені ол негізгі визуализация құралы болып табылады.",
  "demoTourTranslater.title": "Сіздің негізгі құралыңыз карта",
  "demoTourTranslater.video": "Интерфейсті бейне шолу",
  "demoTourTranslater.watchVideoYouTube": "YouTube сайтында кіріспе бейнені қараңыз",
  "demoTourTranslater.workMap": "Картамен жұмыс",
  "description": "Сипаттама",
  "details": "Қосымша",
  "edit-retranslators": "Қайталағышты өңдеу",
  "edit-terminal": "Терминалды өңдеу",
  "editTarifTranslater.amount": "Қосынды:",
  "editTarifTranslater.amountPer1Object": "1 объектінің құны",
  "editTarifTranslater.changePeriod": "Сіз төлем мерзімін өзгерттіңіз, сондықтан келесі төлем күні келесіден өзгерді",
  "editTarifTranslater.dateNextPayment": "Келесі төлем күні өткен күн болуы мүмкін емес. ",
  "editTarifTranslater.dateStart": "Тарифтің басталу күні:",
  "editTarifTranslater.info": "Төлем кезеңіндегі төлем сомасы формула бойынша қалыптасады: Макс. ",
  "editTarifTranslater.info_2": "Төлем кезеңі үшін төлем сомасы мына формула бойынша қалыптасады: Кезеңге деректерді жіберген объектілер саны 1 объектінің құнына көбейтілген",
  "editTarifTranslater.max": "Макс. ",
  "editTarifTranslater.nextPayment": "Келесі тарифтік төлем:",
  "editTarifTranslater.periodStart": "Кезеңнің басталуы",
  "editTarifTranslater.setBeforeDate": "Сіз тарифтің басталуын өткен күнге белгіледіңіз, осыған байланысты келесі төлем күні өзгерді",
  "editTarifTranslater.setStartTarif": "Сіз тарифті болашақ күні басталатын етіп орнаттыңыз, осыған байланысты компания сынақ мерзімінде болады",
  "editTarifTranslater.type": "Бухгалтерлік есеп түрі",
  "email": "Пошта",
  "email_confirmation": "Электрондық поштаны растау",
  "error": "Қате",
  "error.429": "Тым көп әрекет, кейінірек қайталап көріңіз.",
  "error.502": "Өкінішке орай, сервер уақытша қолжетімсіз. ",
  "events": "Оқиға",
  "events.consumed": "Жанармай шығыны, л",
  "events.create": "Оқиға жасаңыз",
  "events.datetime": "Күн уақыты",
  "events.description": "Сипаттама",
  "events.deviation_minutes": "Ауытқу, мин",
  "events.duration_hours": "Ұзақтығы сағ",
  "events.duration_minutes": "Ұзақтығы, мин",
  "events.filled": "Жанармай толтырылған, л",
  "events.finish_date": "Аяқталу",
  "events.guaranteed": "Кепілдік",
  "events.millage": "Жүгіру км.",
  "events.motohours": "Қозғалтқыш сағаты, сағ",
  "events.price": "Бағасы",
  "events.requiredFields": "Міндетті өрістер",
  "events.service_name": "Қызмет",
  "events.start_date": "Бастау",
  "events.stolen": "Жанармай төгілді, л",
  "events.type_duration_hours": "Ұзақтығы, сағ",
  "events.type_name": "Оқиға түрі",
  "events.update": "Оқиғаны өңдеу",
  "events.user_id": "Жүргізуші",
  "events.validation.datetime": "Күн мен уақытты енгізіңіз",
  "events.validation.number": "0-ден үлкен сан болуы керек",
  "events.validation.required": "Міндетті өріс",
  "events.show": "Оқиғаны көру",
  "events.imageview.use.keyboard": "Пернелерді пайдаланыңыз",
  "events.imageview.use.keyboard1": "кескіндер арасында ауысу үшін",
  "events.imageview.images": "Суреттер:",
  "events.imageview.maxsize.msg": "10 МБ-тан асатын кескіндерді қоса алмайсыз.",
  "events.validation.driver": "Драйверді таңдаңыз",
  "excel": "Excel",
  "fast_track.close": "Жылдам жолды жасыру",
  "feedback.description": "Біздің өнімді пайдалану тәжірибесін бізбен бөлісіңіз",
  "feedback.fill_area": "Өрісті толтырыңыз",
  "feedback.message_send": "Сіздің сұрауыңыз жіберілді. ",
  "feedback.question_offer": "СұрақҰсыныс",
  "feedback.send": "Жіберу",
  "feedback.yours": "сіздің пікіріңіз",
  "feedbackTranslater.addSomeTerminals": "Жаңа жолға өту кезінде бірнеше терминалдарды қосу.",
  "feedbackTranslater.error.name": "«Компания атауы» өрісін толтырыңыз",
  "feedbackTranslater.error.time": "«Уақыт белдеуі» өрісін толтырыңыз",
  "feedbackTranslater.save": "Сақтау",
  "feedbackTranslater.selectCompany": "Таңдалған компанияға ауысыңыз",
  "fill_field": "Объектілер бойынша іздеу",
  "formula.add": "қосу",
  "formula.errors.formula": "Формуланы енгізіңіз",
  "formula.errors.name": "Тақырыпты енгізіңіз",
  "formula.extra_fields_sensors": "Қосымша қосыңыз ",
  "formula.tooltip": "Формулада +,-,/,*, жақшалар )(, кез келген сандар арифметикалық амалдарды қолдануға болады.",
  "geozones.add": "қосу",
  "geozones.blue": "Көк",
  "geozones.color": "Түс",
  "geozones.create": "Географиялық қоршауларды қосу",
  "geozones.delete": "Жою",
  "geozones.edit": "Географиялық қоршауды өңдеу",
  "geozones.editor": "Өңдеу",
  "geozones.errors.draw_circle": "Шеңбер сызыңыз!",
  "geozones.errors.min_dots_line": "Сызық үшін кем дегенде 2 ұпай керек!",
  "geozones.errors.min_dots_polygon": "Көпбұрыш үшін кем дегенде 3 ұпай керек!",
  "geozones.errors.name": "Атыңызды енгізіңіз!",
  "geozones.errors.put_marker": "Маркерді картаға қойыңыз!",
  "geozones.errors.width": "Жарамды ен енгізіңіз!",
  "geozones.figure": "Сурет",
  "geozones.green": "Жасыл",
  "geozones.grey": "Сұр",
  "geozones.hectar": "га",
  "geozones.km": "км",
  "geozones.library": "Geofence каталогы",
  "geozones.meter": "м",
  "geozones.name": "Аты",
  "geozones.orange": "Апельсин",
  "geozones.red": "Қызыл",
  "geozones.square": "Шаршы:",
  "geozones.type": "Түр",
  "geozones.unitArea": "Аудан бірліктері:",
  "geozones.use_as_address": "Мекенжай ретінде пайдаланыңыз",
  "geozones.width": "Қалыңдық",
  "geozones_lang.geozones_enter": "Geofences жүйесіне кіріңіз",
  "geozones_lang.geozones_exit": "Географиялық қоршаулардан шығу",
  "guide.back": "Артқа",
  "guide.company_workplace": "Компанияның жұмыс орны",
  "guide.instructions": "Нұсқаулар",
  "guide.personal_account": "Дилердің жеке шоты",
  "guide.quiestion_offer": "СұрақҰсыныс",
  "guide.short_instruction": "Қысқаша нұсқаулар",
  "guide.title": "Анықтама",
  "help": "Анықтама",
  "helper.admin.text": "Әкімші панеліне кіру үшін интегратор ретінде тіркелу керек немесе біздің маманнан платформаның көрсетілімін сұрау керек.",
  "helper.content_text": "Сенсорлармен жұмыс істеу үшін:",
  "helper.content_text_list.five": "Сенсорда сіз параметрлерді таңдап, олардың калибрлеуін конфигурациялай аласыз, түймесін басыңыз;",
  "helper.content_text_list.four": "Сіз нысандағы сенсорлардың тізімін көресіз. ",
  "helper.content_text_list.one": "1. «Объектілер» модулін ашыңыз",
  "helper.content_text_list.seven": "Мұны істеу үшін «Формула» өрісінде «Өрнек» соңғы элементін таңдаңыз.",
  "helper.content_text_list.six": "Стандартты формулаларға қоса, өңделмеген сенсор деректеріне қол жеткізу мүмкіндігі бар параметрлер үшін өзіңіздің өрнектеріңізді жасай аласыз.",
  "helper.content_text_list.three": "3. Жоғарғы сол жақ бұрыштағы «Сенсорлар» тармағын таңдаңыз.",
  "helper.content_text_list.two": "2. Тізімнен оның атын басу арқылы нысанды таңдаңыз",
  "helper.description_text": "Демонстрациялық режимде сізде әкімші панеліне және нысандарды (нысандар, геоқоршаулар және т.б.) жасау/өңдеу мүмкіндігі жоқ.",
  "helper.footer.account": "Тіркелгіні тіркеңіз",
  "helper.footer.get_free": "(30 күндік тегін сынақ нұсқасын алыңыз)",
  "helper.list.five.title": "Басқару тақтасы",
  "helper.list.four.title": "Бақылау",
  "helper.list.one.content": "Объектілерді құру және өңдеу. ",
  "helper.list.one.link": "Бейне: «Нысан жасау»",
  "helper.list.one.title": "Объектілер",
  "helper.list.three.title": "Есептер",
  "helper.list.two.content": "Сенсорлармен жұмыс істеу үшін: 1. «Объектілер» модулін ашыңыз. ",
  "helper.list.two.title": "Сенсорлар",
  "helper.monitoring.colors.one": "— Деректер 15 минутқа жетпей келді",
  "helper.monitoring.colors.three": "— Деректер 30 минуттан астам уақыт бұрын келді",
  "helper.monitoring.colors.two": "— Деректер 30 минутқа жетпей келді",
  "helper.monitoring.statuses.four": "— Нысан қозғалыста",
  "helper.monitoring.statuses.one": "— Нысан тұр",
  "helper.monitoring.statuses.text": "Объектіде 4 күйдің біреуі болуы мүмкін:",
  "helper.monitoring.statuses.three": "— Нысан қозғалыста",
  "helper.monitoring.statuses.two": "— Нысан тұтану қосулы тұр",
  "helper.monitoring.text_three": "Сондай-ақ бақылауда сіз нысанға қатысты жылдам әрекеттерге қол жеткізе аласыз. ",
  "helper.monitoring.text_two": "Нысандағы деректер қанша уақыт бұрын келгенін жылдам түсіну үшін түс көрсеткішін қостық:",
  "helper.objects": "Объектілерді құру және өңдеу. ",
  "helper.reports.text": "Нысан немесе нысандар тобы туралы есеп жасаңыз. ",
  "helper.reports.video_base_reports": "Бейне: «Негізгі есептер»",
  "helper.reports.video_create_template": "Бейне: «Үлгілерді жасау»",
  "helper.video": "Бейне: «Нысан жасау»",
  "helper.video_add_sensor": "Бейне: «Сенсорларды қосу»",
  "helperTranslater.helper": "Көмекші",
  "hide": "ӨШІРУ",
  "hide.all": "Барлық элементтер",
  "hide.all.text": "Барлық элементтерді жасыру",
  "hide.events": "Оқиғалар",
  "hide.events.text": "Картадағы оқиғаларды жасыру",
  "hide.geozones": "Географиялық қоршаулармен жұмыс істеу параметрлері",
  "hide.geozones.text": "Картада геоқоршауларды жасырыңыз",
  "hide.notifications": "Хабарландыру параметрлері",
  "hide.notifications.text": "Картадағы хабарландыруларды жасыру",
  "hide.objects": "Объектілер",
  "hide.objects.text": "Картадағы нысандарды жасыру",
  "hide.title": "Картадағы көріну",
  "hide.tracks": "Тректер",
  "hide.tracks.direction_of_movement": "Қозғалыс бағыты",
  "hide.tracks.group": "Қашықтығы аз болса топтаңыз",
  "hide.tracks.signatures": "Жолдардағы жазуларды жасыру",
  "hide.tracks.text": "Картадағы жолдарды жасыру",
  "hide.trips": "Маршруттар мен рейстер",
  "hide.trips.text": "Маршруттар мен рейстерді жасыру",
  "history.author": "Автор",
  "history.create": "Жасау",
  "history.date": "Күн уақыты",
  "history.delete": "Жою",
  "history.edit": "Өңдеу",
  "history.event": "Оқиға",
  "history.operation": "Операция",
  "history.title": "Өзгерістер тарихы",
  "importKmlTranslater.error.fileSize": "Қате: файл 5 МБ-тан аз болуы керек.",
  "importKmlTranslater.error.formatKML": "Файл пішімі kml болуы керек",
  "importKmlTranslater.import": "Импорттау",
  "importKmlTranslater.inCompany": "Компанияға",
  "importKmlTranslater.selectFile": "Файлды таңдаңыз",
  "importKmlTranslater.selectFileKML": "Геоқоршалған KML файлын таңдаңыз",
  "importKmlTranslater.title": "KML ішінен импорттау",
  "importWialonTranslater.back": "Артқа",
  "importWialonTranslater.fileObject": "Wialon ішінен нысандары бар файлды таңдаңыз",
  "importWialonTranslater.findEquipment": "Келесі құрылғылар анықталды, олар үшін протоколдарды таңдаңыз:",
  "importWialonTranslater.findedParametrs": "Датчиктерде таңдалған келесі параметрлер анықталды, олардың сәйкестігін тексеріңіз. ",
  "importWialonTranslater.import": "Импорттау",
  "importWialonTranslater.inCompany": "Компанияға",
  "importWialonTranslater.next": "Әрі қарай",
  "importWialonTranslater.selectFile": "Файлды таңдаңыз",
  "importWialonTranslater.selectProtocol": "Протоколды таңдаңыз",
  "importWialonTranslater.title": "Wialon-дан импорттау",
  "importWialonTranslater.typeFiles": ".wlp, .xml немесе осындай файлдардың zip мұрағаты",
  "in": "IN",
  "integratorsTranslater.INN": "ҚАЛАЙЫ",
  "integratorsTranslater.addParameter": "Интеграторды қосу",
  "integratorsTranslater.address": "Мекенжай",
  "integratorsTranslater.cancel": "Болдырмау",
  "integratorsTranslater.change": "Өзгерту",
  "integratorsTranslater.contactPerson": "Байланыстағы адам",
  "integratorsTranslater.delete": "Жою",
  "integratorsTranslater.documentMobileApp": "Мобильді қолданба келісімі тұтынушыларға көрсетіледі",
  "integratorsTranslater.editParameter": "Интеграторды өңдеу",
  "integratorsTranslater.email": "Электрондық пошта",
  "integratorsTranslater.enterName": "Тақырыпты енгізіңіз",
  "integratorsTranslater.fieldMiss": "Өрісті толтырыңыз",
  "integratorsTranslater.fileSize": "Файл өлшемі 10 МБ аспауы керек",
  "integratorsTranslater.idShop": "Дүкен идентификаторы",
  "integratorsTranslater.moveOferta": "Ұсынысты осында орналастырыңыз немесе таңдау үшін басыңыз",
  "integratorsTranslater.name": "Аты",
  "integratorsTranslater.numberCallCenter": "Байланыс орталығының нөмірі",
  "integratorsTranslater.ofertaLoaded": "Ұсыныс жүктелді",
  "integratorsTranslater.password": "Құпия сөз",
  "integratorsTranslater.paySystem": "Төлем жүйесі",
  "integratorsTranslater.paymentInforamion": "Төлем туралы ақпарат",
  "integratorsTranslater.periodPayment": "Төлем мерзімі (күндер)",
  "integratorsTranslater.priceObject": "Бір нысанның құны",
  "integratorsTranslater.secretKey": "Құпия кілт",
  "integratorsTranslater.services": "Қызметтер",
  "integratorsTranslater.smsTariff": "SMS тарифі",
  "integratorsTranslater.tariffDefault": "Жаңа компаниялар үшін әдепкі тариф",
  "integratorsTranslater.tariffDefaultQR": "QR тіркеулерінің әдепкі жылдамдығы",
  "integratorsTranslater.tariffDescription": "Тарифтің сипаттамасы",
  "integratorsTranslater.tryPeriod": "Сынақ мерзімі (күндер)",
  "integratorsTranslater.typeAccount": "Бухгалтерлік есеп түрі",
  "integratorsTranslater.typeOrganization": "Ұйым түрі",
  "inviteUserTranslater.back": "Артқа",
  "inviteUserTranslater.enterNumber": "Телефон нөміріңізді +XXXXXXXXXX немесе XXXXXXXXXX пішімінде енгізіңіз",
  "inviteUserTranslater.invite": "Шақыру",
  "inviteUserTranslater.next": "Әрі қарай",
  "inviteUserTranslater.passwordMustBe": "Құпия сөз 5 таңбадан көп болуы керек",
  "inviteUserTranslater.register": "Тіркелу",
  "inviteUserTranslater.wrongNumber": "Телефон нөмірі дұрыс емес",
  "journal-events": "Оқиғалар журналы",
  "journal-events.filter": "Сүзгі",
  "km_hour": "км/сағ",
  "lkDiler.admin_panel.step_1": "Басқару тақтасында компанияны құру үшін «Компаниялар» модулінде «Компания қосу» түймесін басу керек:",
  "lkDiler.admin_panel.step_2": "Ашылатын компанияны құру пішінінде компания атын толтырып, уақыт белдеуін таңдап, қажет болған жағдайда 1 немесе одан да көп терминалдарды қосып, «Сақтау» түймесін басыңыз. ",
  "lkDiler.admin_panel.step_3": "«Таңдалған компанияға ауысу» опциясын қалдырыңыз, сіз автоматты түрде құрылған компанияға өтесіз.",
  "lkDiler.change_step.step_1": "Әдепкі бойынша, SKIF.PRO-да жаңадан құрылған компаниялар бір объектіге 250 рубль тарифін пайдаланады және клиенттің SKIF EPP компаниясының процессингтік орталығы арқылы қызмет ақысын төлеуге мүмкіндігі бар. ",
  "lkDiler.change_step.step_2": "Тізімнен қажетті компанияны таңдап, «Көрсету» түймесін басыңыз",
  "lkDiler.change_step.step_3": "Тарифі бар жолды шертіңіз, 0 нысандардың санын және бір объектінің құнын 0 таңдаңыз, растауды басыңыз",
  "lkDiler.change_tariff": "Тариф өзгереді",
  "lkDiler.create_company": "Компанияларды құру",
  "lkDiler.create_company_step.create": "Әкімші панелінде компания құру",
  "lkDiler.create_company_step.register": "Шақыру сілтемесі арқылы тіркелу",
  "lkDiler.create_company_step.title": "Компанияларды құру",
  "lkDiler.create_terminal": "Терминалдарды құру",
  "lkDiler.register_link.step_1": "Шақыру сілтемесін жіберу үшін компаниялар тізімінде «Шақыру» түймесін басу керек.",
  "lkDiler.register_link.step_2": "Ашылған өріске хаттағы сілтемені басу арқылы шақыруды алатын Компания қызметкерінің электрондық пошта мекенжайын енгізу керек, ол жаңа Компанияны тіркеу бетіне өтеді; ",
  "lkDiler.start_terminal.create_terminal": "Терминалдарды құру",
  "lkDiler.start_terminal.step_1": "Терминалдар қосу үшін әкімші тақтасындағы терминалдар модуліне өтіп, «+» түймесін басу керек.",
  "lkDiler.start_terminal.step_2": "Әрі қарай сізге қажет:",
  "lkDiler.start_terminal.step_3": "1. Терминалдарды пайдалануға рұқсат бергіңіз келетін компаниялар тізімінен компанияны таңдаңыз.",
  "lkDiler.start_terminal.step_4": "2. Қосқыңыз келетін бір немесе бірнеше imei терминалдарын енгізіңіз. ",
  "lkDiler.start_terminal.step_5": "3. Егер терминалдар құпия сөз арқылы қосылса, оны осы жерде көрсетуге болады.",
  "lkDiler.start_terminal.step_6": "4. Сақтау түймесін басыңыз.",
  "lkDiler.start_terminal.template.step_1": "Дилердің жеке кабинетінде сіз мұны істеу үшін терминалдар үшін пәрмен үлгілерін жасай аласыз, Басқару тақтасына өтіп, «Командалық анықтама» модулін таңдаңыз;",
  "lkDiler.start_terminal.template.step_2": "Пәрменді қосу үшін «+» түймесін басыңыз, ашылған терезеде терминал түрін таңдаңыз, пәрмен атауын таңдаңыз немесе толтырыңыз және «Пәрмен мәтіні» өрісіне пәрменнің өзін енгізіңіз, сақтау түймесін басыңыз.",
  "lkDiler.start_terminal.template.title": "Терминалдарға арналған команда үлгілері",
  "lkDiler.template_command": "Пәрмен үлгілері",
  "lkDiler.title": "Дилердің жеке шоты",
  "loading": "Жүктелуде...",
  "loginPage.accept": "Мен көрсетілген шарттарды қабылдаймын",
  "loginPage.addObject": "Нысан қосу",
  "loginPage.autoName": "Көліктің аты:",
  "loginPage.autoType": "Көлік түрі:",
  "loginPage.back": "Артқа",
  "loginPage.btn.send": "Жіберу",
  "loginPage.carName": "көлік атауы",
  "loginPage.clickOnStartTheBot-": "«Бастау» түймесін басыңыз, бот Telegram үшін пошталық идентификаторды қайтарады",
  "loginPage.company.name": "Компанияның атауы",
  "loginPage.company.submit": "Кіру үшін",
  "loginPage.company.title": "Компанияны таңдау",
  "loginPage.confirm.submit": "Негізгіге",
  "loginPage.confirm.text1": "Компания тіркелген.",
  "loginPage.confirm.text2": "Тіркеуді растау үшін көрсетілген электрондық поштаға жіберілген хаттағы сілтемені орындаңыз",
  "loginPage.confirm.title": "Растау",
  "loginPage.dontHaveAccount": "Мен SKIF.PRO2 жаңа пайдаланушысымын",
  "loginPage.email": "Электрондық пошта",
  "loginPage.enterCode": "Алты таңбалы кодты енгізіңіз:",
  "loginPage.enterTheTelegramBot": "Telegram ботына кіріңіз",
  "loginPage.errors.email": "Электрондық поштаны енгізіңіз",
  "loginPage.errors.emailError": "Бар электрондық поштаны енгізіңіз",
  "loginPage.errors.name": "Атыңызды енгізіңіз",
  "loginPage.errors.nameCompany": "Компанияңыздың атын енгізіңіз",
  "loginPage.errors.pass": "құпия сөзді еңгізіңіз",
  "loginPage.errors.region": "Аймақты таңдаңыз",
  "loginPage.errors.telegramChatId": "Telegram үшін пошта идентификаторын енгізіңіз",
  "loginPage.haveAccount": "Менің аккаунтым бар",
  "loginPage.hint.companyName": "Компанияны таңдаңыз",
  "loginPage.hint.lang": "Тілді таңдаңыз",
  "loginPage.hint.timeZone": "Уақыт белдеуін таңдаңыз",
  "loginPage.lang": "Тіл",
  "loginPage.linkTermsOfUse": "«Пайдаланушы келісімі»",
  "loginPage.links.ifHaveAccount": "Сізде аккаунт бар ма?",
  "loginPage.links.ifHaveNotAccount": "Есептік жазбаңыз жоқ па?",
  "loginPage.links.login": "Кіру үшін",
  "loginPage.links.lostPass": "Құпия сөзіңізді ұмыттыңыз ба?",
  "loginPage.links.register": "Тіркеу",
  "loginPage.login": "Кіру",
  "loginPage.login.guest": "Демо кіру",
  "loginPage.login.submit": "Кіру үшін",
  "loginPage.login.title": "Кіру",
  "loginPage.mail": "Сіздің электрондық поштаңыз",
  "loginPage.name": "Сіздің атыңыз",
  "loginPage.name.label": "Аты:",
  "loginPage.nameCompany": "Компанияның атауы",
  "loginPage.offer": "ұсыныс",
  "loginPage.orgName": "Ұйымыңыздың атауы:",
  "loginPage.orgName.holder": "Ұйымның атауы",
  "loginPage.pass": "Құпия сөз",
  "loginPage.passHint": "Құпия сөзіңіз кемінде 4 таңбадан тұрады.",
  "loginPage.password": "Құпия сөз:",
  "loginPage.phoneHolder": "+XXXXXXXXXXXX форматындағы телефон нөмірі",
  "loginPage.recovery.text1": "Есептік жазбаңызды қалпына келтіру үшін мына мекенжайға өтіңіз",
  "loginPage.recovery.text2": "көрсетілген электрондық поштаға жіберілген хаттағы сілтеме арқылы.",
  "loginPage.recovery.title": "Қалпына келтіру",
  "loginPage.recovery.toMain": "Негізгіге",
  "loginPage.register": "Тіркелу",
  "loginPage.register.submit": "Тіркелу",
  "loginPage.register.title": "Тіркеу",
  "loginPage.sixCode": "алты таңбалы код:",
  "loginPage.telegramChatId": "Telegram үшін пошталық идентификаторлар",
  "loginPage.textTermsOfUse": "«Тіркеу» түймесін басу арқылы сіз шарттарды қабылдайсыз",
  "loginPage.timeZone": "Сағаттық белдеу",
  "loginPage.timeZones": "Сағаттық белдеу:",
  "loginPage.userName": "Қолданушының аты",
  "loginPage.validation.acceptTerms": "шарттарды қабылдаңыз",
  "loginPage.validation.choosecartype": "Машина түрін таңдаңыз",
  "loginPage.validation.codeType": "Алты таңбалы код бас ағылшын әріптері мен сандарынан тұруы керек",
  "loginPage.validation.email": "Электрондық поштаңызды енгізіңіз",
  "loginPage.validation.email.correctform": "дұрыс электрондық пошта мекенжайын енгізіңіз",
  "loginPage.validation.enter6code": "Сіз алты таңбалы кодты енгізуіңіз керек",
  "loginPage.validation.entercarname": "Құрылғы атауын енгізіңіз",
  "loginPage.validation.name": "Атыңызды енгізіңіз",
  "loginPage.validation.password": "Құпия сөзіңізді енгізіңіз",
  "loginPage.validation.sixCode": "Сіз алты таңбалы кодты енгізуіңіз керек:",
  "loginPage.validation.telephone": "Телефон нөміріңізді енгізіңіз",
  "loginPage.validation.tiemzone": "Уақыт белдеуін таңдаңыз",
  "loginPage.yourpassword": "құпия сөзіңіз",
  "loginPass.changePass.adminPass": "Әкімшіден алынған құпия сөзді енгізіңіз",
  "loginPass.changePass.confirmNewPass": "Құпия сөзді растаңыз",
  "loginPass.changePass.current": "Ағымдағы Құпия Сөз",
  "loginPass.changePass.empty": "Егер өрісті бос қалдырсаңыз, электрондық пошта арқылы құпия сөзді өзгерту туралы растау аласыз.",
  "loginPass.changePass.inputNewPass": "Жаңа құпия сөзді енгізіңіз",
  "loginPass.changePass.inputPass": "құпия сөзді еңгізіңіз",
  "loginPass.changePass.newPass": "Жаңа пароль",
  "loginPass.changePass.newPass_confirm": "Қайтадан жаңа құпия сөз",
  "loginPass.changePass.old_pass": "Ескі Құпия сөз",
  "loginPass.changePass.repeatNewPass": "Құпия сөзді қайталаңыз",
  "loginPass.changePass.submit": "Кіру үшін",
  "loginPass.changePass.title": "Құпия сөзді өзгерту",
  "loginPass.errors.lengthPass": "Құпия сөз 5 пен 25 таңба арасында болуы керек",
  "loginPass.errors.lengthPass.strongPass.adminUser": "Құпия сөз 15 пен 25 таңба аралығында болуы керек және кем дегенде бір ағылшын әріпінен, кем дегенде бір кіші ағылшын әріпінен және кем дегенде бір арнайы таңбадан (~ ! @ # $ % ^ & * ( ) - = + [ \"{ ] \" } | : \" \" , < . > / ?)",
  "loginPass.errors.lengthPass.strongPass.regularUser": "Құпия сөз 8 және 25 таңба аралығында болуы керек және кем дегенде бір бас ағылшын әріпінен, кем дегенде бір кіші ағылшын әріпінен және кем дегенде бір арнайы таңбадан (~ ! @ # $ % ^ & * ( ) - = + [ \"{ ] \" } | : \" \" , < . > / ?)",
  "loginPass.errors.newPass": "Құпия сөзіңізді қайта енгізіңіз",
  "loginPass.errors.notMatchPass": "Енгізілген құпия сөздер сәйкес келмейді",
  "loginPass.errors.pass": "құпия сөзді еңгізіңіз",
  "loginPass.recovery.back": "Артқа",
  "loginPass.recovery.content": "Есептік жазбаңызды қалпына келтіру үшін электрондық пошта арқылы жіберілген хаттағы сілтемені орындаңыз. ",
  "loginPass.recovery.hint": "Құпия сөзді қалпына келтіруге сілтемесі бар хат көрсетілген электрондық пошта мекенжайына жіберіледі.",
  "loginPass.recovery.title": "Құпия сөзді қалпына келтіру",
  "logout": "Шығу",
  "mailing.adresses": "Адресаттар",
  "mailing.adresses.warning": "Пошта адрестеріңізді енгізіңіз!",
  "mailing.chooseGroup": "Топты таңдаңыз!",
  "mailing.chooseObject": "Объектіні таңдаңыз!",
  "mailing.create": "Ақпараттық бюллетень жасаңыз",
  "mailing.date": "күні",
  "mailing.daysweek": "Апта күндері",
  "mailing.daysweek.warning": "Аптаның күндерін енгізіңіз!",
  "mailing.edit": "Өзгерту",
  "mailing.enterName": "Атыңызды енгізіңіз!",
  "mailing.isActive": "Белсенділік",
  "mailing.mailing": "Ақпараттық бюллетень",
  "mailing.tooltip": "Бірінші хабарламаны жіберу күні мен уақыты таңдалған кезеңге және соңғы жіберу күніне байланысты есептеледі",
  "mailing.name": "Аты",
  "mailing.object": "Объект",
  "mailing.objectGroup": "Объектілер тобы",
  "mailing.periodicity": "Мерзімділік",
  "mailing.periodicity.warning": "Жиілікті енгізіңіз!",
  "mailing.subject": "Хабар тақырыбы",
  "mailing.subject.warning": "Хабарламаңызды енгізіңіз!",
  "mailing.template": "Үлгіні таңдаңыз",
  "mailing.timeDateSent": "Соңғы жіберілген күні/уақыты",
  "mailing.title": "Пошталық тізім каталогы",
  "mailing.vehicle": "Көлікті таңдау",
  "mainPage.back": "Үйге!",
  "mainPage.sorry": "Өкінішке орай, сіздің сілтемеңіз енді жұмыс істемейді. ",
  "mainPage.title": "SKIF-ке қош келдіңіз!",
  "map.adrress_not_found": "Мекенжай табылмады",
  "map.bing": "Bing",
  "map.bingSatellite": "Bing спутнигі",
  "map.googleHybrid": "Google гибридті",
  "map.googleRoadMap": "Google жол картасы",
  "map.googleSatelite": "Google жерсерігі",
  "map.googleTerrain": "Google Terrain",
  "map.here": "Мұнда",
  "map.osm": "О.С.М.",
  "map.search": "Нақты мекенжайды табу",
  "map.style": "Карта стилі",
  "map.title": "Карта",
  "map.yandex": "Яндекс карталары",
  "measure.cancel": "Болдырмау",
  "measure.clear": "ТАЗАЛАУ",
  "measure.description": "Өлшемді нүктені жылжыту арқылы өзгертуге болады",
  "measure.distance": "Қашықтық",
  "measure.finish": "Өлшеулерді аяқтау",
  "measure.lastPoint": "Соңғы нүкте",
  "measure.line": "Түзу",
  "measure.perimeter": "Периметр",
  "measure.polygon": "Көпбұрыш",
  "measure.region": "Аймақ",
  "measure.square": "Шаршы",
  "measure.title": "Қашықтықтар мен аудандарды өлшеу",
  "menuSelect.geozones": "Географиялық қоршаулар",
  "menuSelect.monitoring": "Бақылау",
  "menuSelect.notifications": "Хабарландырулар",
  "menuSelect.objects": "Объектілер",
  "menuSelect.race": "Ұшулар",
  "menuSelect.reports": "Есептер",
  "menuSelect.routes": "Маршруттар",
  "menuSelect.users": "Пайдаланушылар",
  "monitoring": "Бақылау",
  "monitoring.accept": "Қолдану",
  "monitoring.activeFilters": "Белсенді сүзгілер",
  "monitoring.add-objects": "Объектілерді қосыңыз",
  "monitoring.addFilter": "Сүзгіні қосыңыз",
  "monitoring.apply_filter": "Сүзгілерді қолданыңыз",
  "monitoring.change-object": "Объектіні өзгерту",
  "monitoring.choose": "Таңдау",
  "monitoring.columns.name": "Аты",
  "monitoring.command_response": "Жауап",
  "monitoring.commands": "Командалар",
  "monitoring.commands.columns.command": "Команда",
  "monitoring.commands.columns.data": "Күн уақыты",
  "monitoring.commands.columns.status": "Күй",
  "monitoring.commands.error_message": "«Нысан таңдау» және «Пәрмен» өрістерін толтырыңыз",
  "monitoring.commands.exit_number": "Шығару нөмірі",
  "monitoring.commands.object": "Объект",
  "monitoring.create-event": "Оқиға жасаңыз",
  "monitoring.date-and-time": "күні мен уақыты",
  "monitoring.datepoint": "Байланыс болды",
  "monitoring.display_all": "Барлығын көрсету",
  "monitoring.displayed_objects": "Көрсетілген нысандар:",
  "monitoring.distance_group": "Қашықтығы (экран пикселдері) аз болса топтаңыз:",
  "monitoring.editObject": "Объектіні өңдеу",
  "monitoring.enter-description": "Сипаттама енгізіңіз",
  "monitoring.fast-report": "Жылдам есеп",
  "monitoring.fast-track": "Жылдам трек",
  "monitoring.fast_tracks": "Жылдам тректер",
  "monitoring.fast_tracks.month": "Ай сайын",
  "monitoring.fast_tracks.today": "Бүгінге",
  "monitoring.fast_tracks.week": "Апта ішінде",
  "monitoring.fast_tracks.yesterday": "Кеше үшін",
  "monitoring.fast_tracks.custom": "Басқа кезең",
  "monitoring.filter-journal-events": "Оқиғалар журналының сүзгісі",
  "monitoring.filter.customFields": "қосу. ",
  "monitoring.filter.driver": "Жүргізуші",
  "monitoring.filter.event": "Оқиғалар",
  "monitoring.filter.fieldsEmpty": "Барлық өрістер толтырылуы керек",
  "monitoring.filter.imei": "IMEI",
  "monitoring.filter.name": "Аты",
  "monitoring.filter.phoneNumber": "Телефондар",
  "monitoring.filter.sensorName": "Датчик атаулары",
  "monitoring.filter.sensorParam": "Сенсорлар параметрі",
  "monitoring.filter.sensorType": "Датчиктердің түрлері",
  "monitoring.filterMax": "Сіз 30-дан астам сүзгі қоса алмайсыз",
  "monitoring.filters": "Сүзгілер",
  "monitoring.graph": "Кесте",
  "monitoring.grouping_on_map": "Карта бойынша топтастыру",
  "monitoring.groups.edit": "Өңдеу",
  "monitoring.map_work_settings": "Карта параметрлері",
  "monitoring.maxUnits": "Бір уақытта 300-ден астам нысанды көрсете алмайсыз, нысандардың санын азайту үшін сүзгіні пайдаланыңыз;",
  "monitoring.object_search": "Нысан атауы бойынша іздеу",
  "monitoring.objects": "Объектілер",
  "monitoring.objects_grouping": "Объектілерді топтастыру",
  "monitoring.objects_list": "Объектілер тізімі:",
  "monitoring.objects_on_map": "Картадағы объектілер:",
  "monitoring.position": "Позиция",
  "monitoring.registration-event": "Оқиғаны тіркеу",
  "monitoring.search.custom_fields": "қосу. ",
  "monitoring.search.driver": "Жүргізуші",
  "monitoring.search.events": "Оқиғалар",
  "monitoring.search.groups": "Топтар",
  "monitoring.search.imei": "IMEI",
  "monitoring.search.name": "Аты",
  "monitoring.search.phonenumber": "Телефондар",
  "monitoring.search.sensors_name": "Датчик атаулары",
  "monitoring.search.sensors_param": "Сенсорлар параметрі",
  "monitoring.search.sensors_types": "Датчиктердің түрлері",
  "monitoring.searchHolder": "тұтану, отын",
  "monitoring.send-command": "Команда жібереді",
  "monitoring.sendCommand-title": "Пәрменді жіберу",
  "monitoring.send_command": "Пәрменді жіберу",
  "monitoring.shape_size": "Пішін өлшемі:",
  "monitoring.show_address": "Нысан мекенжайын көрсету",
  "monitoring.show_annotation_on_click": "Бақылау тізіміндегі нысанды басқан кезде аннотацияны көрсетіңіз",
  "monitoring.terminal_on": "Терминал қосылған",
  "monitoring.to_reports": "Есептерге",
  "monitoring.track_all": "Барлығын бақылаңыз",
  "monitoring.trips": "Сапарлар",
  "monitoring.type-event": "Оқиға түрі",
  "monitoring.view-events": "Оқиға түрі",
  "monitoring.violation": "Бұзушылық",
  "monitoringListTranslater.withoutgroup": "Топсыз",
  "name": "Аты",
  "name.column": "Баған тақырыбы",
  "nav.admin": "Әкімші панелі",
  "nav.geozones": "Географиялық қоршаулар",
  "nav.mailing": "Ақпараттық бюллетень",
  "nav.monitoring": "Бақылау",
  "nav.notifications": "Хабарландырулар",
  "nav.objects": "Объектілер",
  "nav.reports": "Есептер",
  "nav.trips": "Ұшулар",
  "nav.users": "Пайдаланушылар",
  "new_units.add.add_field": "Өріс қосыңыз",
  "new_units.add.couplings": "қосу",
  "new_units.add.groups": "Топ қосу",
  "new_units.add.links": "Сілтеме жасау",
  "new_units.add.objects": "Нысан қосу",
  "new_units.add.parameters": "Опцияларды қосыңыз",
  "new_units.add.service": "Техникалық қызмет көрсетуді қосыңыз",
  "new_units.add.shifts": "қосу",
  "new_units.add.trailers": "Трейлер қосу",
  "new_units.apply_changes": "Өзгерістерді қолдану",
  "new_units.approve": "Растау",
  "new_units.cancel": "өзгерістерден бас тарту",
  "new_units.create": "Жасау",
  "new_units.delete": "жою",
  "new_units.edit": "Өңдеу",
  "new_units.footer.cancel": "Болдырмау",
  "new_units.footer.save": "Сақтау",
  "new_units.groups.errors.create": "Ой... Жасалуда ақау пайда болды",
  "new_units.groups.errors.delete": "Топтарды жою кезінде қате орын алды!",
  "new_units.groups.errors.edit": "Ой... Өңдеу кезінде мәселе туындады",
  "new_units.groups.errors.name": "Атыңызды енгізіңіз!",
  "new_units.groups.errors.validation": "Міндетті өрістерді толтырыңыз!",
  "new_units.groups.no_groups": "Ешқандай нысан топтары жасалмады",
  "new_units.groups.search": "Топ бойынша іздеу",
  "new_units.groups.search_options.name": "Аты",
  "new_units.groups.search_options.object": "Объект",
  "new_units.groups.success.create": "Топ сәтті құрылды!",
  "new_units.groups.success.delete": "Топтар сәтті жойылды!",
  "new_units.groups.success.edit": "Топ сәтті өңделді!",
  "new_units.groups.table_columns.group_name": "Топ аты",
  "new_units.groups.table_columns.objects": "Объектілер",
  "new_units.groups.table_columns.users_count": "Пайдаланушылар",
  "new_units.links.accessEnd": "Аяқталу күні",
  "new_units.links.accessStart": "Қол жеткізу басталу күні",
  "new_units.links.create": "Қол жеткізу сілтемесін жасаңыз",
  "new_units.links.description": "Сипаттама",
  "new_units.links.edit": "Сілтемені өңдеу",
  "new_units.links.empty": "Таңдалмаған",
  "new_units.links.errors.access_end": "Аяқтау күнін таңдаңыз!",
  "new_units.links.errors.access_start": "Басталу күнін таңдаңыз!",
  "new_units.links.errors.create": "Кіру сілтемесін жасау кезінде қате орын алды!",
  "new_units.links.errors.delete": "Кіру сілтемелерін жою кезінде қате орын алды!",
  "new_units.links.errors.edit": "Қол жеткізу сілтемесін өңдеу кезінде қате орын алды!",
  "new_units.links.errors.unit": "Объектіні таңдаңыз!",
  "new_units.links.link": "Сілтеме",
  "new_units.links.no_links": "Кіру сілтемелерінің тізімі бос",
  "new_units.links.object": "Объект",
  "new_units.links.placeholder": "Ұзын сипаттама....",
  "new_units.links.search": "Сілтемелер бойынша іздеу",
  "new_units.links.search_options.object": "Объект",
  "new_units.links.search_options.status": "Күй",
  "new_units.links.status.active_off": "Белсенді (өшірулі)",
  "new_units.links.status.active_on": "Белсенді (қосулы)",
  "new_units.links.status.inactive": "Белсенді емес",
  "new_units.links.status.planned": "Жоспарланған",
  "new_units.links.success.create": "Кіру сілтемесі сәтті жасалды!",
  "new_units.links.success.create_imei": "Imei коды сәтті жасалды!",
  "new_units.links.success.delete": "Кіру сілтемелері сәтті жойылды!",
  "new_units.links.success.edit": "Кіру сілтемесі сәтті өңделді!",
  "new_units.links.success.edit_imei": "Imei коды сәтті өңделді!",
  "new_units.links.table_columns.active_from": "бастап белсенді",
  "new_units.links.table_columns.active_to": "Белсенді",
  "new_units.links.table_columns.object": "Объект",
  "new_units.links.table_columns.status": "Күй",
  "new_units.not_selected": "таңдалмаған",
  "new_units.objects.approved": "РАСТАДЫ",
  "new_units.objects.create": "Объект құру",
  "new_units.objects.edit": "Объектіні өңдеу",
  "new_units.objects.errors.delete": "Нысандарды жою кезінде қате орын алды!",
  "new_units.objects.fields.basic.errors.custom_imei": "Оны құлыптаңыз!",
  "new_units.objects.fields.basic.errors.imei": "Бірегей идентификаторды таңдаңыз!",
  "new_units.objects.fields.basic.errors.name": "Атыңызды енгізіңіз!",
  "new_units.objects.fields.basic.errors.phonenumber": "Телефоныңызды енгізіңіз!",
  "new_units.objects.fields.basic.errors.terminal_type": "Құрылғы түрін таңдаңыз!",
  "new_units.objects.fields.basic.errors.type": "Нысан түрін таңдаңыз!",
  "new_units.objects.fields.basic.gadget": "Құрылғы (терминал):",
  "new_units.objects.fields.basic.groups": "Топтар",
  "new_units.objects.fields.basic.imei": "IMEI:",
  "new_units.objects.fields.basic.name": "Мүліктің атауы:",
  "new_units.objects.fields.basic.note": "Құрылғы атауын көрсетудің қажеті жоқ. ",
  "new_units.objects.fields.basic.port": "Порт:",
  "new_units.objects.fields.basic.protocol": "Деректер алмасу протоколы:",
  "new_units.objects.fields.basic.server": "Мәліметтерді қабылдау сервері:",
  "new_units.objects.fields.basic.sim_1": "Sim1:",
  "new_units.objects.fields.basic.sim_2": "Sim2:",
  "new_units.objects.fields.basic.type": "Нысан түрі:",
  "new_units.objects.fields.couplings.date_filter": "Күні бойынша сүзгі:",
  "new_units.objects.fields.couplings.end": "Авторы",
  "new_units.objects.fields.couplings.end_date": "Аяқтау күні:",
  "new_units.objects.fields.couplings.errors.already_deleted": "Сіз бұл сілтемені әлдеқашан жойдыңыз, оны қайтарғыңыз келсе, төмендегі бас тарту түймесін басыңыз.",
  "new_units.objects.fields.couplings.errors.approved": "Жаңа түйін жасау үшін ағымдағы трейлеріңізді растаңыз!",
  "new_units.objects.fields.couplings.errors.couplig_end": "Аяқтау күнін таңдаңыз!",
  "new_units.objects.fields.couplings.errors.couplig_start": "Басталу күнін таңдаңыз!",
  "new_units.objects.fields.couplings.errors.trailer": "Трейлерді таңдаңыз!",
  "new_units.objects.fields.couplings.errors.work_type": "Жұмыс түрін таңдаңыз!",
  "new_units.objects.fields.couplings.max_speed": "Макс. ",
  "new_units.objects.fields.couplings.min_speed": "Мин. ",
  "new_units.objects.fields.couplings.no_couplings": "Тіркемелермен жұмыс жоспарланбаған",
  "new_units.objects.fields.couplings.note": "Трейлерді пайдалану аралығы басқа интервалмен қабаттасады.",
  "new_units.objects.fields.couplings.sensor_mask": "Датчик атауының маскасы:",
  "new_units.objects.fields.couplings.start": "МЕН",
  "new_units.objects.fields.couplings.start_date": "Басталатын күн:",
  "new_units.objects.fields.couplings.success.approved": "Байланыс сәтті расталды!",
  "new_units.objects.fields.couplings.success.updated": "Бекіткіш сәтті жаңартылды",
  "new_units.objects.fields.couplings.table_columns.from": "МЕН",
  "new_units.objects.fields.couplings.table_columns.to": "Авторы",
  "new_units.objects.fields.couplings.table_columns.trailer": "Трейлер",
  "new_units.objects.fields.couplings.title": "Жоспарлау:",
  "new_units.objects.fields.couplings.trailer": "Трейлер",
  "new_units.objects.fields.couplings.work_type": "Жұмыс түрі:",
  "new_units.objects.fields.parameters.errors.correct_values": "Бөлімге өту үшін дұрыс мәндерді толтырыңыз!",
  "new_units.objects.fields.parameters.errors.wrong_value": "Дұрыс емес мән!",
  "new_units.objects.fields.parameters.name": "Аты:",
  "new_units.objects.fields.parameters.no_parameters": "Сіз ешқандай қосымша параметрлерді қосқан жоқсыз",
  "new_units.objects.fields.parameters.switch.hint": "Мониторингте көрсету",
  "new_units.objects.fields.parameters.switch.not_show_annotation": "Аннотацияда көрсетпеңіз",
  "new_units.objects.fields.parameters.switch.show_annotation": "Аннотацияда көрсету",
  "new_units.objects.fields.parameters.tabs.additional": "Қосымша",
  "new_units.objects.fields.parameters.tabs.engine_hours": "Қозғалтқыш сағаттары",
  "new_units.objects.fields.parameters.tabs.filter_data": "Деректерді сүзу",
  "new_units.objects.fields.parameters.tabs.fuel": "Жанармай",
  "new_units.objects.fields.parameters.tabs.parameters": "Сіздің параметрлеріңіз",
  "new_units.objects.fields.parameters.tabs.trips": "Сапарлар",
  "new_units.objects.fields.parameters.tabs.video": "Бейне",
  "new_units.objects.fields.parameters.value": "Мағынасы:",
  "new_units.objects.fields.parameters.video.hint": "Бейнені көрсету үшін арна нөмірлерін үтірмен бөліп енгізіңіз.",
  "new_units.objects.fields.sensors.fields.connected_sensors.bundle_with_ignition": "Тұтану сенсорына сілтеме",
  "new_units.objects.fields.sensors.fields.connected_sensors.cancel": "Болдырмау",
  "new_units.objects.fields.sensors.fields.connected_sensors.connected": "Байланысты",
  "new_units.objects.fields.sensors.fields.connected_sensors.title": "Сенсорлармен қосылу",
  "new_units.objects.fields.sensors.fields.errors.duplicate_lower_bound": "Төменгі шек мәндерін қайталау мүмкін емес!",
  "new_units.objects.fields.sensors.fields.errors.duplicate_x": "X мәндерін қайталауға болмайды!",
  "new_units.objects.fields.sensors.fields.errors.formula": "Формула таңдаңыз!",
  "new_units.objects.fields.sensors.fields.errors.invalid_value": "Жаңа жолды қосу мүмкін емес, себебі кестеде жарамсыз мән бар.",
  "new_units.objects.fields.sensors.fields.errors.name": "Сенсордың атын енгізіңіз!",
  "new_units.objects.fields.sensors.fields.errors.parameter_1": "1 опцияны таңдаңыз!",
  "new_units.objects.fields.sensors.fields.errors.parameter_2": "2-нұсқаны таңдаңыз!",
  "new_units.objects.fields.sensors.fields.errors.save_validation": "Сақтау үшін ағымдағы сенсордағы қатені түзетіңіз!",
  "new_units.objects.fields.sensors.fields.errors.smoothing": "Дұрыс мәнді енгізіңіз!",
  "new_units.objects.fields.sensors.fields.errors.type": "Сенсор түрін таңдаңыз!",
  "new_units.objects.fields.sensors.fields.errors.validation": "Басқасына өту үшін ағымдағы сенсордағы қатені түзетіңіз!",
  "new_units.objects.fields.sensors.fields.expression": "Өрнек",
  "new_units.objects.fields.sensors.fields.formula": "Формула",
  "new_units.objects.fields.sensors.fields.help_info": "Тінтуірді басу арқылы сенсор параметрлерімен жұмыс пішімін таңдаңыз.",
  "new_units.objects.fields.sensors.fields.hidden_in_both": "Есептер мен мониторингте жасырылған",
  "new_units.objects.fields.sensors.fields.hidden_in_monitoring": "Мониторингте жасырылған",
  "new_units.objects.fields.sensors.fields.hidden_in_report": "Есептерде жасырылған",
  "new_units.objects.fields.sensors.fields.hide_in_monitoring": "Мониторингте жасыру",
  "new_units.objects.fields.sensors.fields.hide_in_report": "Есептерде жасыру",
  "new_units.objects.fields.sensors.fields.hint.hide": "Жасыру",
  "new_units.objects.fields.sensors.fields.hint.show": "Көрсету",
  "new_units.objects.fields.sensors.fields.hint.title": "Анықтама",
  "new_units.objects.fields.sensors.fields.multiple_deletion_mode": "Сіз сенсорды жою режиміндесіз",
  "new_units.objects.fields.sensors.fields.name": "Аты:",
  "new_units.objects.fields.sensors.fields.new_sensor": "Жаңа сенсор",
  "new_units.objects.fields.sensors.fields.parameters.a": "А",
  "new_units.objects.fields.sensors.fields.parameters.b": "Б",
  "new_units.objects.fields.sensors.fields.parameters.clear": "Кестені тазалау",
  "new_units.objects.fields.sensors.fields.parameters.download_as_csv": "CSV файлы ретінде X,Y жүктеп алыңыз",
  "new_units.objects.fields.sensors.fields.parameters.download_from_csv": "CSV файлынан X және Y жүктеңіз",
  "new_units.objects.fields.sensors.fields.parameters.exist": "Тамақ",
  "new_units.objects.fields.sensors.fields.parameters.helpers.degree": "дәрежесі",
  "new_units.objects.fields.sensors.fields.parameters.helpers.param": "Параметр",
  "new_units.objects.fields.sensors.fields.parameters.helpers.param1": "парам1",
  "new_units.objects.fields.sensors.fields.parameters.helpers.param1_minus_1": "параметр1[-1]",
  "new_units.objects.fields.sensors.fields.parameters.helpers.param2": "параметр2",
  "new_units.objects.fields.sensors.fields.parameters.helpers.param2_minus_1": "параметр2[-1]",
  "new_units.objects.fields.sensors.fields.parameters.helpers.param_minus_1": "Параметр[-1]",
  "new_units.objects.fields.sensors.fields.parameters.helpers.sensor_name": "Аурухана атауы",
  "new_units.objects.fields.sensors.fields.parameters.helpers_description.byte_control": "биттік параметрді басқару (мысалы, param101:3 - param101 параметрінің үшінші биті)",
  "new_units.objects.fields.sensors.fields.parameters.helpers_description.param": "бастапқы деректерден кез келген параметрдің мәндеріне қол жеткізу",
  "new_units.objects.fields.sensors.fields.parameters.helpers_description.param1": "ағымдағы сенсордың бірінші параметріне (сызықтық түрлендіруден кейін) қол жеткізу",
  "new_units.objects.fields.sensors.fields.parameters.helpers_description.param1_minus_1": "ағымдағы сенсордың бірінші параметрінің алдыңғы мәніне қол жеткізу (сызықтық түрлендіруден кейін).",
  "new_units.objects.fields.sensors.fields.parameters.helpers_description.param2": "ағымдағы сенсордың екінші параметріне (сызықтық түрлендіруден кейін) қол жеткізу",
  "new_units.objects.fields.sensors.fields.parameters.helpers_description.param2_minus_1": "ағымдағы сенсордың екінші параметрінің алдыңғы мәніне қол жеткізу (сызықтық түрлендіруден кейін).",
  "new_units.objects.fields.sensors.fields.parameters.helpers_description.param_minus_1": "бастапқы деректерден алдыңғы параметр мәніне қатынасу",
  "new_units.objects.fields.sensors.fields.parameters.helpers_description.sensor_name": "басқа сенсорлардың мәніне қол жеткізу",
  "new_units.objects.fields.sensors.fields.parameters.hide_graph": "Диаграмманы жасыру",
  "new_units.objects.fields.sensors.fields.parameters.lower_bound_short": "(төменгі жиек)",
  "new_units.objects.fields.sensors.fields.parameters.lower_bound_x": "Төменгі шекара X:",
  "new_units.objects.fields.sensors.fields.parameters.param_option_1": "1-параметр:",
  "new_units.objects.fields.sensors.fields.parameters.param_option_2": "2-параметр:",
  "new_units.objects.fields.sensors.fields.parameters.param_title_1": "Параметр 1",
  "new_units.objects.fields.sensors.fields.parameters.param_title_2": "Параметр 2",
  "new_units.objects.fields.sensors.fields.parameters.param_title_s_1": "S1",
  "new_units.objects.fields.sensors.fields.parameters.param_title_s_2": "S2",
  "new_units.objects.fields.sensors.fields.parameters.related_tables": "Қатысты кестелер",
  "new_units.objects.fields.sensors.fields.parameters.sensor_calibration": "Сенсорды калибрлеу",
  "new_units.objects.fields.sensors.fields.parameters.show_graph": "Графикті көрсету",
  "new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient": "Тегістеу факторы",
  "new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient_range": "(0-ден 999-ға дейін)",
  "new_units.objects.fields.sensors.fields.parameters.type_filter": "Сүзу түрі",
  "new_units.objects.fields.sensors.fields.parameters.upper_bound_x": "Жоғарғы шегі X:",
  "new_units.objects.fields.sensors.fields.parameters.x": "X",
  "new_units.objects.fields.sensors.fields.parameters.y": "Ы",
  "new_units.objects.fields.sensors.fields.sensor_type": "Сенсор түрі:",
  "new_units.objects.fields.sensors.fields.technical_params.add_params": "Технологияны қосыңыз. ",
  "new_units.objects.fields.sensors.fields.technical_params.errors.wrong_value": "Дұрыс емес мән!",
  "new_units.objects.fields.sensors.fields.technical_params.show_annotation": "Нысан аннотациясында параметрді көрсету.",
  "new_units.objects.fields.sensors.fields.technical_params.title": "Техникалық сипаттамалар",
  "new_units.objects.fields.sensors.no_sensors": "Нысанда сенсорлар жасалмаған",
  "new_units.objects.fields.sensors.output_text": "Мәтінді шығару",
  "new_units.objects.fields.sensors.title": "Сенсорлар:",
  "new_units.objects.fields.sensors.value": "Мағынасы",
  "new_units.objects.fields.sensors.value_to_sensor": "Мәндерді мәтінге түрлендіру:",
  "new_units.objects.fields.sensors.value_to_sensor_text": "Сенсордың сандық мәнін мәтінге түрлендіру. ",
  "new_units.objects.fields.service.days": "Күндер",
  "new_units.objects.fields.service.errors.already_deleted": "Сіз бұл ауысымды әлдеқашан жойдыңыз, оны қайтарғыңыз келсе, төмендегі бас тарту түймесін басыңыз.",
  "new_units.objects.fields.service.errors.checkbox": "Интервал түрлерінің 1 түрін таңдаңыз!",
  "new_units.objects.fields.service.errors.description": "Сипаттама енгізіңіз!",
  "new_units.objects.fields.service.errors.intervalDays": "Аралықты (күндерді) енгізіңіз!",
  "new_units.objects.fields.service.errors.intervalKm": "Ағымдағы жүгірісіңізді (км) енгізіңіз!",
  "new_units.objects.fields.service.errors.intervalMotohours": "Аралықты енгізіңіз (қозғалтқыш сағаты)!",
  "new_units.objects.fields.service.errors.lastDays": "Соңғы техникалық қызмет көрсету күнін таңдаңыз!",
  "new_units.objects.fields.service.errors.lastKm": "Ағымдағы жүгірісіңізді (км) енгізіңіз!",
  "new_units.objects.fields.service.errors.lastMotohours": "Соңғы (уақыт) енгізіңіз!",
  "new_units.objects.fields.service.errors.name": "Тақырыпты енгізіңіз!",
  "new_units.objects.fields.service.errors.validation": "Барлық қажетті өрістерді толтырыңыз!",
  "new_units.objects.fields.service.fields.ativate_engine_hours": "Қозғалтқыштың сағат аралығын іске қосыңыз",
  "new_units.objects.fields.service.fields.current_mileage": "Тех. ",
  "new_units.objects.fields.service.fields.days_period": "Аралықты күн сайын белсендіріңіз",
  "new_units.objects.fields.service.fields.description": "Сипаттама:",
  "new_units.objects.fields.service.fields.engine_hours": "Аралық (М/сағ):",
  "new_units.objects.fields.service.fields.interval": "Аралық (км):",
  "new_units.objects.fields.service.fields.interval_days": "Күндердегі интервал:",
  "new_units.objects.fields.service.fields.last_serviced": "Соңғы техникалық қызмет көрсету күні:",
  "new_units.objects.fields.service.fields.last_times": "Соңғы рет:",
  "new_units.objects.fields.service.fields.mileage_period": "Жүгіру аралығын белсендіріңіз",
  "new_units.objects.fields.service.fields.mileage_period.alert": "Техникалық қызмет көрсету есебі жүгіріс сенсорын қосуды талап етеді",
  'new_units.objects.fields.service.fields.mileage_period.alertSecond': 'Техникалық қызмет көрсету есебі қозғалтқыш сағатының абсолютті сенсорын қосуды талап етеді',
  "new_units.objects.fields.service.fields.service": "Қызмет:",
  "new_units.objects.fields.service.mileage": "Профайл/м.сағ.",
  "new_units.objects.fields.service.no_service": "TO тізімі бос",
  "new_units.objects.fields.service.service": "Қызмет",
  "new_units.objects.fields.service.table_columns.days": "Күндер",
  "new_units.objects.fields.service.table_columns.mileage": "Профайл/м.сағ.",
  "new_units.objects.fields.service.table_columns.service": "Қызмет",
  "new_units.objects.fields.service.title": "БҰЛ:",
  "new_units.objects.fields.shifts.additionalFields": "Қосымша өрістер:",
  "new_units.objects.fields.shifts.driver": "Жүргізуші",
  "new_units.objects.fields.shifts.end": "Авторы",
  "new_units.objects.fields.shifts.errors.already_deleted": "Сіз бұл ауысымды әлдеқашан жойдыңыз, оны қайтарғыңыз келсе, төмендегі бас тарту түймесін басыңыз.",
  "new_units.objects.fields.shifts.errors.approved": "Жаңасын жасау үшін ағымдағы ауысымды растаңыз!",
  "new_units.objects.fields.shifts.errors.driver": "Жүргізушіні таңдаңыз!",
  "new_units.objects.fields.shifts.errors.shift_end": "Ауысымның соңын таңдаңыз!",
  "new_units.objects.fields.shifts.errors.shift_start": "Ауысымыңыздың басталуын таңдаңыз!",
  "new_units.objects.fields.shifts.hide_old_shifts": "Өткен ауысымдарды жасыру",
  "new_units.objects.fields.shifts.name": "Аты:",
  "new_units.objects.fields.shifts.no_shifts": "Ауысым тізімі бос",
  "new_units.objects.fields.shifts.note": "Ауысым пайдалану аралығы басқа интервалмен қабаттасады.",
  "new_units.objects.fields.shifts.shift_end": "Ауысымның соңы:",
  "new_units.objects.fields.shifts.shift_start": "Ауысымның басталуы:",
  "new_units.objects.fields.shifts.start": "МЕН",
  "new_units.objects.fields.shifts.success.approved": "Өзгеріс сәтті расталды!",
  "new_units.objects.fields.shifts.success.updated": "Ауысым сәтті жаңартылды!",
  "new_units.objects.fields.shifts.table_columns.driver": "Жүргізуші",
  "new_units.objects.fields.shifts.table_columns.from": "МЕН",
  "new_units.objects.fields.shifts.table_columns.to": "Авторы",
  "new_units.objects.fields.shifts.title": "Ауысымдар:",
  "new_units.objects.fields.shifts.value": "Мағынасы:",
  "new_units.objects.fill": "Толтыру",
  "new_units.objects.no_objects": "Жүйеге ешбір нысан қосылмаған",
  "new_units.objects.object": "Объект:",
  "new_units.objects.saved": "Сақталды",
  "new_units.objects.search": "Объектілер бойынша іздеу",
  "new_units.objects.search_options.custom_fields": "қосу. ",
  "new_units.objects.search_options.events": "Оқиғалар",
  "new_units.objects.search_options.imei": "IMEI",
  "new_units.objects.search_options.name": "Аты",
  "new_units.objects.search_options.phonenumber": "Телефондар",
  "new_units.objects.search_options.sensors_name": "Датчик атаулары",
  "new_units.objects.search_options.sensors_param": "Сенсорлар параметрі",
  "new_units.objects.search_options.sensors_type": "Датчиктердің түрлері",
  "new_units.objects.success.delete": "Нысандар сәтті жойылды!",
  "new_units.objects.table_columns.imei": "IMEI",
  "new_units.objects.table_columns.name": "Аты",
  "new_units.objects.table_columns.protocol": "Протокол",
  "new_units.objects.table_columns.sensors": "Сенсорлар",
  "new_units.objects.table_columns.sim_1_2": "Sim 1.2",
  "new_units.objects.tabs.basic": "Негізгі",
  "new_units.objects.tabs.blocked_text": "Ауысымдар, техникалық қызмет көрсету және тіректер нысанды сақтағаннан кейін қолжетімді болады",
  "new_units.objects.tabs.couplings": "Бекіткіштер",
  "new_units.objects.tabs.parameters": "Анау. ",
  "new_units.objects.tabs.sensors": "Сенсорлар",
  "new_units.objects.tabs.service": "БҰЛ",
  "new_units.objects.tabs.shifts": "Ауысымдар",
  "new_units.placeholder.select": "таңдаңыз",
  "new_units.recover.button": "Жоюдан бас тарту",
  "new_units.recover.removed.couplings": "Бекіткіштер жойылды:",
  "new_units.recover.removed.parameters": "Жойылған параметрлер:",
  "new_units.recover.removed.sensors": "Жойылған сенсорлар:",
  "new_units.recover.removed.service": "Кімге жойылды:",
  "new_units.recover.removed.shifts": "Ауысымдар жойылды:",
  "new_units.recover.removed.trailers": "Трейлерлер жойылды:",
  "new_units.save_modal.close": "Пішінді жабу",
  "new_units.save_modal.continue": "Жалғастыру",
  "new_units.save_modal.message": "Нысан мен деректер сәтті сақталды",
  "new_units.tabs.groups": "Топтар",
  "new_units.tabs.links": "Сілтемелерге қол жеткізу",
  "new_units.tabs.objects": "Объектілер",
  "new_units.tabs.trailers": "Трейлерлер",
  "new_units.titles.create": "Объект құру",
  "new_units.titles.edit": "Объектіні өңдеу",
  "new_units.titles.groups": "Топтық каталог",
  "new_units.titles.links": "Қол жеткізу сілтемелерінің каталогы",
  "new_units.titles.objects": "Объектілер каталогы",
  "new_units.titles.trailers": "Трейлер каталогы",
  "new_units.trailers.additionalFields": "Қосымша өрістер:",
  "new_units.trailers.errors.already_deleted": "Сіз бұл трейлерді әлдеқашан жойдыңыз, оны қайтарғыңыз келсе, төмендегі бас тарту түймесін басыңыз.",
  "new_units.trailers.errors.approved": "Жаңа трейлер жасау үшін ағымдағы трейлеріңізді сақтаңыз!",
  "new_units.trailers.errors.name": "Атыңызды енгізіңіз!",
  "new_units.trailers.errors.validation": "Басқа трейлерге өту үшін барлық қажетті өрістерді толтырыңыз!",
  "new_units.trailers.fields.code": "Код:",
  "new_units.trailers.fields.description": "Сипаттама:",
  "new_units.trailers.fields.length": "Ені:",
  "new_units.trailers.fields.name": "Аты:",
  "new_units.trailers.fields.value": "Мағынасы:",
  "new_units.trailers.no_trailers": "Трейлер жасалмады",
  "new_units.trailers.save": "Сақтау",
  "new_units.trailers.search": "Трейлерлер бойынша іздеу",
  "new_units.trailers.search_options.code": "Код",
  "new_units.trailers.search_options.name": "Аты",
  "new_units.trailers.success.approved": "Трейлер сәтті жасалды!",
  "new_units.trailers.success.delete": "Трейлерлер сәтті жойылды!",
  "new_units.trailers.success.updated": "Трейлер сәтті жаңартылды!",
  "new_units.trailers.table_columns.code": "Код",
  "new_units.trailers.table_columns.name": "Аты",
  "new_units.trailers.table_columns.width": "Ені",
  "new_units.trailers.title": "Трейлерлер:",
  "new_units.unspecified": "белгілі емес",
  "no": "Жоқ",
  "noMatch": "Сәйкестік табылмады",
  "no_data": "Мәлімет жоқ",
  "notification": "Хабарландыру",
  "notification.use_speed_description": "Бұл құсбелгіні белсендіру драйверге төменде таңдалған геоқоршауларға кіру кезінде газ педальын басу арқылы нысанды көрсетілген максималды жылдамдықтан жоғарылатуға мүмкіндік бермейді.",
  "notification_alert.error_points": "Трек нүктелері жоқ!",
  "notification_alert.success": "Оқиға сәтті сақталды",
  "notifications.add": "Хабарландыру қосу",
  "notifications.adress": "Мекенжай",
  "notifications.allowedDowntime": "Рұқсат етілген тоқтау уақыты",
  "notifications.asEvent": "Оқиғалар ретінде тіркеу",
  "notifications.asViolation": "Бұзушылық ретінде тіркелу",
  "notifications.byEmail": "Электрондық пошта арқылы",
  "notifications.byPush": "Push хабарландырулары",
  "notifications.bySms": "SMS арқылы",
  "notifications.byTelegram": "Telegram желісінде",
  "notifications.change": "Хабарландыруды өңдеу",
  "notifications.chooseDeliveryType": "Ешбір жеткізу түрі таңдалмаған",
  "notifications.chooseEmail": "Кемінде 1 электрондық поштаны таңдаңыз!",
  "notifications.choosePhone": "Кем дегенде 1 телефон таңдаңыз!",
  "notifications.chooseUser": "Кем дегенде бір пайдаланушыны таңдаңыз",
  "notifications.connexionLost": "Байланыс жоғалды",
  "notifications.control": "Бақылау",
  "notifications.days_to_service": "Техникалық қызмет көрсету мерзімі өткен күндер саны",
  "notifications.delivery": "Жеткізу",
  "notifications.deliveryMethod": "Жеткізу әдісі",
  "notifications.deviation": "Ауытқу",
  "notifications.drain": "Ағызыңыз",
  "notifications.driver": "Жүргізуші",
  "notifications.email.placeHolder": "Электрондық поштаны енгізіңіз",
  "notifications.enterName": "Атыңызды енгізіңіз!",
  "notifications.enterNotifyText": "Хабарландыру мәтінін енгізіңіз!",
  "notifications.fill": "Жанармай құю",
  "notifications.final_fuel": "Соңғы отын",
  "notifications.friday": "жұма",
  "notifications.from": "бірге",
  "notifications.geozone_name": "Geofence атауы",
  "notifications.geozones.chooseVerficationType": "Сынақ түрін таңдаңыз!",
  "notifications.geozones.control": "Географиялық қоршауды бақылау",
  "notifications.geozones.geozones": "Географиялық қоршаулар",
  "notifications.geozones.verificationType": "Чек түрі",
  "notifications.hs_to_service": "Қозғалтқыш сағаттарының саны асып кетті",
  "notifications.km_to_service": "Проходтан асып кеткен километрлер саны",
  "notifications.kmph": "км/сағ",
  "notifications.latitude": "Ендік",
  "notifications.longitude": "Бойлық",
  "notifications.lossDuration": "Жоғалту ұзақтығы",
  "notifications.lostMsg": "Уақыт аралығын таңдаңыз!",
  "notifications.main": "Негіздер",
  "notifications.markAsRead": "оқылған деп белгілеу",
  "notifications.maxSpeed": "Жылдамдық артық емес",
  "notifications.min": "мин",
  "notifications.monday": "дүйсенбі",
  "notifications.more": "Қосымша",
  "notifications.motohours": "Қозғалтқыш сағаттары бойынша",
  "notifications.name": "Аты",
  "notifications.no_sound": "Дыбыссыз",
  "notifications.notification": "Хабарландырулар",
  "notifications.notificationName": "Хабарландыру тақырыбы",
  "notifications.objectName": "Нысан атауы",
  "notifications.objects": "Объектілер",
  "notifications.online": "Желіде",
  "notifications.paramTab": "Параметрлер кестесі:",
  "notifications.period": "Кезең",
  "notifications.periodcontrol": "Ағымдағы уақытқа қатысты бақылау кезеңі",
  "notifications.phone.placeHolder": "Телефонды енгізіңіз...",
  "notifications.pointName": "Нүкте атауы",
  "notifications.push.placeHolder": "Атын немесе электрондық поштаны енгізіңіз",
  "notifications.responsetime": "Мин. ",
  "notifications.saturday": "сенбі",
  "notifications.sensor.control": "Сенсордың мәнін бақылау",
  "notifications.sensor.controleGeo": "Географиялық қоршауды бақылау",
  "notifications.sensor.maskByName": "Аты бойынша маска",
  "notifications.sensor.meaning": "Мағынасы",
  "notifications.sensor.meaningFrom": "Мән бастап",
  "notifications.sensor.meaningTo": "дейін мәні",
  "notifications.sensor.minimalTime": "Мин. ",
  "notifications.sensor.selectFrame": "Жақтауларыңызды таңдаңыз!",
  "notifications.sensor.type": "Сенсор түрі",
  "notifications.sensor.warning": "Аты бойынша сенсор түрін немесе масканы таңдаңыз!",
  "notifications.sensor.warningFromTo": "Таңдаңыз немесе мынаны!",
  "notifications.sensor.work": "Триггер",
  "notifications.sensor.writeNumber": "Нөмірді енгізіңіз",
  "notifications.sensorValue": "Сенсордың мәні",
  "notifications.sensors": "Сенсорлар",
  "notifications.service.byMilage": "Проходка бойынша",
  "notifications.service.days": "күндер",
  "notifications.service.for": "Артында",
  "notifications.service.km": "км",
  "notifications.service.maintenance": "Жоспарлы жөндеу жұмыстары жақындағанда хабарлаңыз",
  "notifications.service.warning": "Аралықты күндер, жүгіріс немесе қозғалтқыш сағаттары бойынша енгізіңіз!",
  "notifications.simpleTransport": "Көліктің тоқтап қалу уақыты",
  "notifications.speed": "Жылдамдық",
  "notifications.sunday": "Жексенбі",
  "notifications.telegram.placeHolder": "Атын немесе телеграмманы енгізіңіз",
  "notifications.template": "Хабарландыру үлгісі",
  "notifications.temporaryValueMonitoring": "Уақытша мәнді бақылау",
  "notifications.text": "Мәтін",
  "notifications.text.arrival": "%UNIT% нысанында жоспарланған техникалық қызмет көрсету жақындағанда немесе мерзімі өткенде сізге хабарлаймыз",
  "notifications.text.confirmation": "Электрондық поштасын растамаған пайдаланушылар мұны сұрайтын электрондық хат алады. ",
  "notifications.text.connection": "Қосылым жоғалуы немесе %ADDRESS% жанында %TIME% ішіндегі %UNIT% координаттары анықталды",
  "notifications.text.drain": "%TIME% жылы %UNIT% %ADDRESS% жанында %DRAIN% және %LAST_FUEL% төгілді",
  "notifications.text.events_routes": "Ұшу оқиғасы",
  "notifications.text.exit": "%TIME% жылы %UNIT% %ADDRESS% маңында %SPEED% жылдамдықпен геоқоршауға кіру/шығу анықталды",
  "notifications.text.filling": "% TIME% жылы %UNIT% %ADDRESS% жанында %FILL% кезінде %LAST_FUEL% дейін жанармай құйылды.",
  "notifications.text.geozone.inside": "% TIME% жылы %UNIT% геоқоршауға кіруді анықтады %GEOZONE% %SPEED% жылдамдығымен %ADDRESS% жанында",
  "notifications.text.geozone.outside": "% TIME% жылы %UNIT% геошарықтан %GEOZONE% шығуы %ADDRESS% жанында %SPEED% жылдамдығымен анықталды.",
  "notifications.text.idle": "%TIME% кезінде %UNIT% нысаны %ADDRESS% жанында бос тұру уақытының шегінен асып кетті",
  "notifications.text.sensor": "%TIME% жылы %UNIT% %ADDRESS% маңында %SPEED% жылдамдықта сенсор белсендірілгенін анықтады",
  "notifications.text.speed": "%TIME% кезінде %UNIT% рұқсат етілген максималды жылдамдық шегінен асып кетті %SPEED% мәні %ADDRESS% жанында",
  "notifications.text.trip": "%TIME% ішінде %UNIT%, %ADDRESS% жанында ұшу оқиғасы жазылды",
  "notifications.textDescription": "Төмендегі теңшелетін опцияларды пайдаланып хабарландыру мәтінін енгізіңіз. ",
  "notifications.thursday": "бейсенбі",
  "notifications.time": "Жауап беру уақыты",
  "notifications.to": "бұрын",
  "notifications.tripName": "Ұшу атауы",
  "notifications.trips.alertEvent": "Ескерту оқиғалары:",
  "notifications.trips.arrCheckPoint": "Бақылау пункттеріне келу",
  "notifications.trips.departure": "Бақылау пункттерінен кету",
  "notifications.trips.end": "Ұшудың соңы",
  "notifications.trips.mask": "Ұшу атауы маскасы",
  "notifications.trips.onlyViolation": "Бұзылған жағдайда ғана:",
  "notifications.trips.pass": "Бақылау пункттерін өткізіп жіберу (қатаң тәртіп)",
  "notifications.trips.start": "Ұшудың басталуы",
  "notifications.trips.validationOne": "Қызмет атауын дұрыс енгізіңіз!",
  "notifications.tuesday": "сейсенбі",
  "notifications.types": "Хабарландыру түрлері",
  "notifications.use_speed_limit": "Үстінен асып кету мүмкіндігін шектеңіз",
  "notifications.velocity.geozones": "Географиялық қоршаулар",
  "notifications.velocity.max": "Артық керек емес",
  "notifications.velocity.min": "Аз емес",
  "notifications.velocity.speed": "Жылдамдықты бақылау",
  "notifications.velocity.warning": "Жылдамдықты аз немесе көп емес енгізіңіз!",
  "notifications.view": "Көру",
  "notifications.warning": "минималды және максималды жылдамдықты енгізіңіз",
  "notifications.wednesday": "сәрсенбі",
  "notifications.workType": "Жұмыс түрі",
  "notifications.service.controlService": "Қызметті бақылау",
  "notifications.success.title": "Жетістік",
  "notifications.success.message": "Хабарландыру сәтті қосылды",
  "notifications.success.message2": "Хабарландыру сәтті жаңартылды",
  "notifications.one.object": "Объект",
  "notifications.more.object": "Нысан",
  "notifications.many.object": "Объектілер",
  "notifications.commands.warning": "Протоколдардың біреуі үшін кем дегенде бір пәрменді көрсету керек.",
  "notifications.commands.select_template": "Үлгіні қолдану",
  "notifications.commands.send_command": "Пәрмендерді жіберу",
  "notifications.commands.template_empty": "Ешқандай үлгі табылмады",
  "objecrRecoveryTranslater.copyObject": "Объектіні көшіру",
  "objecrRecoveryTranslater.objectCompany": "компаниядағы объект",
  "objecrRecoveryTranslater.restore": "Сіз шынымен қалпына келтіргіңіз келе ме?",
  "objecrRecoveryTranslater.restoring": "Қалпына келтіру",
  "objecrRecoveryTranslater.success": "нысан сәтті қалпына келтірілді",
  "object": "Объект",
  "objects": "Объект",
  "objects.add.objects": "Объектілерді қосыңыз",
  "objects.add.objects.in.group": "Нысандарды топқа қосыңыз",
  "objects.add.units.to.group": "Нысандарды топқа қосыңыз",
  "objects.create.edit.group": "Топты өңдеу",
  "objects.create.new.group": "Топ құру",
  "objects.delete": "Жою",
  "objects.device": "Құрылғы түрі",
  "objects.editImei": "Таңдалған IMEI өңдеу",
  "objects.fillfield": "Негізгі бөлімдегі жолдарды толтыру қажет",
  "objects.groups": "Топтар",
  "objects.groups.access": "Топтық рұқсаттар",
  "objects.groups.access-title": "Топтық құқықтар",
  "objects.groups.acess": "Қол жеткізу",
  "objects.groups.close-all": "Барлығын жию",
  "objects.groups.objects-in-group": "Топтағы объектілер",
  "objects.imei": "IMEI",
  "objects.locator.access-object": "Қол жеткізу объектісі",
  "objects.locator.copy-link": "Сілтеме көшірілді",
  "objects.locator.status": "Күй",
  "objects.locators": "Сілтемелерге қол жеткізу",
  "objects.locators-date-end": "Аяқталу күні мен уақытына қол жеткізіңіз",
  "objects.locators-date-select": "Күнді таңдаңыз",
  "objects.locators-date-start": "Басталу күні мен уақытына қол жеткізіңіз",
  "objects.locators-link": "Сілтеме",
  "objects.name": "Атыңызды енгізіңіз!",
  "objects.name.group": "Топ аты",
  "objects.name.number": "Аты/Нөмірі",
  "objects.objects": "Объектілер",
  "objects.showmonitoring": "Мониторингте көрсету",
  "objects.title": "Объектілер каталогы",
  "objects.titleLocators": "Қол жеткізу сілтемелерінің каталогы",
  "objects.titleTrailers": "Трейлер каталогы",
  "objects.titlegroups": "Топтық каталог",
  "objects.total.group": "Объектілер саны",
  "objects.trailers": "Трейлерлер",
  "objects.type": "Нысан түрі",
  "objects.unit": "Өлшем бірлігі",
  "objects.users": "Пайдаланушылар",
  "objectsSensors.cansel": "Болдырмау",
  "objectsSensors.clearSelect": "Таңдауды тазалау",
  "objectsSensors.copy": "Көшіру",
  "objectsSensors.copySensors": "Сенсорларды көшіру",
  "objectsSensors.noData": "Мәлімет жоқ",
  "objectsSensors.copy.success": "Сенсорлар сәтті көшірілді",
  "objectsSensors.copy.error": "Ешбір сенсор немесе нысандар таңдалмаған",
  "objectsSensors.copy.text1": "Ескі сенсорларға жаңа сенсорлар қосылады. ",
  "objectsSensors.copy.text2": "Барлық нысан сенсорлары жойылады, содан кейін көшірілген сенсорлар қосылады.",
  "objectsSensors.copy.text3": "Ескі сенсорларға жаңа сенсорлар қосылады. ",
  "objects_locator_change": "Сілтемені өңдеу",
  "objects_locator_create": "Кіру сілтемесін жасаңыз",
  "objects_noPicture": "Ешбір сурет таңдалмаған",
  "objects_phone1": "Телефон 1",
  "objects_phone2": "Телефон 2",
  "objects_port": "Порт:",
  "objects_server": "Мәліметтерді қабылдау сервері:",
  "objects_tabs.TO": "БҰЛ",
  "objects_tabs.drivers.add": "Драйверді қосыңыз",
  "objects_tabs.drivers.change": "Драйверді өзгерту",
  "objects_tabs.drivers.driver": "Жүргізуші",
  "objects_tabs.drivers.from": "МЕН",
  "objects_tabs.drivers.to": "Авторы",
  "objects_tabs.extra": "Қосымша",
  "objects_tabs.extra.addNewField": "Жаңа өріс қосыңыз",
  "objects_tabs.extra.changeField": "Өрісті өзгерту",
  "objects_tabs.extra.custom": "Арнаулы",
  "objects_tabs.extra.etc": "Қосымша",
  "objects_tabs.extra.fuel": "Жанармай",
  "objects_tabs.extra.motohours": "Қозғалтқыш сағаттары",
  "objects_tabs.extra.name": "Аты",
  "objects_tabs.extra.trips": "Сапарлар",
  "objects_tabs.extra.update_field": "Жаңарту өрісі",
  "objects_tabs.extra.value": "Мағынасы",
  "objects_tabs.main": "Негізгі",
  "objects_tabs.main.analyst": "талдаушы",
  "objects_tabs.main.analyst.error": "Аналитикті таңдаңыз",
  "objects_tabs.main.device.error": "Құрылғы түрін таңдаңыз",
  "objects_tabs.main.id.error": "Бірегей идентификаторды таңдаңыз",
  "objects_tabs.main.name.error": "Аты/Нөмірді енгізіңіз",
  "objects_tabs.main.phone.error": "Телефон нөмірін енгізіңіз",
  "objects_tabs.main.type.error": "Нысан түрін таңдаңыз",
  "objects_tabs.sensor": "Сенсорлар",
  "objects_tabs.sensors.actions": "Әрекеттер",
  "objects_tabs.sensors.border_bottom": "Төменгі сызық",
  "objects_tabs.sensors.coefficient": "Коэффицент",
  "objects_tabs.sensors.coefficientA": "А коэффициенті",
  "objects_tabs.sensors.coefficientB": "B коэффициенті",
  "objects_tabs.sensors.description": "Сипаттама",
  "objects_tabs.sensors.enterValue": "Мән енгізіңіз!",
  "objects_tabs.sensors.error_formula": "Формула таңдаңыз!",
  "objects_tabs.sensors.error_param12": "1 немесе 2 опцияны таңдаңыз!",
  "objects_tabs.sensors.error_type_sensor": "Сенсор түрін енгізіңіз!",
  "objects_tabs.sensors.formula": "Формула",
  "objects_tabs.sensors.formula.columns": "Баған формуласы",
  "objects_tabs.sensors.hide_in_monitoring": "Мониторингте жасыру",
  "objects_tabs.sensors.hide_in_reports": "Есептерде жасыру",
  "objects_tabs.sensors.keyExist": "Мұндай кілт бұрыннан бар!",
  "objects_tabs.sensors.lowerBorder": "Төменгі шегі X",
  "objects_tabs.sensors.option1": "Параметр 1",
  "objects_tabs.sensors.option2": "Параметр 2",
  "objects_tabs.sensors.selectA": "Мәнді таңдаңыз!",
  "objects_tabs.sensors.selectB": "b таңдаңыз!",
  "objects_tabs.sensors.selectFromValue": "Мәннен таңдаңыз!",
  "objects_tabs.sensors.selectValueX": "x мәнін таңдаңыз!",
  "objects_tabs.sensors.selectValueY": "Мәнді таңдаңыз!",
  "objects_tabs.sensors.sensors_create": "Сенсорды құру",
  "objects_tabs.sensors.sensors_edit": "Сенсорды өңдеу",
  "objects_tabs.sensors.show_graph": "Графикті көрсету",
  "objects_tabs.sensors.show_table": "Кестені көрсету",
  "objects_tabs.sensors.smoothing": "Тегістеу",
  "objects_tabs.sensors.type_sensor": "Сенсор түрі",
  "objects_tabs.sensors.units": "Өлшем бірлігі",
  "objects_tabs.sensors.upperBorder": "Жоғарғы шегі X",
  "objects_tabs.sensors.valueX": "X мәні",
  "objects_tabs.sensors.valueY": "Y мәні",
  "objects_tabs.services.active_on_interval_days": "Күндер аралығын белсендіріңіз",
  "objects_tabs.services.active_on_interval_mileage": "Жүгіру аралығын белсендіріңіз",
  "objects_tabs.services.create_form": "Техникалық қызмет көрсетуді құру",
  "objects_tabs.services.date.placeholder": "Күнді енгізіңіз!",
  "objects_tabs.services.description": "Сипаттама",
  "objects_tabs.services.edit_form": "Өңдеу TO",
  "objects_tabs.services.intervalInDays": "Аралықты күндермен енгізіңіз!",
  "objects_tabs.services.intervalInMotoHours": "Қозғалтқыштың сағат аралығын іске қосыңыз",
  "objects_tabs.services.intervalInMotoHoursDate": "Қозғалтқыш сағаттары, тәулік/сағат бойынша аралық",
  "objects_tabs.services.interval_days": "Күндер",
  "objects_tabs.services.interval_days_numb_day": "Күндермен, күнмен/тәумендегі интервал",
  "objects_tabs.services.interval_mileage": "Жүгіру",
  "objects_tabs.services.interval_mileage_numb_km": "Жүгіру аралығы, сан/км",
  "objects_tabs.services.lastTime": "Профайлды енгізіңіз",
  "objects_tabs.services.lastTimeDate": "Соңғы уақыт, күн/сағат",
  "objects_tabs.services.lastTimeDate.warning": "Соңғы рет енгізіңіз!",
  "objects_tabs.services.last_one_date": "Соңғы уақыт, күн",
  "objects_tabs.services.last_one_numb_km": "Соңғы рет, сан/км",
  "objects_tabs.services.mileageInterval": "Ағымдағы жүгіріс көрсеткішін енгізіңіз",
  "objects_tabs.services.name_service": "Қызмет",
  "objects_tabs.services.warning.date": "Күнді енгізіңіз!",
  "objects_tabs.services.warning.description": "Сипаттама енгізіңіз!",
  "objects_tabs.services.warning.name": "Қызмет атауларын енгізіңіз!",
  "objects_tabs.trailer": "Трейлер",
  "objects_tabs.trailer.add": "«Трейлер қосыңыз",
  "objects_tabs.trailer.change": "Трейлерді өзгерту",
  "objects_tabs.trailers": "Трейлерлер",
  "objects_trailer_change": "Трейлерді өзгерту",
  "objects_trailer_create": "Трейлер жасаңыз",
  "objects_trailer_key": "Кілт",
  "objects_trailer_moreFields": "Қосымша өрістер",
  'objects.generate.imei': 'Виртуалды IMEI жасау',
  "one_element_deleted": "1 элемент жойылды",
  "outdated_version": "Нұсқа ескірген",
  "outdated_version_message": "Бетті жаңартыңыз",
  "pdf": "PDF",
  "phone": "Телефон",
  "phone_confirmation": "Телефонды тексеру",
  "pixels.group": "экран нүктелері",
  'qrAdminTranslater.scanCode': `GPS трекерін тіркеу үшін кодты сканерлеңіз немесе кодты ${ currentUrl }/c мекенжайына енгізіңіз`,
  "qrAdminTranslater.addGps": "GPS трекерін қосу",
  "qrAdminTranslater.print": "Мөр",
  "qrAdminTranslater.qrCreate": "QR кодын жасау",
  "qrAdminTranslater.register": "Тіркеу және",
  "report.tables.tabs.add_mask": "Сенсор атауы бойынша маска қосыңыз",
  "report.tables.tabs.add_parameter": "Параметрді қосыңыз",
  "report.tables.tabs.enter_value": "Мән енгізіңіз",
  "report.tables.tabs.name_mask": "Датчик атауының маскасы",
  "report.tables.tabs.parameter": "Параметр",
  "report.tables.tabs.parameters": "Опциялар",
  "report.tables.tabs/parameter_hint": "Есепті құруға қатысатын параметрлердің атауларын таңдаңыз.",
  "reportEdit.sensor.addTotalValue": "Есепте жалпы мәнді қосыңыз:",
  "reportEdit.sensor.hintText": "Жасалған есепте осы баған үшін қорытындылар жолына нәтиже мәнін қосыңыз.",
  "reports": "Есептер",
  "reports,tables.fields.mask_name_sensor": "Жанармай сенсорының атауы маскасы",
  "reports.action": "Әрекеттер",
  "reports.add-group-report": "Үлгіге қосу",
  "reports.add-in-template": "Үлгіге қосу",
  "reports.add-new-template": "Жаңа үлгі қосыңыз",
  "reports.add-table-to-reports": "Үлгіден кесте қосыңыз",
  "reports.applied_filter": "Мониторингтен сүзу қолданылды.",
  "reports.attached": "Файл тіркелген",
  "reports.base-table": "Негізгі кесте",
  "reports.build-report": "Құру",
  "reports.chart.fillings": "Жанармай құю станциялары",
  "reports.chart.fillings_markers": "Жанармай құю аралықтары",
  "reports.chart.intervals": "Интервалдар",
  "reports.chart.thefts": "Алхоры",
  "reports.chart.thefts_markers": "Ағызу аралықтары",
  "reports.column": "Баған",
  "reports.composite_report": "Құрама есеп",
  "reports.connect": "Диаграммаға сілтеме",
  "reports.contains": "қамтиды",
  "reports.copy": "Көшіру",
  "reports.copy_key": "Кілтті Excel үлгісі ұяшығына көшіріп, үлгіні SKIF.PRO ішіне жүктеңіз.",
  "reports.create_template": "Үлгі жасаңыз",
  "reports.deleted_intervals_messages": "Жойылған хабар аралықтары",
  "reports.deleted_messaged": "Жойылған хабарлар",
  "reports.display_all_events": "Барлық оқиғаларды көрсету",
  "reports.download_selected": "Жүктеу таңдалды",
  "reports.edit-report": "Өңдеу",
  "reports.edit-reports": "Есеп параметрлерін өңдеу",
  "reports.editTemplate.nameTemplate": "Үлгі атауы",
  "reports.edit_template": "Үлгіні өзгерту",
  "reports.enter_name": "Атыңызды енгізіңіз!",
  "reports.exclude_interval": "Аралықты жою",
  "reports.form_for_unloading": "Жүктеп салу пішіні (excel үлгісі)",
  "reports.from_monitoring": "Мониторингтен",
  "reports.group-object": "Объектілер тобы",
  "reports.group-table": "Үстел тобы",
  "reports.increase": "Арттыру",
  "reports.interval": "Аралық",
  "reports.interval_for_deletion": "Хабарларды жоюға арналған аралық",
  "reports.interval_info": "Жойылған хабар аралықтары журналда көрсетілмейді және есептерді құруға қатыспайды.",
  "reports.is-already-template": "Бар",
  "reports.key_hint": "Excel бағдарламасында есеп үлгісін жасаңыз, содан кейін сәйкес пернелерді көшіру арқылы оған деректерді орналастырыңыз. ",
  "reports.link_to_magazine": "Журналға сілтеме",
  "reports.list_key": "Excel үлгісіне арналған кілттер тізімі",
  "reports.load-report": "Жүктеп алу",
  "reports.map_note": "Картадағы нүктені көру үшін басыңыз",
  "reports.map_snapshot": "Картаның суреті",
  "reports.menu": "Мәзір",
  "reports.mini_hint": "Көшіруден кейін Excel үлгісі ұяшығын таңдап, CTRL+V пернелер тіркесімін басыңыз.",
  "reports.moving": "Қозғалыс",
  "reports.name-template": "Үлгі атауы",
  "reports.new-temlate": "Жаңа",
  "reports.not-select": "Таңдалмаған",
  "reports.not_all_objects_displayed": "Барлық нысандар көрсетілмейді.",
  "reports.object": "Объект",
  "reports.onlyResult": "Тек қорытындыларды көрсету",
  "reports.operation": "Операция",
  "reports.period": "Кезең",
  "reports.print-report": "Мөр",
  "reports.reduce": "Азайту",
  "reports.reports-size-screen.maximum": "Толық экранда",
  "reports.reports-size-screen.medium": "1/2",
  "reports.reports-size-screen.minimum": "1/4",
  "reports.reports-size-screen.thirdScreen": "1/3",
  "reports.reports-template": "Үлгіні өңдеу",
  "reports.reports-visit-geozone": "Geofence сапарының есебі",
  "reports.reset_magnification": "Үлкейтуді қалпына келтіру",
  "reports.select": "Таңдау",
  "reports.select-group-object": "Объектілер тобын таңдау",
  "reports.select-object": "Көлікті таңдау",
  "reports.select-table": "Кестені таңдау",
  "reports.select-template": "Кесте үлгісін таңдау",
  "reports.select_object": "Объектіні таңдаңыз!",
  "reports.select_objects_group": "Нысандар тобын таңдаңыз!",
  "reports.select_with_magnification": "Масштабтау таңдау",
  "reports.sensor_placeholder": "Сенсорлық маска",
  "reports.show-chart": "Кесте",
  "reports.show-color": "Трек түсі",
  "reports.show_all_objects": "Барлық нысандарды көрсету",
  "reports.show_annotation_on_click": "Жолды басу арқылы аннотация",
  "reports.show_annotation_on_click_hint": "Белсендірілген болса, есеп жолын басқан кезде оқиға туралы аннотация көрсетіледі.",
  "reports.show_key": "Excel үлгісіне арналған кілттер тізімін қараңыз",
  "reports.success_copy_template": "Үлгі сәтті көшірілді.",
  "reports.tab-columns": "Бағандар",
  "reports.tab-geozones": "Географиялық қоршаулар",
  "reports.tab-main": "Жалпы",
  "reports.tab-main.group": "Топтастыру",
  "reports.tab-main.group-field": "Өріс бойынша топтастыру",
  "reports.tab-main.interval-priority": "Есеп аралық басымдылығы",
  "reports.tab-main.interval-priority-tooltip": "Егер бұл параметр белсенді болса және аралық Shift басына немесе аяғына дейін кесілсе, онда ол толығымен есепке қосылады.<br>Бұл параметр белсенді болмаса, есепте нақты Shift аралығы пайда болады.<br>Мысалы, ауысым 08:00-де басталып, сапар 06:00-де басталса,<br>содан кейін «Есеп аралықтарының басымдылығы» параметрі белсенді болса, сапарлар есебіндегі интервал 06:00-де басталады,<br>ал «Есептер аралықтарының басымдылығы» параметрі қосылмаған болса – 08:00-де.",
  "reports.tab-main.show-total": "Барлығын көрсету",
  "reports.table.type": "Кесте түрі",
  "reports.tables": "Кестелер",
  "reports.tables.add_mask_sensor": "Сенсор атауы бойынша маска қосыңыз",
  "reports.tables.columns.fields.column": "Баған",
  "reports.tables.columns.fields.filter": "Сүзгі",
  "reports.tables.columns.fields.max_value": "Макс. ",
  "reports.tables.columns.fields.min_value": "Мин. ",
  "reports.tables.columns.fields.name.error": "Тақырыпты енгізіңіз",
  "reports.tables.columns.fields.name.label": "Баған атауы",
  "reports.tables.columns.fields.visibility": "Көріну",
  "reports.tables.fields.add_mask": "Сенсор атауы бойынша маска қосыңыз",
  "reports.tables.fields.excel_template_name": "Excel үлгісінің аты",
  "reports.tables.fields.files_warn": "Сіз тек xlsx xlsm xlsb пішіміндегі файлдарды жүктей аласыз",
  "reports.tables.fields.geozone_warn": "Сіз кем дегенде бір кіру геоқоршауын және бір шығу геоқоршауын таңдауыңыз керек.",
  "reports.tables.fields.grouping": "Топтастыру",
  "reports.tables.fields.grouping_by_field": "Өріс бойынша топтастыру",
  "reports.tables.fields.name.error": "Атыңызды енгізіңіз!",
  "reports.tables.fields.name.label": "Аты",
  "reports.tables.fields.sensor_name_mask": "Датчик атауының маскасы",
  "reports.tables.fields.showThefts": "Жанармай құю станцияларын көрсету",
  "reports.tables.fields.showfillings": "Алхоры көрсетіңіз",
  "reports.tables.fields.template_upload": "Жүктеп алу",
  "reports.tables.geozones.enter": "Кіру",
  "reports.tables.geozones.exit": "Шығу",
  "reports.tables.geozones.title": "Географиялық қоршаулар",
  "reports.tables.report": "Есеп беру",
  "reports.tables.tabs.additional_columns": "қосу. ",
  "reports.tables.tabs.all_protocol": "Барлық протоколдар",
  "reports.tables.tabs.columns": "Бағандар",
  "reports.tables.tabs.data": "Деректер",
  "reports.tables.tabs.general": "Жалпы",
  "reports.tables.tabs.geozones": "Географиялық қоршаулар",
  "reports.tables.tabs.hint": "Жабық есептерде пайдаланушы өзі құрастырылған сенсорлар мен параметрлерді өзгерте алмайды.",
  "reports.tables.tabs.make_close_report": "Есепті жеке етіңіз",
  "reports.tables.tabs.mask_sensor": "Сенсорлық маскалар",
  "reports.tables.tabs.mask_sensor_hint": "Есепті жасау үшін көрсетілген маскалардың кем дегенде біреуін қанағаттандыратын нысан сенсорларын таңдаңыз (толығырақ мына жерден оқыңыз:",
  "reports.tables.tabs.parameters": "Опцияны таңдаңыз",
  "reports.tables.tabs.sensors": "Сенсорлар",
  "reports.tables.tabs.table_name": "Аты:",
  "reports.tables.tabs.type_table": "Кесте түрі:",
  "reports.template": "Үлгі",
  "reports.templateWarning": "Үлгі атауын енгізіңіз!",
  "reports.template_deletion": "Үлгіні жою",
  "reports.template_deletion_sure": "Есеп үлгісін шынымен жойғыңыз келе ме?",
  "reports.value": "Мағынасы",
  "reports.was-deleted": "жойылды",
  "reports.without_grouping": "Топтастыру жоқ",
  "reportsFilter.addInterval": "Аралықты қосыңыз",
  "reportsFilter.daysWeek": "Апта күндері:",
  "reportsFilter.filterByTime": "Уақыт аралықтары бойынша сүзу:",
  "reportsFilter.filterByGeozone": "Географиялық қоршаулар бойынша сүзгілеу",
  "reportsFilter.hint.in": "Ішкі геоқоршаулар",
  "reportsFilter.hint.out": "Географиялық қоршаудан тыс",
  "reportsFilter.hint.in.text": "Есеп тек таңдалған геоаймақтарда алынған деректерді көрсетеді.",
  "reportsFilter.hint.out.text": "Есеп таңдалған геоқоршаулардан тыс алынған деректерді ғана көрсетеді.",
  "reportsFilter.filterType": "Сүзгі түрі:",
  "reportsFilter.filterHintFunction": "Егер функция қосылса, аралық ішінде басталған, бірақ оның сыртында аяқталған күй (мысалы, сапар) қысқартылады. ",
  "reportsFilter.filterHintSelectInterval": "Есептегі деректер сүзілетін уақыт аралықтарын таңдаңыз.",
  "reportsFilter.title": "Сүзу",
  "reportsFilter.trimmerInterval": "Кесу аралықтары:",
  "reportsFilter.zeroInterval": "Нөлдік интервал!",
  "reportsFilter.groupBy": "Топтар",
  "reportsFilter.addGroup": "Топтауды қосыңыз",
  "reportsFilter.byGroup.tooltip": "Қазіргі уақытта бір уақытта үш топтан артық емес қосуға болады",
  "reportsFilter.byGroup.noSearch.msg": "Топтар табылмады",
  "reportsTemplate.successUpdate.msg": "Үлгі сәтті сақталды",
  "reports.loadingMoreData.msg": "Деректерді қабылдау",
  "reportsTranslater.noData": "Есепті құру үшін деректер жоқ",
  "reportsTranslater.rewrite": "Сұраныс",
  "response-terminal": "Жауап",
  "retranslatorTranlsater.changeIMEI": "IMEI-мен ауыстырыңыз",
  "retranslatorTranlsater.gps_signal_category": "GPS сигналының санаты:",
  "retranslatorTranlsater.hint": "Қоғамдық көлік трекерлері үшін баяу пайдаланылады",
  "retranslatorTranlsater.participant": "Бағдарламаға қатысушының идентификаторы:",
  "retranslatorTranlsater.port": "Реле порты",
  "retranslatorTranlsater.protocol": "Протокол",
  "retranslatorTranlsater.retranslationFrom": "Қайта трансляция",
  "retranslatorTranlsater.retranslationTo": "Реле",
  "retranslatorTranlsater.route_identifier": "Маршрут идентификаторы:",
  "retranslatorTranlsater.serverRetranslation": "Релелік сервер",
  "retranslatorTranlsater.type_vehicle": "Қоғамдық көлік түрі:",
  "role": "Рөл",
  "routesTrips.addTrip": "Ұшуды қосыңыз",
  "routesTrips.editTrip": "Ұшуды өңдеу",
  "routesTrips.name": "Аты",
  "routesTrips.route.addFromGeozones": "Географиялық қоршауларды қосыңыз",
  "routesTrips.route.addFromGeozones.title": "Географиялық қоршауларды қосыңыз",
  "routesTrips.route.addFromMap": "Картадан нүкте қосыңыз",
  "routesTrips.route.create": "Маршрутты құру",
  "routesTrips.route.createGeozone.figure": "Сурет",
  "routesTrips.route.createGeozone.name": "Аты",
  "routesTrips.route.createGeozone.title": "Картадан нүкте қосыңыз",
  "routesTrips.route.createGeozone.width": "Ені",
  "routesTrips.route.edit": "Маршрутты өңдеу",
  "routesTrips.route.name.placeholder": "Маршрут атауы",
  "routesTrips.route.point": "Нүкте",
  "routesTrips.routes": "Маршруттар",
  "routesTrips.title": "Маршруттар мен рейстер",
  "routesTrips.trip.activationType": "Белсендіру түрі",
  "routesTrips.trip.activationType.tooltip": "Егер «бірінші нүкте уақытында» таңдалса, онда ұшудың басталуы бірінші бақылау нүктесінде көрсетілген уақытқа сәйкес қатаң түрде бекітіледі. ",
  "routesTrips.trip.addDays": "Келесі нүктелерге бір күн қосыңыз",
  "routesTrips.trip.addFromGeozones": "Географиялық қоршауларды қосыңыз",
  "routesTrips.trip.addFromGeozones.title": "Географиялық қоршауларды қосыңыз",
  "routesTrips.trip.addFromMap": "Картадан нүкте қосыңыз",
  "routesTrips.trip.addFromRoutes": "Жол нүктелерін қосыңыз",
  "routesTrips.trip.addFromRoutes.title": "МАРШРУТ НҮКТЕЛЕРІН ҚОСУ",
  "routesTrips.trip.arrival": "Келу",
  "routesTrips.trip.arrivalDev": "Келуден бас тарту",
  "routesTrips.trip.clearDays": "Қосымшаны өшіру ",
  "routesTrips.trip.createGeozone.figure": "Сурет",
  "routesTrips.trip.createGeozone.name": "Аты",
  "routesTrips.trip.createGeozone.title": "Картадан нүкте қосыңыз",
  "routesTrips.trip.date.cancel_days_period": "Мереке күндерін қоспағанда",
  "routesTrips.trip.date.filter_days": "Күндерді сүзу",
  "routesTrips.trip.date.placeholder": "Күндерді таңдаңыз",
  "routesTrips.trip.date.reset_days_period": "Қалпына келтіру кезеңі күндері",
  "routesTrips.trip.date.set_days_period": "Мерзімнің күндерін сүзіңіз",
  "routesTrips.trip.date.total_days_period": "Кезеңнің жалпы күндері",
  "routesTrips.trip.date.week_days.all_days": "Барлық күндер",
  "routesTrips.trip.date.week_days.even": "Тіпті",
  "routesTrips.trip.date.week_days.friday": "PT",
  "routesTrips.trip.date.week_days.monday": "дүйсенбі",
  "routesTrips.trip.date.week_days.odd": "Тіпті емес",
  "routesTrips.trip.date.week_days.saturday": "SB",
  "routesTrips.trip.date.week_days.sunday": "Күн",
  "routesTrips.trip.date.week_days.thursday": "Бсен",
  "routesTrips.trip.date.week_days.tuesday": "В.Т",
  "routesTrips.trip.date.week_days.wednesday": "SR",
  "routesTrips.trip.departure": "Шығу",
  "routesTrips.trip.departureDev": "Элементті қабылдамау",
  "routesTrips.trip.driver": "Жүргізуші",
  "routesTrips.trip.editGeozone.figure": "Сурет",
  "routesTrips.trip.editGeozone.name": "Аты",
  "routesTrips.trip.editGeozone.title": "Географиялық қоршауды өңдеу",
  "routesTrips.trip.entranceToFirstPoint": "Бірінші нүктеге кірген кезде",
  "routesTrips.trip.firstPoint": "Бірінші нүктенің уақыты бойынша",
  "routesTrips.trip.leavingToFirstPoint": "Бірінші нүктеден шыққанда",
  "routesTrips.trip.name": "аты*",
  "routesTrips.trip.name.placeholder": "Ұшу атауы...",
  "routesTrips.trip.object": "Объект*",
  "routesTrips.trip.object.placeholder": "Объект",
  "routesTrips.trip.order": "Өткізу тәртібі",
  "routesTrips.trip.order.arbitrary": "Ерікті",
  "routesTrips.trip.order.strict": "Қатаң",
  "routesTrips.trip.period": "Кезең",
  "routesTrips.trip.point": "Нүкте",
  "routesTrips.trip.pointsValidation": "Кем дегенде екі нүктені таңдаңыз",
  "routesTrips.trip.removeDays": "Келесі нүктелерге күндерді жою",
  "routesTrips.trip.trailer": "Трейлер",
  "routesTrips.trip.validation": "Міндетті өріс",
  "routesTrips.trip.validation.total_period": "Сіз кем дегенде бір жалпы күн қосуыңыз керек",
  "routesTrips.trip.workType": "Жұмыс түрі",
  "routesTrips.trip.work_type_three": "Жұмыс түрі 3",
  "routesTrips.trip.work_type_two": "Жұмыс түрі 2",
  "routesTrips.trips": "Ұшулар",
  "save": "Сақтау",
  "search": "Іздеу",
  "search_company": "Объектілер бойынша іздеу",
  "select": "Таңдау",
  "select_all": "Барлығын таңдаңыз",
  "send": "Жіберу",
  "send-terminal": "Жіберу",
  "sensorNotificationTranslater.inFrame": "Белгіленген шектерде",
  "sensorNotificationTranslater.outFrame": "Белгіленген шеңберден тыс",
  "sensors_details.choose": "Таңдау",
  "sensors_details.help_validator": "Валидатор сенсор мәндерін сүзуге немесе түрлендіруге көмектеседі",
  "sensors_details.sensor_validator": "Сенсорды валидатор:",
  "sensors_details.validation_info": "3 күннен астам жарамды деректер болмаса, бақылау сенсордың мәнін көрсетпейді.",
  "sensors_details.validation_principle": "Валидация принципі:",
  "sessions.day": "Күн",
  "sessions.event": "Оқиға",
  "sessions.ip": "IP мекенжайы",
  "sessions.month": "Ай",
  "sessions.time": "Күн уақыты",
  "sessions.title": "Сеанстар",
  "sessions.user": "Пайдаланушы",
  "sessions.week": "Апта",
  "setting-company": "Компания параметрлері",
  "setting-user": "Пайдаланушы параметрлері",
  "settings.balance.company": "Компания:",
  "settings.balance.currency": "ысқылау",
  "settings.balance.errors.amount": "Толықтыру сомасын енгізіңіз",
  "settings.balance.min_amount": "Ең төменгі төлем сомасы",
  "settings.balance.mir_unavailable": "MIR карталарын сақтау үшін уақытша қолжетімсіз",
  "settings.balance.save_card": "Картаны сақтау",
  "settings.balance.top_up": "Толықтыру",
  "settings.balance.top_up_amount": "Толықтыру сомасы",
  "settings.balance.top_up_title": "Толықтыру",
  "settings.balance.turn_on_autopay": "Автоматты төлемді қосыңыз",
  "settings.company.data_format": "Күн пішімі:",
  "settings.company.exit_time": "n минут әрекетсіздіктен кейін шығыңыз",
  "settings.company.exit_time_tooltip": "0 немесе бос өріс - әрекетсіздікке байланысты шығу болмайды",
  "settings.company.high_security_mode": "Жетілдірілген қауіпсіздік режимі",
  "settings.company.high_security_mode_hint.first": "Қауіпсіздікті арттыру үшін осы режимді іске қосқан кезде жаңа пайдаланушылардан күшті құпия сөздерді жасау қажет болады. ",
  "settings.company.high_security_mode_hint.second": "Пайдаланушының құпия сөзін өзгерткен кезде олар әкімші құпия сөзін пайдаланып жүйеге кіріп, содан кейін тіркелгі үшін өздерінің құпия сөзін орнатуы керек. ",
  "settings.company.integrator": "Интегратор:",
  "settings.company.last_widget_time": "Виджеттегі соңғы күннің уақыты:",
  "settings.company.name": "Аты:",
  "settings.company.note": "Күндер ауқымындағы соңғы күнді таңдағанда (күнді таңдау виджетінде) қай уақыт пішімі пайдаланылатынын көрсетіңіз.",
  "settings.company.retransmit_efis": "EFIS жүйесіне қайта тарату",
  "settings.company.success.update_company": "Компания параметрлері сәтті жаңартылды",
  "settings.company.time_format": "Уақыт форматы:",
  "settings.company.time_zone": "Сағаттық белдеу:",
  "settings.company.title": "Компания параметрлері",
  "settings.display.area_measurement": "Аудан бірліктері:",
  "settings.display.geozones": "ГЕОЗОНДАР",
  "settings.display.group_by_dots": "Қашықтығы (экран пикселдері) аз болса топтаңыз:",
  "settings.display.hectar": "га",
  "settings.display.hide_events": "Оқиғаларды картада жасыру:",
  "settings.display.hide_notification": "Картадағы хабарландыруларды жасыру:",
  "settings.display.kilometer": "км",
  "settings.display.map": "Карта",
  "settings.display.meter": "м",
  "settings.display.monitoring": "Бақылау",
  "settings.display.notification": "Хабарландырулар",
  "settings.display.object_grouping": "Картадағы объектілерді топтастыру:",
  "settings.display.show_annotation": "Бақылау тізіміндегі нысанды басқан кезде аннотацияны көрсетіңіз:",
  "settings.display.show_geozones": "Картада географиялық қоршауларды көрсетіңіз:",
  "settings.display.title": "Дисплей параметрлері",
  "settings.display.track_weight": "Картадағы қалыңдығын қадағалаңыз",
  "settings.invite.access_level": "Қол жеткізу деңгейі:",
  "settings.invite.errors.access_level": "Қол жеткізу деңгейін таңдаңыз",
  "settings.invite.errors.user_email": "Электрондық поштаны енгізіңіз",
  "settings.invite.submit_button": "Шақыру",
  "settings.invite.success.invite": "Пайдаланушы сәтті шақырылды",
  "settings.invite.title": "Пайдаланушыны шақыру",
  "settings.invite.user_email": "Пайдаланушының электрондық поштасы:",
  "settings.invite.user_email_placeholder": "Электрондық поштаңызды енгізіңіз",
  "settings.main.access_settings": "Параметрлерге қол жеткізу",
  "settings.main.add_balance": "Баланс толтыру",
  "settings.main.additional": "Қосымша:",
  "settings.main.company": "Компания:",
  "settings.main.company_settings": "Компания параметрлері",
  "settings.main.company_tariff": "Компанияның тарифі",
  "settings.main.display_settings": "Дисплей параметрлері",
  "settings.main.exit": "Шығу",
  "settings.main.finance_and_balance": "Қаржы және теңгерім:",
  "settings.main.financical_report": "Қаржылық есеп",
  "settings.main.go_admin_panel": "Админге өтіңіз. ",
  "settings.main.go_admin_panel_beta": "Админ. ",
  "settings.main.go_back": "Қайту",
  "settings.main.invite_user": "Пайдаланушыны шақыру",
  "settings.main.news_and_updates": "Жаңалықтар мен жаңартулар",
  "settings.main.payments_history": "төлем тарихы",
  "settings.main.reference": "Анықтама (Wiki)",
  "settings.main.sessions": "Сеанстар",
  "settings.main.support": "Қолдау",
  "settings.main.work_with_company": "Компаниямен жұмыс:",
  "settings.support.contacts.email": "Электрондық пошта: monitoringplus@support.ru",
  "settings.support.contacts.phone": "Телефон (тек жұмыс күндері сағат 9:00-ден 18:00-ге дейін): 89008887712",
  "settings.support.contacts.telegram": "Telegram: @Monitoring_plus",
  "settings.support.description": "Қайырлы күн! ",
  "settings.support.title": "Қолдау",
  "settings.tariff.accounting_type": "Бухгалтерлік есеп түрі:",
  "settings.tariff.active_autopay": "Автоматты төлем іске қосылды",
  "settings.tariff.autopay": "Автоматты төлем:",
  "settings.tariff.by_number_objects": "Объектілер саны бойынша",
  "settings.tariff.by_number_objects_sent_data": "Деректерді жіберетін объектілер саны бойынша",
  "settings.tariff.card": "Карта:",
  "settings.tariff.card_section.add": "Картаны қосу",
  "settings.tariff.card_section.add_note": "Автотөлем картасын қосу үшін біз сізден 1 рубльді дебеттеуіміз керек. ",
  "settings.tariff.card_section.change": "Автоматты төлем үшін картаны ауыстыру",
  "settings.tariff.card_section.continue": "Жалғастыру",
  "settings.tariff.card_section.errors.mir_error": "MIR карталарымен автоматты төлем уақытша қолжетімсіз",
  "settings.tariff.card_section.mir_system": "MIR жүйесі",
  "settings.tariff.card_section.select_system": "*Төлем жүйесін таңдаңыз:",
  "settings.tariff.card_section.temporarily_unavailable": "Уақытша қол жетімсіз",
  "settings.tariff.card_section.visa_master": "Visa немесе Mastercard",
  "settings.tariff.errors.autopay": "Автоматты төлем мүмкіндігі өшірілді, себебі транзакцияны аяқтау үшін картада қаражат жеткіліксіз.",
  "settings.tariff.last_period_sum": "Өткен кезеңдегі сома",
  "settings.tariff.next_write_off": "Келесі есептен шығару:",
  "settings.tariff.no_card_added": "Карта қосылмаған",
  "settings.tariff.object_count": "Макс. ",
  "settings.tariff.object_price": "1 объектінің құны:",
  "settings.tariff.on_balance": "Шот бойынша (баланс):",
  "settings.tariff.payment_period": "Төлем мерзімі:",
  "settings.tariff.period_start": "Ағымдағы кезеңнің басы:",
  "settings.tariff.success.delete_card": "Карта сәтті жойылды",
  "settings.tariff.success.update_autopay": "Автотөлем сәтті жаңартылды",
  "settings.tariff.title": "Тарифтік ақпарат",
  "settings.tariff.turn_on_autopay": "Автоматты төлемді қосыңыз",
  "settings.user.access_level": "Қол жеткізу деңгейі:",
  "settings.user.change_access_level": "Қол жеткізу деңгейін өзгерту",
  "settings.user.change_password": "Құпия сөзді өзгерту",
  "settings.user.code": "Код:",
  "settings.user.description": "Сипаттама:",
  "settings.user.edit": "Өңдеу",
  "settings.user.email": "Электрондық пошта:",
  "settings.user.errors.admin_strong_pass": "Құпия сөз 15 пен 25 таңба аралығында болуы керек және кем дегенде бір ағылшын әріпінен, кем дегенде бір кіші ағылшын әріпінен және кем дегенде бір арнайы таңбадан (~ ! @ # $ % ^ & * ( ) - = + [ \"{ ] \" } | : \" \" , < . > / ?)",
  "settings.user.errors.email": "Электрондық поштаны енгізіңіз",
  "settings.user.errors.name": "Атыңызды енгізіңіз",
  "settings.user.errors.phone_confirmation": "Телефон нөмірі расталмаған.",
  "settings.user.errors.simple_pass": "Құпия сөз 5 пен 25 таңба арасында болуы керек және кем дегенде бір ағылшын әріпі мен кем дегенде бір саннан тұруы керек",
  "settings.user.errors.user_strong_pass": "Құпия сөз 8 және 25 таңба аралығында болуы керек және кем дегенде бір бас ағылшын әріпінен, кем дегенде бір кіші ағылшын әріпінен және кем дегенде бір арнайы таңбадан (~ ! @ # $ % ^ & * ( ) - = + [ \"{ ] \" } | : \" \" , < . > / ?)",
  "settings.user.language": "Тіл:",
  "settings.user.mailing_id": "Telegram үшін пошталық идентификаторлар:",
  "settings.user.name": "Аты:",
  "settings.user.note.first": "1) Telegram ботына кіріңіз: t.me/skif2_bot",
  "settings.user.note.second": "2) «Бастау» түймесін басыңыз, бот Telegram үшін пошталық идентификаторды қайтарады",
  "settings.user.phone": "Телефон:",
  "settings.user.set_password": "Құпия сөзді орнатыңыз",
  "settings.user.success.avatar_change": "Аватар сәтті жаңартылды",
  "settings.user.success.change_password": "Құпия сөз сәтті өзгертілді",
  "settings.user.success.update_info": "Ақпарат сәтті жаңартылды",
  "settings.user.user": "Қолданушы:",
  "status-terminal": "Күй",
  "success": "Жетістік",
  "tariff.accept": "Келісемін",
  "tariff.amount": "Шот сомасы",
  "tariff.amounttopay": "сомасы",
  "tariff.balance": "Есептік жазба бойынша:",
  "tariff.blocked-info": "Компанияның шотында қаражат жеткіліксіз. ",
  "tariff.choose": "Тарифтік жоспарды таңдау",
  "tariff.choose-pay": "ТАРИФТІ ТАҢДАҢЫЗ ЖӘНЕ ТӨЛЕҢІЗ",
  "tariff.choose-units": "Қажетті нысандар санын таңдаңыз:",
  "tariff.company-blocked": "компания бұғатталған",
  "tariff.currency": "білік",
  "tariff.currency-month": "валюта/ай",
  "tariff.currency-type": "валюталар",
  "tariff.current": "Сіздің ағымдағы тарифіңіз:",
  "tariff.date-time": "Күн уақыты",
  "tariff.days": "күндер",
  "tariff.info-one": "SKIF PRO жүйесінде 1 нысанның құны",
  "tariff.info-two--part-one": "қосулы",
  "tariff.info-two--part-two": "күнтізбелік күндер.",
  "tariff.information": "ТАРИФ ТУРАЛЫ АҚПАРАТ",
  "tariff.kz": "теңгені құрады",
  "tariff.less_than_four_days": "күні",
  "tariff.next-payment": "Келесі есептен шығару:",
  "tariff.notselected": "Таңдалмаған",
  "tariff.objects": "Объектілер",
  "tariff.offers": "ұсынады.",
  "tariff.one_day": "күні",
  "tariff.operation": "Операция",
  "tariff.paid": "Төленген кезең",
  "tariff.pay": "ТАРИФТІ ТӨЛЕҢІЗ",
  "tariff.payableto": "төлемге:",
  "tariff.payement-date": "Төлем:",
  "tariff.payment-history": "ЖАРЫҚТАР МЕН ТӨЛЕМДЕР ТАРИХЫ",
  "tariff.payments-history": "төлем тарихы",
  "tariff.price": "Тарифтік құны:",
  "tariff.remaining": "Сол:",
  "tariff.rouble": "ысқылау",
  "tariff.tariffprice": "Тарифтік құны:",
  "tariff.trial": "Сынақ мерзімі:",
  "tariff.txt-five": "қолдау қызметіне хабарласу керек.",
  "tariff.txt-four": "Тарифті төмендетемін десеңіздер",
  "tariff.txt-one": "SKIF PRO мониторинг жүйесін пайдалану ақылы.",
  "tariff.txt-seven": "тариф, төлем ережелері және",
  "tariff.txt-six": "«Келісу» түймесін басу арқылы сіз келісесіз",
  "tariff.txt-three": "Алғашқы 7 күн тегін.",
  "tariff.txt-two": "Сіз өзіңіздің компанияңызға қажетті тарифті таңдай аласыз.",
  "tariff.units": "нысандар",
  "templateCommandTranslater.add": "Пәрмен үлгісін қосу",
  "templateCommandTranslater.edit": "Пәрмен үлгісін өңдеу",
  "templateCommandTranslater.enterNameCommand": "Команда атын енгізіңіз",
  "templateCommandTranslater.enterTextCommand": "Пәрмен мәтінін енгізіңіз",
  "templateCommandTranslater.nameCommand": "Команда аты",
  "templateCommandTranslater.parametrsCommand": "Пәрмен опциялары",
  "templateCommandTranslater.selectCommand": "КОМАНДА ТАҢДАУ",
  "templateCommandTranslater.selectTypeTerminal": "Терминал түрін таңдаңыз",
  "templateCommandTranslater.textCommand": "Команда мәтіні",
  "templateCommandTranslater.typeTermial": "Терминал түрі",
  "text-command-terminal": "Команда мәтіні",
  "time": "Уақыт",
  "to": "Қосулы",
  "tools.admin_panel_beta": "βета сынағы",
  "tools.admin_panel_v2": "Админ. ",
  "tracking.speed.legend.text": "Жылдамдық градациясы",
  "tracking.speed.legend.mileage": "Жүгіру",
  "tracking.speed.legend.km": "км.",
  "trailer.width": "тіркеме ені (метр)",
  "translation.key": "мән",
  "type": "Түр",
  "units.create": "Объект құру",
  "units.update": "Объектіні өңдеу",
  "unitsVisiblityTranslater.filterFormMonitoring": "Мониторингтен сүзу қолданылды.",
  "unitsVisiblityTranslater.notShowAllObject": "Барлық нысандар көрсетілмейді.",
  "unitsVisiblityTranslater.showAllObject": "Барлық нысандарды көрсету",
  "user.create_user": "Пайдаланушыны құру",
  "user.edit_user": "Пайдаланушыны өңдеу",
  "user.enter_language": "Тіліңізді енгізіңіз!",
  "user.history_change": "Өзгерістер тарихы",
  "user.invite_all": "Барлық компанияларды шақырамыз",
  "user.invite_email": "Электрондық пошта",
  "user.invite_role": "Рөл",
  "user.invite_role_error": "Рөлді енгізіңіз!",
  "user.invite_user": "Шақыру",
  "users.approve_close_form": "Өзгерістер сақталмады. ",
  "users.change_password": "Өзгерту",
  "users.change_password_title": "Құпия сөзді өзгерту",
  "users.close_form": "профильді жабу",
  "users.confirm": "Растау",
  "users.confirm_link": "Сілтемесі бар хабарлама жіберіледі. ",
  "users.confirm_message": "SKIF.PRO жүйесінен ақпараттық хабарламаларды алуға келісіміңізді растауды сұрайтын хат жіберіледі.",
  "users.confirm_notification": "Электрондық пошта расталғанша пайдаланушы электрондық пошта хабарландыруларын алмайды.",
  "users.current_password": "Ағымдағы Құпия Сөз",
  "users.email": "Пошта",
  "users.email_placeholder": "Электрондық поштаңызды енгізіңіз",
  "users.enter": "Кіру",
  "users.exit": "Шығу",
  "users.hint.lang": "Тілді таңдаңыз",
  "users.hint.role": "Пайдаланушы рөлін таңдаңыз",
  "users.invalid_email": "Электрондық пошта расталмады.",
  "users.invalid_phone": "Телефон нөмірі расталмаған.",
  "users.invite": "Шақыру",
  "users.invite_all": "Барлық компанияларды шақырамыз",
  "users.invite_level_access": "Қол жеткізу деңгейі",
  "users.isDriver": "Жүргізуші",
  "users.name": "Аты",
  "users.password_empty": "Егер өрісті бос қалдырсаңыз, электрондық пошта арқылы құпия сөзді өзгерту туралы растау аласыз.",
  "users.sessions": "Сеанстар",
  "users.subscribe": "Пайдаланушыны шақыру",
  "users.success_email_send": "Хат сәтті жіберілді.",
  "users.success_message_send": "Хабар жіберілді.",
  "valid.text": "Өрісті толтырыңыз",
  "value": "Мағынасы",
  "word": "Сөз",
  "work_company.create_object": "Объектілерді құру",
  "work_company.data": "Терминалдан алынған деректер",
  "work_company.data_terminal.ste_3": "Журнал есебінде параметрлерді және басқа сенсорларды қосуға болады. ",
  "work_company.data_terminal.step_1": "Терминалдардағы өңделмеген «шикі» деректерді Журнал есебінде көруге болады. ",
  "work_company.data_terminal.step_2": "Содан кейін ашылған терезеде объектіні, нүктені таңдап, есептер тізіміндегі журналды басу керек",
  "work_company.short_list.action_eight": "Параметрлерді таңдаған кезде сіз оларды теңшей аласыз, бұл үшін қажетті параметрдің жанындағы беріліс түймесін басып, қажетті мәндерді енгізіңіз және сақтау түймесін басыңыз.",
  "work_company.short_list.action_five": "Ескертпе: Қажетті терминал түрін таңдағаннан кейін серверге деректерді жіберу үшін терминалдарды конфигурациялау қажет сервер мен портты көрсететін нұсқауға назар аударыңыз.",
  "work_company.short_list.action_four": "Ашылған пішінде барлық өрістерді толтырып, нысан үшін суретті таңдаңыз",
  "work_company.short_list.action_nine": "Сенсорды қосып, сақтағаннан кейін «Нысан жасау» түймесін басыңыз",
  "work_company.short_list.action_seven": "Әрі қарай, сіз «+» түймесін басуыңыз керек, сенсорды қосу терезесі ашылады, онда барлық өрістерді толтыру, параметрлер мен формулаларды таңдау керек.",
  "work_company.short_list.action_six": "Объектіге сенсорды қосу үшін жоғарғы ашылмалы тізімнен «Сенсорлар» тармағын таңдаңыз",
  "work_company.short_list.action_three": "Қосу түймесін басыңыз",
  "work_company.short_list.action_two": "Кез келген компаниядан Басқару тақтасына өту үшін пайдаланушы аты бар түймені басыңыз, содан кейін таңдалған компанияда «Әкімшілік тақтасы» элементіне өтіңіз",
  "work_company.title": "Компанияның жұмыс орны",
  "terminal.blocked": "Терминал бұғатталған",
  "yes": "Иә",
  "archive.title_settings": "Параметрлер",
  "archive.title_events": "Объекті бойынша оқиғалар",
  "archive.title": "Мемлекеттік мұрағат",
  "archive.subtitle": "Объект",
  "archive.search": "Іздеу",
  "archive.view": "Дисплей",
  "archive.add_state": "Күйді қосыңыз",
  "archive.state_name": "Мемлекет атауы",
  "archive.state_protocol": "Протокол",
  "archive.state_date": "бері белсенді",
  "archive.state_imei": "IMEI",
  "archive.current": "Ағымдағы",
  "archive.add_button": "қосу",
  "archive.zebra": "Зебра",
  "archive.state_edit": "Өңдеу",
  "archive.state_copy": "Көшіру және ағымдағы жасау",
  "archive.state_delete": "Жою",
  "archive.state_warning": "Сіз нысанның мұрағатталған күйін өңдеп жатырсыз.",
  "archive.state_save": "Өзгерістерді сақтау",
  "archive.state_cancel": "Болдырмау",
  "archives.groups_report": "Топтық есеп",
  "archives.warning": "Кейбір нысандарда таңдалған кезең үшін бірнеше мұрағат күйлері бар, сондықтан олар топ есебін құруға қатыса алмайды және алынып тасталды.",
  "archives.exclude": "Топтық есептен шығарылды",
  "archives.requestReport": "Есепті сұрау",
  "archives.object.haveState": "Ағымдағы нысан күйлерді қамтиды",
  "helper.monitoring.text_one": "Көліктің ағымдағы күйін бақылаңыз.",
  "helper.monitoring.text_other": "Көліктің ағымдағы күйін бақылаңыз.",
  "routesTrips.trip.work_type_one": "Жұмыс түрі 1",
  "routesTrips.trip.work_type_other": "Жұмыс түрі 1",
  "work_company.short_list.action_one": "Нысандарды жасау үшін компанияларыңыздың біріне бару керек. ",
  "work_company.short_list.action_other": "Нысандарды жасау үшін компанияларыңыздың біріне бару керек. "
}

export default translation
