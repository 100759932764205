<template lang="html">
  <div
    ref="userList"
    class="absolute z-30 shadow top-5 bg-white bottom-5 ml-5 grid grid-cols-2 pl-6 pt-5 w-module resize-x rounded-xl"
    style="grid-template-rows: auto auto auto 1fr; min-width: 480px; left: 68px"
  >
    <h1
      class="col-start-1 col-end-2 font-bold text-darkblue text-xl font-SourceSansPr whitespace-no-wrap font-SourceSansPro"
    >
      {{ $t('catalog.user') }}
    </h1>
    <div class="flex justify-end col-start-2 items-center">
      <img
        class="mr-5"
        src="@/assets/icons/history.svg"
        alt=""
        style="width: 18px"
        v-if="notRole"
        @click="$emit('show-history', { objects: 'users' })"
      />
      <el-popover
        popper-class="moreActionMonitoring"
        trigger="hover"
        v-model="hidePopoveHistory"
      >
        <p v-if="notRole" @click="isInviteUser = true">
          {{ $t('user.invite_user') }}
        </p>
        <p @click="isInviteUserAll = true" v-if="notRole">
          {{ $t('user.invite_all') }}
        </p>
      </el-popover>
      <i
        class="el-icon-close text-2xl pr-4 cursor-pointer text-darkblue font-semibold"
        @click="handlClose"
      />
    </div>
    <div
      class="col-start-1 col-end-3 pb-3 mr-4 mt-2 border-b border-dividerColor flex justify-between"
    >
      <skif-search
        v-model="filterString"
        :placeholder="$t('search')"
        class="w-full mr-6"
        @searching="searching"
      />
      <skif-button
        style="height: 35px"
        variant="normal"
        v-if="stateIcon === 'select'"
        @click="cancel"
      >
        {{ $t('btn.cancel') }}
      </skif-button>
      <skif-button
        v-if="stateIcon === 'normal'"
        style="height: 35px"
        :disabled="disabledRole"
        @click="$emit('create-user')"
      >
        {{ $t('btn.create') }}
      </skif-button>
    </div>
    <div
      class="relative col-start-1 col-end-3 flex items-center font-SourceSansPro pt-5 pb-3 text-annotationColor"
    >
      <button
        class="mr-8 pr-3 font-semibold focus_outline-none hover_text-headerText transition-colors duration-300"
        @click="
          ;(isInviteUser = true),
            (isShowing = true),
            (isShowingAll = false),
            (isChildActive = true)
        "
        type="button"
        style="font-size: 13px"
        :class="[isShowing ? 'isShowing' : '']"
      >
        {{ $t('users.invite') }}
      </button>
      <button
        v-if="isAdminPanel"
        class="mr-8 px-3 font-semibold focus_outline-none hover_text-headerText transition-colors duration-300"
        @click="
          ;(isInviteUserAll = true),
            (isShowingAll = true),
            (isShowing = false),
            (isChildActive = true)
        "
        type="button"
        style="font-size: 13px"
        :class="[isShowingAll ? 'isShowing' : '']"
      >
        {{ $t('users.invite_all') }}
      </button>
      <span v-if="stateIcon === 'select'" class="absolute right-0">
        <el-tooltip
          :open-delay="700"
          effect="dark"
          :content="$t('delete')"
          placement="bottom"
          :offset="2"
          :visible-arrow="false"
        >
          <button
            class="h-8 group relative link mr-3"
            @click="deleteUser"
            type="button"
          >
            <svg
              class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#91B4E7"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
              />
            </svg>
            <skif-badge list="list" :value="selectedUsers.length"> </skif-badge>
          </button>
        </el-tooltip>
      </span>
    </div>
    <div class="col-start-1 col-end-3 overflow-y-auto mx-auto">
      <table class="w-full table-fixed">
        <thead class="text-left">
          <th class="bg-white top-0 sticky z-10 w-6">
            <skif-checkbox
              class="p-2"
              @click.native="(event) => event.stopPropagation()"
              v-model="isAllChecked"
              :indeterminate="isIndeterminate"
              @change="checkAllUsers($event)"
            />
          </th>
          <th class="bg-white top-0 sticky w-24 pl-2">
            <span
              class="text-darkblue leading-10 cursor-pointer mr-2"
              style="font-size: 14px; color: #91b4e7"
              @click="sortChange('name')"
            >
              {{ $t('users.name') }}
              <svg
                class="ml-2 transition-transform ease-in-out duration-500"
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                :style="
                  sortProp === 'name' &&
                  sortOrder === 'descending' && { transform: 'scale(1,-1)' }
                "
              >
                <path
                  class="transition-colors ease-in-out duration-200"
                  d="M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z"
                  :fill="sortProp === 'name' ? '#3C5E8F' : '#91B4E7'"
                />
                <path
                  class="transition-colors ease-in-out duration-200"
                  d="M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z"
                  :fill="sortProp === 'name' ? '#3C5E8F' : '#91B4E7'"
                />
              </svg>
            </span>
          </th>
          <th
            class="bg-white top-0 sticky text-center bg-white"
            style="width: 200px"
          >
            <span
              class="text-darkblue leading-10 cursor-pointer"
              @click="sortChange('email')"
              style="font-size: 14px; color: #91b4e7"
            >
              {{ $t('users.email') }}
              <svg
                class="ml-2 transition-transform ease-in-out duration-500"
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                :style="
                  sortProp === 'email' &&
                  sortOrder === 'descending' && { transform: 'scale(1,-1)' }
                "
              >
                <path
                  class="transition-colors ease-in-out duration-200"
                  d="M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z"
                  :fill="sortProp === 'email' ? '#3C5E8F' : '#91B4E7'"
                />
                <path
                  class="transition-colors ease-in-out duration-200"
                  d="M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z"
                  :fill="sortProp === 'email' ? '#3C5E8F' : '#91B4E7'"
                />
              </svg>
            </span>
          </th>
          <th
            class="bg-white top-0 sticky text-right bg-red-200 cursor-pointer"
            @click="sortChange('online')"
            style="width: 40px; background-color: white"
          >
            <svg
              class="mr-2 transition-transform ease-in-out duration-500"
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :style="
                sortProp === 'online' &&
                sortOrder === 'descending' && { transform: 'scale(1,-1)' }
              "
            >
              <path
                class="transition-colors ease-in-out duration-200"
                d="M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z"
                :fill="sortProp === 'online' ? '#3C5E8F' : '#91B4E7'"
              ></path>
              <path
                class="transition-colors ease-in-out duration-200"
                d="M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z"
                :fill="sortProp === 'online' ? '#3C5E8F' : '#91B4E7'"
              ></path>
            </svg>
          </th>
        </thead>
        <tbody
          class="table-users"
          move-class="transition duration-1000"
          style="border-top: 4px solid white"
        >
          <div
            class="handlerScroll"
            v-infinite-scroll="handlerInfiniteScroll"
            :infinite-scroll-distance="10"
          ></div>
          <tr
            class="cursor-pointer hover_bg-reallylightblue"
            v-for="(user, i) in filtredUsers"
            @click="updateUser(user)"
            :key="i"
            :class="[user.isInArray ? 'itemSelected' : '']"
          >
            <td class="w-10">
              <skif-checkbox
                class="p-2"
                @click.native="(event) => event.stopPropagation()"
                v-model="user.isInArray"
                @change="changeChecked(user, $event)"
              />
            </td>
            <td class="ellipsis pl-2">
              <el-tooltip
                :open-delay="700"
                effect="dark"
                :content="user.name"
                placement="bottom"
                :offset="0"
                :visible-arrow="false"
              >
                <span class="leading-10 nameTable">
                  {{ user.name }}
                </span>
              </el-tooltip>
            </td>
            <td class="ellipsis pl-8 text-right">
              <span class="leading-10 nameTable">{{ user.email }}</span>
            </td>
            <td>
              <span
                :class="[
                  activeUsers.includes(user.id) ? 'userOnline' : 'userOffline'
                ]"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <transition name="dialog-fade">
      <invite-user-all
        :title="2"
        v-if="isInviteUserAll"
        @close="
          ;(isInviteUserAll = false),
            (isShowingAll = false),
            (isChildActive = false)
        "
      />
    </transition>
    <transition name="dialog-fade">
      <invite-user
        :title="1"
        v-if="isInviteUser"
        @close="
          ;(isInviteUser = false), (isShowing = false), (isChildActive = false)
        "
      />
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'
// import { usersApi } from '@/api'

export default {
  directives: { infiniteScroll },
  components: {
    inviteUser: () => import('@/components/user/inviteUsers'),
    inviteUserAll: () => import('@/components/user/inviteUsers')
  },
  data() {
    const errorMsg = {}
    errorMsg.email = this.$t('loginPage.errors.email')

    const validateEmail = function (rule, value, callback) {
      const test =
        /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(?:aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/.test(
          value
        )
      if (test) {
        setTimeout(() => {
          callback()
        }, 1000)
      } else {
        callback(new Error(errorMsg.email))
      }
    }

    const validateRole = function (role, value, callback) {
      if (value === '') {
        callback(new Error(errorMsg.role))
      }
      callback()
    }
    return {
      isChildActive: false,
      isShowing: false,
      isShowingAll: false,
      sortProp: null,
      sortOrder: null,
      isInviteUser: false,
      stateIcon: 'normal',
      isDeleting: false,
      isInviteUserAll: false,
      userModal: false,
      loading: false,
      submitError: '',
      addUser: true,
      inviteForm: {
        email: '',
        role: ''
      },
      inviteRules: {
        email: [{ validator: validateEmail, trigger: 'blur' }],
        role: [
          {
            message: this.$t('user.invite_role_error'),
            validator: validateRole,
            trigger: 'blur'
          }
        ]
      },
      filterString: '',
      inviteUser: false,
      editUserForm: false,
      selectedUser: {},

      selectedItem: [],
      headers: [
        {
          prop: 'name',
          label: this.$t('name'),
          sortable: true
        },
        {
          prop: 'role',
          label: this.$t('role'),
          sortable: true
        },
        {
          prop: 'email',
          label: this.$t('email'),
          sortable: true
        },
        {
          prop: 'phone',
          label: this.$t('phone'),
          sortable: true
        }
      ],
      hidePopoveHistory: false,
      isAllChecked: false,
      isIndeterminate: false,
      selectedUsers: [],
      isOnline: false,
      count: 50,
      isAdminPanel: false
    }
  },
  computed: {
    usersIds() {
      return this.filtredUsers.map((k) => k.id)
    },
    setDataTable() {
      return this.users.filter((user) =>
        JSON.stringify(user)
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
      )
    },
    filtredUsers() {
      return this.users.reduce((result, user) => {
        const isInArray = this.selectedUsers.map((k) => k.id).includes(user.id)
        const users = JSON.stringify(user)
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
          ? [...result, { ...user, isInArray }]
          : result
        return this.sortOrder === 'ascending'
          ? this.sortProp === 'name'
            ? users
                .sort((a, b) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )
                .slice(0, this.count)
            : this.sortProp === 'online'
            ? users
                .sort((a, b) => (this.activeUsers.includes(b.id) ? 1 : -1))
                .slice(0, this.count)
            : this.sortProp === 'email'
            ? users
                .sort((a, b) =>
                  a.email > b.email ? 1 : b.email > a.email ? -1 : 0
                )
                .slice(0, this.count)
            : users.slice(0, this.count)
          : this.sortOrder === 'descending'
          ? this.sortProp === 'name'
            ? users
                .sort((a, b) =>
                  a.name > b.name ? -1 : b.name > a.name ? 1 : 0
                )
                .slice(0, this.count)
            : this.sortProp === 'online'
            ? users
                .sort((a, b) => (this.activeUsers.includes(b.id) ? -1 : 1))
                .slice(0, this.count)
            : this.sortProp === 'email'
            ? users
                .sort((a, b) =>
                  a.email > b.email ? -1 : b.email > a.email ? 1 : 0
                )
                .slice(0, this.count)
            : users.slice(0, this.count)
          : users.slice(0, this.count)
      }, [])
    },
    ...mapGetters('login', ['role', 'disabledRole', 'notRole', 'me']),
    ...mapState('users', {
      users: (state) => state.users,
      activeUsers: (state) => state.activeUsers,
      recoveryId: (state) => state.recoveryId
    }),
    ...mapState({
      roles: (state) => state.properties.roles
    })
  },

  watch: {
    filtredUsers() {
      const valIds = this.selectedUsers.map((k) => k.id)
      this.isAllChecked =
        !!this.usersIds.length &&
        this.usersIds.every((id) => valIds.includes(id))
      this.isIndeterminate =
        !this.isAllChecked && this.usersIds.some((id) => valIds.includes(id))
    }
  },
  methods: {
    handlerInfiniteScroll() {
      this.handlerScroll()
    },
    handlerScroll() {
      // позиционирование popover
      const el = document.querySelector('.table-users')
      if (
        Math.ceil(el.scrollTop + el.clientHeight) >= el.scrollHeight &&
        this.filtredUsers.length >= this.count
      ) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.count += 10
        }, 200)
      }
    },
    searching(val) {
      console.log(val, 'val')
      this.filterString = val
    },
    closeDeletedNotification() {
      setTimeout(() => {
        this.$notify.closeAll()
      }, 10000)
    },
    handlClose() {
      if (this.isChildActive === false) {
        this.$emit('close')
      } else {
        this.isInviteUser = false
        this.isInviteUserAll = false
        this.isShowingAll = false
        this.isShowing = false
        this.isChildActive = false
      }
    },
    changeChecked(user, $event) {
      this.selectedUsers = $event.target.checked
        ? [...this.selectedUsers, user]
        : this.selectedUsers.filter((el) => el.id !== user.id)
      const selectedLength = this.selectedUsers.length
      if (selectedLength === this.filtredUsers.length) {
        this.isAllChecked = true
        this.isIndeterminate = false
        this.stateIcon = 'select'
      } else if (!selectedLength) {
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      } else {
        this.isAllChecked = false
        this.isIndeterminate = true
        this.stateIcon = 'select'
      }
    },
    sortChange(prop) {
      if (this.sortProp === prop) {
        if (this.sortOrder === 'ascending') {
          this.sortOrder = 'descending'
        } else if (this.sortOrder === 'descending') {
          this.sortOrder = ''
          this.sortProp = ''
        } else {
          this.sortOrder = 'ascending'
        }
      } else {
        this.sortProp = prop
        this.sortOrder = 'ascending'
      }
    },
    checkAllUsers($event) {
      if ($event.target.checked) {
        this.selectedUsers = this.filtredUsers
        this.isAllChecked = true
        this.isIndeterminate = false
        this.stateIcon = 'select'
        console.log()
      } else {
        this.selectedUsers = []
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      }
    },
    cancel() {
      this.stateIcon = 'normal'
      // this.$refs.selectedUsers.clearSelection()
      this.selectedUsers = []
    },
    selectionItem(data) {
      this.stateIcon = data.length === 0 ? 'normal' : 'select'
      this.selectedUsers = data
    },
    recoverItems(items) {
      const recoveryObject = {
        id: this.recoveryId,
        itemsDeleted: items
      }
      this.$store.dispatch('users/RECOVER_USERS', recoveryObject)
      this.$notify.closeAll()
    },
    deleteUser() {
      const someUnits = this.users.filter((el) =>
        this.selectedUsers.some((item) => el.id === item.id)
      )
      console.log(someUnits)
      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div class="confirmation-container"> 
                      <span  > ${
                        this.selectedUsers.length <= 1
                          ? this.$t('one_element_deleted')
                          : this.selectedUsers.length +
                            this.$t('already_deleted_elements')
                      } </span>
                      <span id='confirm' class='cursor-pointer cancelConfirm text-annotationColor'>${this.$t(
                        'cancel'
                      )}</span>          
                    </div>`,
        duration: 10000,
        position: 'bottom-left',
        customClass: 'confirmation'
      })
      this.closeDeletedNotification()
      const confirmation = document.getElementById('confirm')
      const self = this
      confirmation.addEventListener(
        'click',
        function () {
          self.recoverItems(someUnits)
        },
        false
      )
      this.$store.dispatch('users/DELETE', this.selectedUsers).then((_) => {
        this.cancel()
      })
    },
    updateUser(user) {
      this.$emit('update-user', user, false)
    },

    close() {
      this.inviteUser = false
    }
  },
  mounted() {
    this.isAdminPanel = this.me.active_company.is_admin_panel

    const windowSize = JSON.parse(localStorage.getItem('userListWidth'))
    this.$refs.userList.style.width = `${windowSize}px`
  },
  beforeDestroy() {
    const windowWidth = this.$refs.userList.clientWidth
    localStorage.setItem('userListWidth', windowWidth)
  }
}
</script>
<style>
.isShowing {
  color: #5477a9;
}
.nameTable {
  color: #5477a9;
  font-size: 14px;
  font-weight: bold;
}

.ellipsis {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #91b4e7;
}
.itemSelected {
  background-color: #eef5ff;
}
.itemSelected .nameTable {
  color: #3c5e8f;
}
.userOffline {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #cfdbeb;
  border-radius: 50%;
  margin-left: 20px;
}
.userOnline {
  width: 12px;
  height: 12px;
  background-color: #4abc96;
  border-radius: 50%;
  display: inline-block;
  margin-left: 20px;
}
</style>
<style lang="stylus">
.confirmation {
  width: 466px;
  background-color: #eff4fb;
  margin-left: 4.5rem;

  .el-notification__group {
    margin-right: 30px;
    width: 95%;

    .el-notification__content {
      width: 95%;

      p {
        .confirmation-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .el-icon-close {
      margin-top: 3px;

      &::before {
        font-weight: bolder;
        font-size: 18px;
      }
    }
  }
}
</style>
