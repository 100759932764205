<template>
  <div
    class="z-30 bg-white shadow top-5 left-10 absolute grid grid-cols-2 pl-6 pr-4 pt-5 ml-8 w-module rounded-xl"
    style="
      grid-template-rows: min-content min-content min-content min-content min-content min-content;
    "
  >
    <span
      class="col-start-1 col-end-2 font-bold text-xl text-darkblue flex justify-between font-SourceSansPro whitespace-no-wrap"
    >
      {{ $t('geozones.edit') }}
    </span>
    <div class="flex justify-end col-start-2 items-center">
      <img
        v-if="notRole"
        alt=""
        class="mr-5"
        src="../../assets/icons/history.svg"
        style="width: 18px"
        @click="
          $emit('show-history', { objects: 'geozones', objectId: geozone.id })
        "
      />
      <i
        class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="cancel"
      />
    </div>
    <div
      class="flex justify-end col-start-1 col-end-3 border-b border-divider pb-3 mt-6"
    >
      <skif-button class="w-full" variant="normal" @click="cancel">
        {{ $t('btn.cancel') }}
      </skif-button>
      <skif-button
        class="ml-6 w-full"
        :disabled="disabledRole && role !== 'EDITOR'"
        @click="update"
      >
        {{ $t('btn.update') }}
      </skif-button>
    </div>
    <span
      class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3"
    >
      {{ $t('geozones.name') }}
    </span>
    <el-input
      v-model="geozone.name"
      class="col-start-1 col-end-3"
      style="width: 300px"
      :placeholder="$t('geozones.name')"
    />
    <span
      class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3"
    >
      {{ $t('geozones.type') }}
    </span>
    <button
      class="col-start-1 col-end-3 h-9 border focus_outline-none rounded focus_border-darkblue transition-colors duration-200 font-bold text-sm text-someGrey"
      type="button"
      style="width: 300px"
      @click="isIconShowen = !isIconShowen"
    >
      <div class="flex items-center justify-between px-3">
        <span>{{ getGeozoneIcon(geozone.icon.key) }}</span>
        <svg
          class="duration-300"
          :style="[
            isIconShowen
              ? { transform: 'rotateZ(-180deg)' }
              : { transform: 'rotateZ(0deg)' }
          ]"
          width="14"
          height="8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.266 0L7 4.823 1.734 0 0 1.589 7 8l7-6.411L12.266 0z"
            fill="#5477A9"
          />
        </svg>
      </div>
    </button>
    <transition
      enter-active-class="duration-300 ease-in"
      leave-active-class="duration-300"
      enter-to-class="overflow-hidden max-h-320"
      leave-class="overflow-hidden max-h-320"
      leave-to-class="overflow-hidden max-h-0"
      enter-class="overflow-hidden max-h-0"
    >
      <ul
        v-show="isIconShowen"
        class="col-start-1 col-end-3 border border-divider rounded-sm mt-1 overflow-y-auto"
      >
        <li
          v-for="item in geozoneTypes"
          :key="item.key"
          :class="[
            'leading-8',
            'h-8',
            'block',
            'cursor-pointer',
            'px-3',
            'font-bold',
            'text-sm',
            'hover_bg-reallylightblue',
            geozone.icon.key === item.key ? 'text-textmain' : 'text-someGrey'
          ]"
          @click="
            geozone.icon.key = item.key
            $store.commit('geozones/TYPE_CHANGE', geozone.icon.key)
            isIconShowen = false
          "
        >
          {{ item.value }}
        </li>
      </ul>
    </transition>
    <span
      v-if="geozone.type.key === 'line'"
      class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3"
    >
      {{ $t('routesTrips.route.createGeozone.width') }}
      <el-input
        v-if="geozone.type.key === 'line'"
        v-model.number="geozone.width"
        class="col-start-1 col-end-3"
        style="width: 300px; font-family: 'Lato'"
        type="number"
        @input="$store.commit('geozones/SET_WIDTH', geozone.width)"
      />
    </span>
    <span
      class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro pt-4 pb-1"
    >
      {{ $t('geozones.color') }}
    </span>
    <div
      v-model="geozone.color.key"
      class="flex items-center col-start-1 col-end-3 mb-8 mt-3 h-6"
    >
      <div
        class="mr-8 rounded-full cursor-pointer transition-all duration-300"
        v-for="color in colors"
        :key="color.key"
        :style="{ backgroundColor: color.key }"
        :class="
          geozone.color.key === color.key
            ? 'w-6 h-6 border-2 border-headerText'
            : 'w-5 h-5'
        "
        @click="setActiveClass(color)"
      ></div>
    </div>
    <skif-checkbox
      v-model="geozone.use_as_address"
      class="mb-6"
      :disabled="disabledRole && role !== 'EDITOR'"
      :label="$t('geozones.use_as_address')"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: ['geozone', 'admin'],
  data() {
    return {
      isIconShowen: false,
      theGeozone: ''
    }
  },

  computed: {
    ...mapState('dictionary', {
      geozoneType: (state) => state.geozone_type,
      geozoneTypes: (state) => state.geo_icon,
      colors: (state) => state.color_code
    }),
    ...mapGetters({
      getGeozoneIcon: 'dictionary/getGeozoneIcon',
      role: 'login/role',
      disabledRole: 'login/disabledRole',
      notRole: 'login/notRole'
    }),
    ...mapState('geozones', {
      currentGeozone: (state) => state.currentGeozone
    })
  },
  watch: {
    '$parent.spinnerLoading': function (val) {
      if (val) {
        this.$store.commit('geozones/END_EDITING', this.theGeozone.id)
        this.$emit('close', false)
      }
    },
    currentGeozone(val) {
      switch (this.theGeozone.type.key) {
        case 'point':
          this.theGeozone.geometry = [val.latlng]
          console.log('new latlng', this.theGeozones)
          break
        case 'circle':
          this.theGeozone.width = val.radius
          this.theGeozone.geometry = [val.latlng]
          break
        default:
          this.theGeozone.geometry = val.latlng
          break
      }
    }
  },
  methods: {
    cancel() {
      this.$store.commit('geozones/END_EDITING', this.theGeozone.id)
      this.$emit('close')
    },
    setActiveClass(color) {
      this.theGeozone.color.key = color.key
      this.$store.commit('geozones/SET_COLOR', color.key)
    },

    update() {
      if (this.theGeozone.name === '') {
        this.$showError(this.$t('geozones.errors.name'))
      } else {
        this.$store
          .dispatch('geozones/UPDATE_GEOZONE', this.theGeozone)
          .then(() => this.$emit('close'))
      }

      this.$store.commit('geozones/END_EDITING', this.theGeozone.id)
    }
  },

  created() {
    this.theGeozone = this.geozone
    if (this.theGeozone.color.key === '#1BB934') {
      this.theGeozone.color.key = '#4ABC96'
    } else if (this.theGeozone.color.key === '#1585D8') {
      this.theGeozone.color.key = '#2F80ED'
    }
  }
}
</script>

<style lang="stylus" scoped>
.color-picker__edit {
  display: flex;
  align-items: center;
}

.color-picker__select {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 20px;
  margin-top: 11px;
  display: inline-block;

  &.active {
    border: 2px solid #5477A9;
    width: 25px;
    height: 25px;
  }
}
</style>
