import { notificationsApi } from '@/api'
import eventBus from '@/eventBus.js'

const defaultState = {
  recoveryId: null,
  notifications: [],
  savedNotifications: [],
  markerId: { '': false },
  isResetNotifications: false,
  notificationUnitsIds: []
}
const getters = {
  notificationUnitsIds: state => state.notificationUnitsIds
}
const actions = {
  setCheckedUnits({ commit }, unitsIds) {
    commit('SET_UNITS_IDS', unitsIds)
  },
  GET({ commit }) {
    notificationsApi.getNotifications(
      response => {
        commit('SET', response.data.list)
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  TOGGLE({ commit }, { id, formData }) {
    notificationsApi.toggleNotification(
      id,
      { formData },
      response => {
        commit('UPDATE', response.data)
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  WEBSOCKET_CHANGE({ commit }, { id, operation }) {
    if (operation === 'DELETE') {
      commit('DELETE', id)
    } else {
      notificationsApi.getNotificationById(
        id,
        response => {
          if (operation === 'POST') {
            commit('ADD', response.data)
          } else {
            commit('UPDATE', response.data)
          }
        },
        error => {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },
  CREATE({ dispatch }, formData) {
    return new Promise((resolve, reject) => {
      notificationsApi.createNotification(
        { formData },
        _ => {
          dispatch('GET')
          resolve()
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  DELETE({ commit }, notifications) {
    let promiseList = []
    promiseList.push(
      new Promise(function (resolve, reject) {
        notificationsApi.deleteNotification(notifications, resolve, reject)
      })
    )
    Promise.all(promiseList)
      .then(res => {
        commit('DELETE_NOTIFICATIONS', notifications)
        commit('SET_RECOVERYID', res[0].data)
      })
      .catch(function (error) {
        console.log(error)
        eventBus.$showError(error.response.data.message)
      })
  },
  RECOVER_NOTIFICATIONS({ commit }, data) {
    return new Promise((resolve, reject) => {
      notificationsApi.recover(
        data.id,
        response => {
          commit('RECOVER', data)
          resolve()
        },
        error => {
          console.log(error)
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE({ dispatch }, formData) {
    return new Promise((resolve, reject) => {
      notificationsApi.updateNotification(
        formData.id,
        { formData },
        _ => {
          dispatch('GET')
          resolve()
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  }
}
const mutations = {
  SET_UNITS_IDS(state, unitsIds) {
    state.notificationUnitsIds = unitsIds
  },
  DELETE_NOTIFICATIONS(state, notifications) {
    state.notifications = state.notifications.filter(
      item => !notifications.map(k => k.id).includes(item.id)
    )
  },
  SET_RECOVERYID(state, id) {
    state.recoveryId = id
  },
  RECOVER(state, units) {
    units.itemsDeleted.forEach(unit => state.notifications.splice(unit.index, 0, unit))
  },
  SET(state, notifications) {
    const indexedItems = notifications.map((item, index) => ({
      index,
      ...item
    }))
    state.notifications = indexedItems
  },
  ADD(state, notification) {
    if (!state.notifications.some(x => x.id === notification.id)) {
      state.notifications = [...state.notifications, notification]
    }
  },
  UPDATE(state, notification) {
    const indexOfNotification = state.notifications.findIndex(
      elem => elem.id === notification.id
    )
    state.notifications = [
      ...state.notifications.slice(0, indexOfNotification),
      notification,
      ...state.notifications.slice(indexOfNotification + 1)
    ]
  },
  DELETE(state, id) {
    state.notifications = state.notifications.filter(x => x.id !== id)
  },
  SAVED(state, ids) {
    state.savedNotifications = ids
  },
  MARKER_ZOOM(state, markerId) {
    state.markerId = {
      [markerId]: !Object.values(state.markerId)[0]
    }
  }
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
