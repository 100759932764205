import { propertiesApi } from '@/api'

const defaultState = {
  roles: [],
  integrators: [],
  timezones: [],
  timezoneMapping: {
    'UTC-12': 'Etc/GMT+12',
    'UTC-11': 'Pacific/Pago_Pago',
    'UTC-10': 'Pacific/Honolulu',
    'UTC-9': 'America/Anchorage',
    'UTC-8': 'America/Los_Angeles',
    'UTC-7': 'America/Denver',
    'UTC-6': 'America/Chicago',
    'UTC-5': 'America/New_York',
    'UTC-4': 'America/Halifax',
    'UTC-3': 'America/Argentina/Buenos_Aires',
    'UTC-2': 'Atlantic/South_Georgia',
    'UTC-1': 'Atlantic/Azores',
    'UTC+0': 'UTC',
    'UTC+1': 'Europe/Paris',
    'UTC+2': 'Europe/Kiev',
    'UTC+3': 'Europe/Moscow',
    'UTC+4': 'Asia/Dubai',
    'UTC+5': 'Asia/Karachi',
    'UTC+6': 'Asia/Dhaka',
    'UTC+7': 'Asia/Bangkok',
    'UTC+8': 'Asia/Shanghai',
    'UTC+9': 'Asia/Tokyo',
    'UTC+10': 'Australia/Sydney',
    'UTC+11': 'Pacific/Guadalcanal',
    'UTC+12': 'Pacific/Fiji',
    'UTC+13': 'Pacific/Tongatapu'
  },
  languages: [],
  customFields: [],
  accessRights: [],
  organizationType: [],
  unitType: [],
  feedback_type: [],
  payModules: [],
  tariffType: [],
  news: []
}

const getters = {
  getNews: state => {
    return state.news.at(-1)
  },
  getTZ: state => {
    return state.timezones
  },
  customField: state => key => {
    return state.customFields.find(field => field.key === key)
  },
  payModules: state => {
    return state.payModules
  },
  organizationType: state => {
    return state.organizationType
  },
  tariffType: state => {
    return state.tariffType
  },
  languages: state => {
    return state.languages || []
  },
  roles: state => {
    return state.roles || []
  }
}

const actions = {
  getAllProperties({ commit }) {
    return new Promise(function (resolve, reject) {
      propertiesApi.getAllProperties(
        function (response) {
          commit('SET_ALLPROPERTIES', response.data)
          resolve()
        },
        function (error) {
          console.log(error, 'error in function getAllProperties in properties.js')
          reject(error)
        }
      )
    })
  },
  getPropertiesByType({ commit }, type) {
    return new Promise(function (resolve, reject) {
      propertiesApi.getProperties(
        type,
        function (response) {
          commit('SET_PROPERTIES', {
            propertiesName: type,
            values: response.data
          })
          resolve()
        },
        function (error) {
          console.log(error, 'error in properties.js')
          reject(error)
        }
      )
    })
  },

  getPropertiesByTypeList({ commit }, list) {
    let arrayList = []
    list.forEach(type => {
      arrayList.push(actions.getPropertiesByType({ commit }, type))
    })
    return Promise.all(arrayList)
  },
  CUSTOM_FIELDS({ commit }) {
    propertiesApi.getCustomFields(
      function (response) {
        // const patterns = response.request.response
        //   .match(/pattern":".*?"/g)
        //   .map(elem => elem.replace('pattern":"', '').replace('"', ''))

        // const customFields = response.data.map((obj, index) => ({
        //   value: obj.default_value,
        //   key: obj.key,
        //   pattern: obj.pattern,
        //   name: obj.name,
        //   type: obj.type,
        //   values: obj.values
        // }))
        commit('SET_CUSTOMFIELDS', response.data)
      },
      function (error) {
        console.log(error)
      }
    )
  },
  setIntegrator({ commit }) {
    propertiesApi.setIntegrator(
      response => {
        commit('SET_INTEGRATOR', response.data)
      },
      error => {
        console.log(error)
      }
    )
  },
  GET_ACCESS_RIGHTS({ commit, rootState }) {
    propertiesApi.getAccessRights(
      response => {
        commit('SET_ACCESS_RIGHTS', response.data[rootState.login.me.role.key.toLowerCase()])
      },
      error => {
        console.log(error)
      }
    )
  },
  sendMessageNews({ commit }, payload) {
    propertiesApi.sendMessagesNew(payload)
  }
}

const mutations = {
  SET_PROPERTIES(state, { propertiesName, values }) {
    state[propertiesName] = values
    console.log('propertiesName', propertiesName)
    console.log('state', state)
  },
  SET_ALLPROPERTIES(state, props) {
    state.languages = props.filter(el => el.type === 'languages')
    state.roles = props.filter(el => el.type === 'roles')
    state.timezones = props.filter(el => el.type === 'timezones')
    state.organizationType = props.filter(el => el.type === 'dealer_types')
    state.unitType = props.filter(el => el.type === 'unit_type')
    state.feedback_type = props.filter(el => el.type === 'feedback_type')
    state.payModules = props.filter(el => el.type === 'payment_types')
    state.tariffType = props.filter(el => el.type === 'tariff_type')
  },
  SET_CUSTOMFIELDS(state, customFields) {
    state.customFields = customFields
  },
  SET_INTEGRATOR(state, integrator) {
    state.integrators = integrator
  },
  SET_ACCESS_RIGHTS(state, accessRights) {
    state.accessRights = accessRights
  },
  setNews(state, payload) {
    state.news = payload
  }
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
