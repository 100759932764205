import axios from 'axios'

const HTTP = axios.create({
  baseURL: '/api_v1/'
})

HTTP.interceptors.response.use(null, function(error) {
  const { config, response } = error
  if (config !== undefined && config.needAuth && response.status === 401) {
    setTimeout(() => {
      console.log('error!')
      location.reload()
    }, 2000)
  }
  if (response.status === 500) {
    if (window.showError !== undefined) {
      window.showError(error)
    }
  }
  return Promise.reject(error)
})

export default HTTP
