<template>
  <div
    class="bg-white shadow absolute bottom-5 top-5 z-30 overflow-y-auto rounded-xl"
    style="width: 500px"
    :class="isUpdatingMyself ? 'right-5' : 'left-5 ml-12'"
  >
    <div class="wrap wrapContainer" style="padding: 10px 25px">
      <div class="flex justify-between">
        <div class="text-darkblue font-bold text-xl fontSource">
          {{ header }}
        </div>
        <div class="flex justify-center items-center">
          <img
            alt="history"
            src="../../assets/icons/history.svg"
            style="width: 18px; height: 24px"
            v-if="notRole"
            @click="
              $emit('show-history', { objects: 'users', objectId: user.id })
            "
          />
          <el-popover
            v-if="(title === 2, !admin)"
            popper-class="tooltip-user"
            trigger="hover"
            v-model="hidePopoveHistory"
          >
            <P v-if="hasRights" @click="changePasswordModal = true">{{
              $t('users.change_password_title')
            }}</P>
            <button type="button" slot="reference">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.2501 7.5C13.4876 7.5 14.5001 6.4875 14.5001 5.25C14.5001 4.0125 13.4876 3 12.2501 3C11.0126 3 10.0001 4.0125 10.0001 5.25C10.0001 6.4875 11.0126 7.5 12.2501 7.5V7.5ZM12.25 9.74966C11.0125 9.74966 10 10.7622 10 11.9997C10 13.2372 11.0125 14.2497 12.25 14.2497C13.4875 14.2497 14.5 13.2372 14.5 11.9997C14.5 10.7622 13.4875 9.74966 12.25 9.74966V9.74966ZM12.25 16.5002C11.0125 16.5002 10 17.5127 10 18.7502C10 19.9877 11.0125 21.0002 12.25 21.0002C13.4875 21.0002 14.5 19.9877 14.5 18.7502C14.5 17.5127 13.4875 16.5002 12.25 16.5002V16.5002Z"
                  fill="#5477A9"
                ></path>
              </svg>
            </button> </el-popover
          ><i
            class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
            @click="closeForm"
          ></i>
        </div>
      </div>
      <div class="flex mt-4">
        <skif-button class="mr-4 w-full" variant="normal" @click="closeForm">{{
          $t('cancel')
        }}</skif-button>
        <skif-button
          class="w-full"
          :disabled="!hasRights"
          @click="save('userForm')"
          >{{ $t('btn.save') }}</skif-button
        >
      </div>
      <!-- Форма изменения-->
      <div class="modal-body">
        <el-form
          :model="user"
          ref="userForm"
          label-width="50"
          :label-position="`top`"
          :size="`medium`"
          :rules="userFormRules"
          autocomplete="off"
        >
          <div class="left-side form fontSource">
            <el-form-item prop="photo">
              <div
                class="user-avatar__container"
                v-loading="avatarLoading"
                @click="hasRights && $refs.file.click()"
              >
                <img
                  alt="avatar"
                  class="user-avatar__image"
                  v-if="avatarUrl && currentRole !== 'READER'"
                  :src="'/' + 'api_v1' + avatarUrl"
                />
                <img
                  class="user-avatar__image"
                  alt="avatar"
                  v-else
                  src="@/assets/img/Userpic.svg"
                  style="width: 105px"
                />
                <span for="avatar">Загрузить иконку</span>
              </div>
              <label for="avatar"></label>
              <input
                id="avatar"
                ref="file"
                :value="avatar"
                @change="uploadAvatar"
                type="file"
                style="display: none"
              />
            </el-form-item>
            <el-form-item
              class="emailItem"
              prop="email"
              :label="'*' + $t('loginPage.email')"
            >
              <el-input
                class="firstInput mt-3"
                :disabled="!hasRights"
                v-model="user.email"
                type="zipcode"
                name="pass"
                autocomplete="password"
              />
              <div v-if="showMailValidation" class="validate mt-2">
                <span class="email-confirm">
                  {{ $t('users.invalid_email') }}
                  <el-tooltip
                    :open-delay="200"
                    effect="dark"
                    placement="bottom"
                    :offset="0"
                    :visible-arrow="true"
                  >
                    <span class="skif-tooltip-ul" slot="content">
                      {{ $t('users.confirm_notification') }}
                    </span>
                    <span> <infoEmailIcon></infoEmailIcon></span>
                  </el-tooltip>
                </span>
                <span @click="confirmEmail" class="validate-btn">
                  {{ $t('users.confirm') }}
                </span>
              </div>
            </el-form-item>
            <!-- form item for password and another for repeat  -->
            <div v-if="hasRights && title === 1">
              <div>
                <div class="w-full mb-2">
                  <span class="password-label">*Пароль для пользователя</span>
                  <el-input
                    show-password="show-password"
                    type="password"
                    class="mt-2"
                    v-model="user.password"
                    autocomplete="new-password"
                  />
                  <p class="password-requirements">
                    {{ passwordRequirements }}
                  </p>
                </div>
              </div>
              <div>
                <div class="w-full mb-2">
                  <span class="password-label">*Пароль ещё раз</span>
                  <el-input
                    type="password"
                    class="mt-2"
                    v-model="password_confirm"
                    show-password="show-password"
                  />
                </div>
                <p v-if="passwordNotMatch" class="password-error">
                  Пароли должны совпадать
                </p>
              </div>
            </div>
            <el-form-item prop="name" :label="'*' + $t('name')">
              <el-input
                class="firstInput mt-3"
                :disabled="!hasRights"
                v-model="user.name"
              ></el-input>
            </el-form-item>
            <el-form-item class="flex mr-1 is-driver-container">
              <skif-checkbox-old
                class="mr-3"
                type="checkbox"
                v-model="user.is_driver"
              />
              <p class="is-driver">{{ $t('users.isDriver') }}</p>
            </el-form-item>
            <!-- language -->
            <el-form-item prop="lang.key" :label="'*' + $t('loginPage.lang')">
              <el-select
                class="mt-3"
                :disabled="!hasRights"
                v-model="user.lang.key"
                :placeholder="$t('select')"
              >
                <el-option
                  v-for="item in langList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- roles -->
            <el-form-item prop="role.key" :label="$t('role')" v-if="!admin">
              <el-select
                v-if="allowedRoles.length > 0"
                class="mt-3 secondInput"
                :disabled="!hasRights"
                v-model="user.role"
                :placeholder="$t('select')"
                @change="handleRoleChange"
              >
                <el-option
                  v-for="(item,i) in allowedRoles"
                  :key="i"
                  :label="item.value"
                  :value="item"
                />
              </el-select>
              <el-select
                v-else
                class="mt-3 secondInput"
                :disabled="!hasRights"
                v-model="user.role.value"
                :placeholder="$t('select')"
              />
            </el-form-item>
            <!-- roles for admin -->
            <div
              class="flex overflow-y-auto flex-col border-b border-t border-divider"
              v-if="admin"
              style="height: 200px"
            >
              <table class="w-full table-fixed">
                <thead>
                  <th class="w-full">
                    <span class="is-driver">{{
                      $t('admin-panel.company')
                    }}</span>
                  </th>
                  <th class="w-36">
                    <span class="is-driver">{{ $t('role') }}</span>
                  </th>
                </thead>
                <tbody>
                  <tr
                    class="cursor-pointer hover_bg-reallylightblue"
                    v-for="(companie, index) in user.companies"
                    :key="index"
                  >
                    <td class="w-full">
                      <p class="leading-10 nameTable truncate">
                        {{ companie.name }}
                      </p>
                    </td>
                    <td>
                      <p class="leading-10 nameTable">
                        {{ user.companies[index].role.value }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- create a span grey text Необязательные поля-->
            <div class="notRequiredContainer flex items-center">
              <span class="title"> Необязательные поля </span>
              <!-- a line  -->
              <div class="line"></div>
            </div>
<!--            <el-form-item-->
<!--              class="pt-2"-->
<!--              prop="lang.key"-->
<!--              :label="$t('loginPage.lang')"-->
<!--            >-->
<!--              <el-select-->
<!--                class="mt-3"-->
<!--                :disabled="!hasRights"-->
<!--                v-model="user.lang.key"-->
<!--                :placeholder="$t('select')"-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="item in langList"-->
<!--                  :key="item.id"-->
<!--                  :label="item.value"-->
<!--                  :value="item.key"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item class="emailItem" prop="phone" :label="$t('phone')">
              <el-input
                class="mt-3"
                :disabled="!hasRights"
                v-model="user.phone"
              ></el-input>
              <div v-if="showPhoneValidation" class="validate mt-2">
                <span class="email-confirm">{{
                  $t('users.invalid_phone')
                }}</span>
                <span @click="confirmPhone" class="validate-btn">
                  {{ $t('users.confirm') }}
                </span>
              </div>
            </el-form-item>
            <el-form-item prop="code" :label="$t('code')">
              <el-input
                class="mt-3"
                :disabled="!hasRights"
                v-model.number="user.code"
                type="number"
              ></el-input>
            </el-form-item>
            <el-popover
              v-model="popover"
              placement="top-end"
              offset="100"
              title="Title"
              width="200"
              content="this is content, this is content, this is content"
            >
              <span @click="popover = !popover">?</span>
            </el-popover>
            <el-form-item
              prop="telegram_chat_id"
              :label="$t('loginPage.telegramChatId')"
            >
              <el-input
                class="mt-3"
                :disabled="!hasRights"
                v-model="user.telegram_chat_id"
              />
            </el-form-item>
            <div class="tg-info-container">
              <svg
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 21C17.0228 21 21.5 16.5228 21.5 11C21.5 5.47715 17.0228 1 11.5 1C5.97715 1 1.5 5.47715 1.5 11C1.5 16.5228 5.97715 21 11.5 21Z"
                  stroke="#2767B6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.5 15V11"
                  stroke="#2767B6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.5 7H11.51"
                  stroke="#2767B6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <ul class="skif-tooltip-ul" slot="content">
                <li class="skif-tooltip-ul__item">
                  1) {{ $t('loginPage.enterTheTelegramBot') }}:
                  <a
                    style="margin-left: 2px; font-weight: bold"
                    :href="linkTelegramBot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    t.me/{{ telegramBot }}
                  </a>
                </li>
                <li class="skif-tooltip-ul__item">
                  2) {{ $t('loginPage.clickOnStartTheBot-') }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Детали-->
          <div class="bottom-side">
            <el-form-item prop="details" :label="$t('description')">
              <el-input
                class="mt-5"
                :disabled="!hasRights"
                type="textarea"
                autosize="autosize"
                :placeholder="$t('details')"
                v-model="user.details"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <transition name="dialog-fade">
      <change-password
        :isPasswordMine="userId === user.id"
        v-if="changePasswordModal"
        @close="changePasswordModal = false"
      ></change-password>
    </transition>
    <ConfirmDialog
      @close="isEmailConfirmDialog = false"
      :userId="user.id"
      :email="$t('to') + ` ${user.email} ` + $t('users.confirm_message')"
      v-if="isEmailConfirmDialog"
    />
    <ConfirmDialog
      @close="isPhoneConfirmDialog = false"
      :userId="user.id"
      :phone="$t('to') + ` ${user.phone} ` + $t('users.confirm_link')"
      v-if="isPhoneConfirmDialog"
    />
  </div>
</template>

<script>
import HTTP from '@/api/http'
import { mapState, mapGetters, mapActions } from 'vuex'
import { usersApi } from '@/api'
import ElementLocale from 'element-ui/lib/locale'
import localeEn from 'element-ui/lib/locale/lang/en.js'
import localeRu from 'element-ui/lib/locale/lang/ru-RU.js'

export default {
  name: 'usersForm',
  components: {
    changePassword: () => import('@/components/user/changePasswordModal'),
    infoIcon: () => import('@/assets/svg-icons/info.svg'),
    infoEmailIcon: () => import('@/assets/svg-icons/infoEmailIcon.svg'),
    ConfirmDialog: () => import('./ConfirmDialog.vue')
  },
  props: [
    'userToUpdate',
    'title',
    'isUpdatingMyself',
    'admin',
    'userToUpdateFromAdmin'
  ],

  data() {
    const errorMessage = {}
    const emailRegExp =
      /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(?:aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|com|ru|[a-z][a-z])$/
    errorMessage.email = this.$t('loginPage.errors.email')
    errorMessage.emailError = this.$t('loginPage.errors.emailError')
    errorMessage.pass = this.$t('loginPage.errors.pass')
    errorMessage.name = this.$t('loginPage.errors.name')
    errorMessage.language = this.$t('user.enter_language')
    errorMessage.nameCompany = this.$t('loginPage.errors.nameCompany')
    errorMessage.telegramChatId = this.$t('loginPage.errors.telegramChatId')
    errorMessage.role = this.$t('user.invite_role_error')

    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(errorMessage.email))
      }
      if (!value) {
        return callback(new Error(errorMessage.email))
      }
      if (!emailRegExp.test(value.toLowerCase())) {
        return callback(new Error(errorMessage.emailError))
      }
      callback()
    }

    const validateName = (rule, value, callback) => {
      const message =
        rule.field === 'name' ? errorMessage.name : errorMessage.language
      if (value === '') {
        callback(new Error(message))
      }
      callback()
    }

    const validateRole = function (rule, value, callback) {
      if (value === '') {
        callback(new Error(errorMessage.role))
      }
      callback()
    }

    const validateTelegramChatId = function (rule, value, callback) {
      const regex = /^[-]?(\d){1,18}$/
      if (value && !regex.test(value)) {
        callback(new Error(errorMessage.telegramChatId))
      }
      callback()
    }
    return {
      passwordRequirements: '',
      allowedRoles: [],
      popover: false,
      changePasswordModal: false,
      avatarLoading: false,
      avatar: undefined,
      loading: false,
      change: false,
      photos: [],
      userFormRules: {
        name: [{ validator: validateName, trigger: 'change' }],
        role: {
          value: [{ validator: validateRole, trigger: 'change' }]
        },
        email: [{ validator: validateEmail, trigger: 'change' }],
        lang: {
          key: [{ validator: validateName, trigger: 'change' }]
        },
        telegram_chat_id: [
          { validator: validateTelegramChatId, trigger: 'change' }
        ]
      },
      hidePopoveHistory: false,
      user: {
        name: '',
        is_driver: '',
        email: '',
        role: { },
        lang: {
          key: ''
        },
        phone: '',
        code: '',
        telegram_chat_id: '',
        photos: [],
        password: ''
      },
      password_confirm: '',
      passwordNotMatch: false,
      telegramBot: '',
      isPhoneConfirmDialog: false,
      isEmailConfirmDialog: false,
      highRoles: ['SUPERVISOR', 'ADMIN', 'TARIFF_EDITOR', 'CONTROLLER']
    }
  },
  computed: {
    ...mapState({
      me: (state) => state.login.me.id,
      currentRole: (state) => state.login.me.role.key,
      isStrongPassword: (state) =>
        state.login.me.active_company.strong_password,
      langList: (state) => state.properties.languages || [],
      roles: (state) => state.properties.roles || []
    }),
    avatarUrl() {
      if (this.currentRole === 'READER') return
      const img = this.user.photos.find((i) => i.url.indexOf('110') > 1)
      return (img && img.url && img.url) || ''
    },
    hasRights() {
      return this.admin
        ? true
        : this.isMe ||
            this.role !== 'ADMIN' ||
            this.role !== 'SUPERVISOR' ||
            this.role !== 'TARIFF_EDITOR' ||
            this.role !== 'CONTROLLER'
    },
    ...mapGetters('login', ['role', 'userId', 'notRole']),

    isMe() {
      return this.me === this.user.id
    },
    header() {
      return this.title === 1
        ? this.$t('user.create_user')
        : this.$t('user.edit_user')
    },
    linkTelegramBot() {
      return `https://t.me/${this.telegramBot}`
    },
    showMailValidation() {
      return !this.user.is_approved && this.title === 2
    },
    showPhoneValidation() {
      return !this.user.is_approved_phone && this.user.phone && this.title === 2
    }
  },
  watch: {
    // watch for password_confirm if it matches user.password or not
    password_confirm(val) {
      if (val === this.user.password) {
        console.log('match')
        this.passwordNotMatch = false
      } else {
        console.log('error')
        this.passwordNotMatch = true
      }
    }
  },

  methods: {
    ...mapActions({
      getAllRoles:'accessSettings/getAllRoles'
    }),
    handleRoleChange(val) {
      const isAdminOrHigher = this.highRoles.includes(val)

      if (this.isStrongPassword) {
        if (isAdminOrHigher) {
          this.passwordRequirements = `Пароль должен содержать от 15 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' " , < . > / ?)`
        } else {
          this.passwordRequirements = `Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' " , < . > / ?)`
        }
      } else {
        this.passwordRequirements = `пароль должен состоять от 5 до 25 символов и состоять как минимум из одной английской буквы и как минимум одной цифры`
      }
    },
    confirmPhone() {
      this.isPhoneConfirmDialog = true
    },
    confirmEmail() {
      this.isEmailConfirmDialog = true
    },
    uploadAvatar({ target }) {
      if (!this.hasRights) return
      const component = this
      const fd = new FormData()
      fd.append('file', target.files[0], target.files[0].name)

      if (this.currentRole === 'READER') return

      this.avatarLoading = true
      HTTP.post('users/photos', fd)
        .then(function (response) {
          component.user.photos = response.data
          component.avatarLoading = false
        })
        .catch(function (error) {
          component.$showError(error.response.data.message)
          component.errors.user.photos = error.response.data.message
          component.avatarLoading = false
        })
    },

    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title === 1) {
            if (!this.passwordNotMatch) {
              this.$store.dispatch('users/CREATE', this.user).then((_) => {
                this.$emit('close')
              })
            }
          } else if (this.title === 2 && this.isMe) {
            this.$store.dispatch('users/UPDATE_MYSELF', this.user).then((_) => {
              const locale = this.user.lang.key === 'ru' ? localeRu : localeEn
              ElementLocale.use(locale)
              this.$i18n.set(this.user.lang.key)
              const langHtml = window.document.querySelector('html')
              langHtml.setAttribute('lang', this.user.lang.key)
              this.$emit('close')
            })
          } else {
            this.$store.dispatch('users/UPDATE', this.user).then((_) => {
              this.$emit('close')
            })
          }
        } else {
          return false
        }
      })
    },
    closeForm() {
      if (this.isUpdatingMyself) {
        this.$emit('closeFromTools')
      } else {
        this.$emit('close')
      }
    }
  },

  async created() {
    this.allowedRoles = await this.getAllRoles().then(res=>res.map(r=>{
      return {
        id:r.id,
        key:r.key,
        value:r.value
      }
    }))
    if (this.title === 2) {
      this.user = this.userToUpdate
    }
    if (this.admin) {
      this.user = this.userToUpdateFromAdmin[0]
    }

    const { data } = await usersApi.getLinkTelegramBot()
    this.telegramBot = data.telegram_bot_name
    if (this.isStrongPassword) {
      this.passwordRequirements = `Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' " , < . > / ?)`
    } else {
      this.passwordRequirements = `пароль должен состоять от 5 до 25 символов и состоять как минимум из одной английской буквы и как минимум одной цифры`
    }
  }
}
</script>

<style lang="stylus">

.notRequiredContainer{
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: #A6AEB8;
  .title{
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    width: 35%
  }
  .line{
    width: 66%;
    height: 1px;

    background-color: #CDD4DD;
  }
}
.password-error{
  font-size: 12px;
  color: #c93131;
}
.password-label{
  font-size: 14px;
  font-style: normal;
  font-family: 'Source Sans Pro';
  line-height: 19px;
  font-weight: 600;
  padding-bottom: 7px;
  color: #91b4e7;
}
.password-requirements{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #828D9B;
}
.skif-info-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 20px 10px 0 0;
  cursor: pointer;
  color: #2F80ED;
  font-size: 0;
  line-height: 0;
  outline: 0 !important;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: rgba(60, 94, 143, 1);
  }
}

.skif-tooltip-ul {
  margin: 5px 6px;

  &__item {
    padding: 0;
    margin: 0 0 7px 0;
    font-size: 13px;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      color: #5477A9;

      &:hover {
        color: rgba(60, 94, 143, 1);
      }
    }
  }
}
</style>

<style lang="stylus" scoped>
$blue = #5477A9;
$lightBlue = #91B4E7;
.tg-info-container{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background-color:#DBEBFF;
  padding: 10px 12px;
  border-radius: 12px;
  color: #20579A;
  .tg-info-icon{
    margin-right: 10px;
  }
}
.emailItem >>> .el-form-item__content{
  display: flex ;
  flex-direction: column;
  .validate{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 250px;

    .email-confirm{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #DF7244;
  }
    .validate-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #446C9E;
      cursor: pointer
    }

  }
}
.wrap {
  text-align: right;

  .el-form {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0;
    padding-top: 18px;
    margin-top: 16px;
    border-top: 1px solid #EEF5FF;

    .left-side, .right-side {
      width: 100%;

      .el-form-item {
        margin-bottom: 16px;
      }

      .el-form-item__content {
        border-color: #000;
      }
    }

    .right-side {
      .el-form-item {
        float: right;
        justify-content: center;
        display: flex;
        width: 100%;
      }
    }

    .bottom-side {
      width: 100%;

      .el-form-item {
        width: 100%;

        .el-form-item__label {
          width: 100%;
        }
      }
    }

    .el-form-item {
      width: 260px;
    }

    .users-form-select {
      width: 100%;
    }
  }

  .right-side {
    position: absolute;
    top: 350;
    right: 0;
    left: 0;
    width: 30% !important;
    margin-left: 65%;
  }
}

.is-driver-container {
  margin-top: -10px;

}

.is-driver {
  color: #91b4e7;
  font-size: 16px;
  font-weight: 600;
}

.user-avatar__container {
  height: 76px;
  border: 0;
  background-color: #FBFDFF;
  border-radius: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  cursor: pointer;
  display flex

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: none;

  }

  span {
    color: #446C9D;
    font-size 14px;
    font-weight bold
  }
}
</style>
<style land="scss" scoped>
.left-side .el-form-item .el-form-item--medium {
  width: 100%;
}
.left-side .el-form-item--medium .el-form-item__label,
.bottom-side .el-form-item--medium .el-form-item__label {
  color: #91b4e7;
  font-size: 16px;
  font-weight: 600;
}

.right-side .el-form-item {
  height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrap .el-form .el-form-item {
  width: 100%;
}
.left-side .el-form-item {
  width: 100%;
}
.firstInput {
  width: 100%;
}
</style>
