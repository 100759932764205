import { companyApi } from '@/api'
import moment from 'moment'
import eventBus from '@/eventBus.js'

const state = {
  sessions: [],
  currentTime: '',
  sessionsDate: '',
  formatTime: '00:00:00'
}

const getters = {
  formatTime: (state) => {
    return state.formatTime
  }
}
const actions = {
  GET_CURRENTTIME({ commit }, currentTime) {
    commit('set_currentTime', currentTime)
  },
  GET_SESSIONS({ commit }, time = 'day') {
    let datetime = ''
    if (time === 'month') {
      datetime = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .subtract(1, 'months')
        .format('YYYY-MM-DD HH:mm:ss')
    } else if (time === 'week') {
      datetime = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .subtract(7, 'days')
        .format('YYYY-MM-DD HH:mm:ss')
    } else {
      datetime = moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DD HH:mm:ss')
    }
    // if (state.dates.indexOf(datetime) === -1) {
    // commit('SET_DATE', datetime)
    companyApi.getSessions(
      { from: datetime },
      (response) => {
        commit('SET_SESSIONS', { data: response.data, datetime })
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
    // }
  },
  getFormatTime({ commit, rootGetters }, formatTime) {
    const reportForm = JSON.parse(
      localStorage.getItem(
        `reportsForm${rootGetters['login/me'].id}${rootGetters['login/me'].active_company_id}`
      )
    )

    if (reportForm) {
      const endDate = reportForm.datePicker[1]
      const momentDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss')
      if (formatTime === '23:59:59') {
        momentDate.set({ hour: 23, minute: 59, second: 59 })
      } else {
        momentDate.set({ hour: 0, minute: 0, second: 0 })
      }
      reportForm.datePicker[1] = momentDate.format('YYYY-MM-DD HH:mm:ss')

      localStorage.setItem(
        `reportsForm${rootGetters['login/me'].id}${rootGetters['login/me'].active_company_id}`,
        JSON.stringify(reportForm)
      )
    }
    commit('setFormatTime', formatTime)
  }
}

const mutations = {
  SET_SESSIONS(state, session) {
    state.sessions = session.data
    state.sessionsDate = session.datetime
  },
  set_currentTime(state, currentTime) {
    state.currentTime = currentTime
  },
  setFormatTime(state, payload) {
    state.formatTime = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
