import { loginApi, usersApi } from '@/api'

import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'
// import main from '@/views/main.vue'
import main from '@/views/main/main'
import notFound from '@/components/page-utils/404'
import errorServer from '@/components/page-utils/500'

// Логин
import loginApp from '@/views/login.vue'
import loginPopup from '@/components/login/login'
import registerPopup from '@/components/login/register'
import dealerPopup from '@/components/login/dealer'
import qrcode from '@/components/login/qrcode'
import confirmPopup from '@/components/login/confirm'
import recoveryPopup from '@/components/login/recovery'
import changePass from '@/components/login/changePass'
// import changePass from '@/components/login/changePass'
import monitoring from '@/components/monitoring/list'

// import userApp from '@/views/user'
import usersList from '@/components/user/userList'
import userModalForm from '@/components/user/userModalForm'

// Locator
import locator from '@/views/locator/Index.vue'

// Геозоны
// import geozonesList from '@/components/geozones/list'
import geozonesCreateForm from '@/components/geozones/createForm'
import geozonesEditForm from '@/components/geozones/editForm'

// import Axios from 'axios'

// Инструкции
import LkDiler from '@/components/map/tools/help-tools/LkDiler.vue'
import CreateCompany from '@/components/map/tools/help-tools/lk-diler/CreateCompany.vue'
import ChangeTarif from '@/components/map/tools/help-tools/lk-diler/ChangeTarif.vue'
import StartTerminal from '@/components/map/tools/help-tools/lk-diler/StartTerminal.vue'
import TemplateCommand from '@/components/map/tools/help-tools/lk-diler/TemplateCommand.vue'
import RegisterLink from '@/components/map/tools/help-tools/lk-diler/register/RegisterLink.vue'
import AdminPanel from '@/components/map/tools/help-tools/lk-diler/register/AdminPanel.vue'

import WorkPlaceCompany from '@/components/map/tools/help-tools/WorkPlaceCompany.vue'
import DataTerminal from '@/components/map/tools/help-tools/work-place-company/DataTerminal.vue'
import CreateObject from '@/components/map/tools/help-tools/work-place-company/CreateObject.vue'
import partners from '@/components/partners/PartnersView.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/locator',
      component: locator,
      name: 'locator',
      show: true,
      meta: { requiresAuth: false }
    },
    {
      path: '/',
      component: main,
      name: 'map',
      show: true,
      // beforeEnter(to, from, next) {
      //   next()
      //   if (to.redirectedFrom === '/success_invite') {
      //     store.commit('login/SUCCESS_INVITE')
      //   }
      // },
      meta: { requiresAuth: true },
      children: [
        {
          path: 'lk-diler',
          name: 'LkDiler',
          component: LkDiler,
          children: [
            {
              path: 'create-company',
              name: 'CreateCompany',
              component: CreateCompany,
              children: [
                {
                  path: 'register-link',
                  name: 'RegisterLink',
                  component: RegisterLink
                },
                {
                  path: 'admin-panel',
                  name: 'AdminPanel',
                  component: AdminPanel
                }
              ]
            },
            {
              path: 'change-tarif',
              name: 'ChangeTarif',
              component: ChangeTarif
            },
            {
              path: 'start-terminal',
              name: 'StartTerminal',
              component: StartTerminal
            },
            {
              path: 'template-command',
              name: 'TemplateCommand',
              component: TemplateCommand
            }
          ]
        },
        {
          path: 'work-place-company',
          name: 'WorkPlaceCompany',
          component: WorkPlaceCompany,
          children: [
            {
              path: 'data-terminal',
              name: 'DataTerminal',
              component: DataTerminal
            },
            {
              path: 'create-object',
              name: 'CreateObject',
              component: CreateObject
            }
          ]
        },
        {
          path: 'settingsUser/:userId',
          name: 'settingsUser',
          component: userModalForm
        },
        // Роут юзеров
        {
          path: 'users',
          name: 'users',
          component: usersList,
          children: [
            {
              path: 'createUser',
              name: 'createUser',
              component: userModalForm
            },
            {
              path: 'editUser/:userId',
              name: 'editUser',
              component: userModalForm,
              beforeEnter(to, from, next) {
                if (to.params && to.params.userId !== undefined) {
                  next()
                } else {
                  next({ name: 'users' })
                }
              }
            }
          ]
        },
        {
          path: 'monitoring',
          name: 'monitoring',
          component: monitoring
        },
        // Роут геозон
        {
          path: 'geozones',
          name: 'geozones',
          children: [
            {
              path: 'create',
              name: 'geozonesCreateForm',
              component: geozonesCreateForm
            },
            {
              path: 'edit/:id',
              name: 'geozonesEditForm',
              component: geozonesEditForm,
              beforeEnter(to, from, next) {
                if (to.params && to.params.id !== undefined) {
                  next()
                } else {
                  next({ name: 'geozones' })
                }
              }
            }
          ]
        },
        // Роут истории изменений
        {
          path: 'history',
          name: 'history',
          show: false,
          component: history
        },
        {
          path: 'history/:id',
          name: 'history-id',
          show: false,
          component: history
        }
      ]
    },
    {
      path: '/company/change/:companyId/:moduleName',
      name: 'companyChangeWithModule',
      component: main,
      props: true
    },
    {
      path: '/company/change/:companyId',
      name: 'companyChange',
      component: main,
      props: true
    },

    // Логин
    {
      path: '',
      name: 'loginApp',
      component: loginApp,
      meta: { requiresAuth: false },
      children: [
        {
          path: '/login/:username?',
          name: 'login',
          meta: { requiresAuth: false },
          component: loginPopup
          // beforeEnter: (to, from, next) => {
          //   if (store.getters['login/getAuth']) {
          //     next('/')
          //   } else {
          //     next('/login')
          //   }
          // }
        },
        {
          path: '/register',
          name: 'register',
          meta: { requiresAuth: false },
          component: registerPopup
        },
        {
          path: '/dealer',
          name: 'dealer',
          meta: { requiresAuth: false },
          component: dealerPopup
        },
        // {
        //   path: 'c',
        //   name: 'qrcode',
        //   meta: { requiresAuth: false },
        //   component: qrcode
        // },
        {
          path: 'confirm',
          name: 'confirm',
          meta: { requiresAuth: false },
          component: confirmPopup
        },
        {
          path: 'recovery',
          name: 'recovery',
          meta: { requiresAuth: false },
          component: recoveryPopup
        },
        {
          path: 'recovery2',
          name: 'recovery2',
          meta: { requiresAuth: false },
          component: recoveryPopup
        },
        {
          path: 'recovery/:hash',
          name: 'recoveryHash',
          meta: { requiresAuth: false },
          component: recoveryPopup,
          beforeEnter(to, from, next) {
            if (to.params) {
              loginApi.recoveryAuth(
                to.params.hash,
                function (response) {
                  console.log('element;', response)
                  next({ name: 'changePassword' })
                },
                function (error) {
                  console.log(error)
                  next({ name: '500' })
                }
              )
            }
          }
        },
        {
          path: 'approve/:hash',
          name: 'approveHash',
          meta: { requiresAuth: false },
          beforeEnter(to, from, next) {
            loginApi.approve(
              to.params.hash,
              function (response) {
                next({ name: 'map' })
              },
              function (error) {
                console.log(error)
                next({ name: '500' })
              }
            )
          }
        },
        {
          path: 'changePassword',
          name: 'changePassword',
          meta: { requiresAuth: false },
          component: changePass
        },
        {
          path: 'company/invite/:hash',
          name: 'companyInviteHash',
          meta: { requiresAuth: true },
          beforeEnter(to, from, next) {
            usersApi.approveInvite(
              to.params.hash,
              function (response) {
                console.log(response.data)
              },
              function (error) {
                console.log('ses', error)
                next({ name: '500' })
              }
            )
          }
        }
      ]
    },
    {
      path: '/success_invite',
      beforeEnter: (to, from, next) => {
        if (store.getters['login/getAuth']) {
          store.commit('login/REMOVE_SUCCESS_INVITE')
          next('/')
        } else {
          store
            .dispatch('login/getMe')
            .then((res) => {
              console.log('DISPATCH router !')
              store.commit('login/SUCCESS_INVITE')
              next('/')
            })
            .catch((_) => {
              console.log('catching ')
              next()
            })
        }
      }
    },

    {
      path: '/bad_invite',
      beforeEnter: (to, from, next) => {
        next('/500')
      }
    },
    {
      path: '/404',
      component: notFound,
      name: '404'
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/500',
      component: errorServer,
      name: '500'
    },

    {
      path: '/c',
      name: 'qrcode',
      meta: { requiresAuth: false },
      component: qrcode
    },
    {
      path: '/partners',
      name: 'partners',
      meta: { requiresAuth: false },
      component: partners
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.path === '/c') {
      // Skip authentication check for dealers registration
      next()
    } else {
      loginApi.getMe(
        (res) => {
          if (res.data.need_change_password) {
            store.commit('login/SET_CHANGE_PASSWORD', true)
            // return
          }
          next()
        },
        () => {
          next('/login')
        }
      )
    }
  } else if (to.path.startsWith('/company/change')) {
    store.dispatch('login/setRequestedRoute', to.fullPath)
    next()
  } else {
    if (from.path === '/partners') {
      next(from.fullPath)
    }
    next()
  }
})
export default router
